<template>
    <div class="mv-policydetails">
        <HeaderPrice  
            :price="formatNumber(coveragePrice.Policy.firstInstallmentAmount)"
            :paymentType="defaultPaymentFrequency"
            :opportunityNumber="policyObject.root.quoteData.opportunityNumber"
            :rewardNum="formatNumber(Global.motorVehicle.Reward)"
            :isGetReward="Global.motorVehicle.isDefaultReward" 
            id="header-price"
        />
        <div class="section policyDetails">
            <div class="container">
                <div class="questions-section" id="detail-ownership">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.questionMainDriver')"
                        questionImage="images/motor-vehicle/VechileOwner.svg"
                        :globalValue="Global.motorVehicle.phaccount.isMainDriver"
                        :answers="mainDriverOptions"
                        @action="mainDriverAction" />
                </div>
                <div class="questions-section" id="detail-email" v-show="stepCompleted('detail-ownership')">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.carOwnerEmail')"
                        questionImage="images/motor-vehicle/EmailOwner.svg"
                        :textTooltip="$t('motorVehicle.pD1tooltip1')" 
                        email-form
                        :globalValue="policyObject.root.quoteData.policyHolderData.email" 
                        :newsletterSubscribeValue="policyObject.root.quoteData.policyHolderData.PersonHasOptedOutOfEmail"
                        @action="emailAction" 
                        force-disable
                    />
                </div>
                <div class="questions-section" id="detail-phone" v-show="stepCompleted('detail-email')">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.carOwnerPhone')"
                        questionImage="images/motor-vehicle/PhoneOwner.svg" 
                        phone-form
                        is-number-only 
                        phone-validate
                        force-disable 
                        :lengthValue="13" 
                        :minLength="9" 
                        @action="phoneAction"
                        :globalValue="policyObject.root.quoteData.policyHolderData.phoneNumber" 
                        
                    />
                </div>
                <div class="questions-section" id="detail-callmeback" v-show="stepCompleted('detail-phone')">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.availabilityToCall')"
                        questionImage="images/motor-vehicle/RoojaiCall.svg" 
                        :answers="callMeBackOptions"
                        @action="callMeBackAction" 
                        :globalValue="policyObject.root.quoteData.callMeBackRequest" 
                    />
                    <div class="callMeBackQuestion" id="detail-car-plate">
                        <div class="response" v-if="policyObject.root.quoteData.callMeBackRequest === 'Yes'">
                            {{ $t('motorVehicle.answerShowcallMeYes') }}
                        </div>
                        <div class="response" v-else-if="policyObject.root.quoteData.callMeBackRequest === 'Later'">
                            {{ $t('motorVehicle.answerShowcallMeLater') }}
                        </div>
                    </div>
                </div>
                <!-- <div class="questions-section" id="detail-commercial" v-show="stepCompleted('detail-callmeback')">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.commercialPurposes')" 
                        :answers="comercialOptions"
                        @action="commercialAction" 
                        :globalValue="Global.motorVehicle.phaccount.commercial" 
                    />
                </div>
                <div class="questions-section" id="detail-register-owner" v-show="stepCompleted('detail-commercial')">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.registeredAsOwned')" 
                        :answers="ownershipOptions"
                        @action="registeredOwnerAction" 
                        :globalValue="Global.motorVehicle.phaccount.registeredOwner" 
                    />
                </div>

                <div class="questions-section" id="detail-company"
                    v-show="stepCompleted('detail-register-owner') && isCompanysCar">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.companyName')" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.companyNamePlaceholder')" 
                        formType="text"
                        @action="companyNameAction" 
                        :minLength="3" 
                        validate-char
                        :globalValue="Global.motorVehicle.phaccount.companyName" 
                    />
                </div>
                <div class="questions-section" id="detail-company-registration"
                    v-show="stepCompleted('detail-company') && isCompanysCar">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.companyRegistrationNumber')" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.companyRegistrationNumberPlaceholder')" 
                        formType="tel" 
                        is-number-only
                        :minLength="5" 
                        :lengthValue="13"
                        @action="companyRegistrationAction"
                        :globalValue="Global.motorVehicle.phaccount.businessRegNumber" 
                    />
                </div> -->

                <div class="questions-section" id="detail-car-plate"
                    v-show="(stepCompleted('detail-callmeback') && !isCompanysCar) || stepCompleted('detail-company-registration') && isRegisteredAsCompany">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.carNumPlateQuestion')"
                        questionImage="images/motor-vehicle/CarPlate.svg" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.carNumPlate')" 
                        formType="text"
                        :lengthValue="9" 
                        :minLength="3" 
                        validate-char
                        car-plate-validate 
                        force-disable
                        @action="carPlateAction"
                        :globalValue="policyObject.root.quoteData.vehicleData.carPlateNumber" 
                    />
                </div>

                <div class="questions-section" id="detail-car-owner"
                    v-show="stepCompleted('detail-car-plate') && policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="mainDriverDOB" 
                        slot-type 
                        :textTooltip="$t('motorVehicle.pD1tooltip5')"
                        ref="carOwner"
                        @action="carOwnerMaritalandGenderAction" 
                        no-next
                    >
                        <MainDriver 
                            :maritialStatus="policyObject.root.quoteData.policyHolderData.gender + '-' + policyObject.root.quoteData.policyHolderData.maritialStatus"
                            :dob="policyObject.root.quoteData.policyHolderData.dob" 
                            @action="openButtonCarOwner" 
                        />
                    </QuestionPolicyDetails>
                </div>

                <div class="questions-section" id="detail-citizen"
                    v-show="stepCompleted('detail-car-owner') && policyObject.root.quoteData.vehicleData.isMainDriverPH || stepCompleted('detail-car-plate') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.citizenCarOwner')" 
                        :answers="citizenOptions"
                        @action="citizenOptionsAction" 
                        :globalValue="policyObject.root.quoteData.policyHolderData.residentStatus" 
                    />
                </div>
                <div class="questions-section" id="detail-idnumber"
                    v-show="stepCompleted('detail-citizen') && isIndonesianCitizen">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.identificationNumber')" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.identificationNumberPlaceholder')" 
                        formType="tel"
                        :textTooltip="$t('motorVehicle.pD1tooltip4')" 
                        is-number-only 
                        citizen-form 
                        citizen-validate
                        force-disable
                        :lengthValue="16" 
                        :minLength="16" 
                        @action="citizenNumberAction"
                        :globalValue="policyObject.root.quoteData.policyHolderData.thaiIdNumber" 
                    />
                </div>

                <div class="questions-section" id="detail-passportnumber"
                    v-show="stepCompleted('detail-citizen') && !isIndonesianCitizen">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.passportNumber')" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.passportNumberPlaceholder')" 
                        :textTooltip="$t('motorVehicle.pD1tooltip6')" 
                        formType="text"
                        validate-char  
                        ref="country" 
                        passport-validate
                        :lengthValue="9" 
                        :minLength="7" 
                        :checkCountry = "policyObject.root.quoteData.policyHolderData.countryOfIssuance"
                        slot-type 
                        @action="passportAction"
                        :globalValue="policyObject.root.quoteData.policyHolderData.passPortNumber" 
                    >
                        <ButtonSelect 
                            :label="labelcountryPassport" 
                            :listsData="Global.countries" 
                            type="country"
                            @selected="selectCountry" 
                        />
                    </QuestionPolicyDetails>
                </div>
                <div class="questions-section" id="detail-car-owner-name"
                    v-show="stepCompleted('detail-passportnumber') || stepCompleted('detail-idnumber')">
                    <QuestionPolicyDetails 
                        :questions="carOwnername" 
                        formType="text" 
                        multi-form
                        :textTooltip="$t('motorVehicle.pD1tooltip7')" 
                        :multiFormData="mainDriverForm" 
                        @action="fullnameAction"
                        :globalValueArray="[policyObject.root.quoteData.policyHolderData.firstName, policyObject.root.quoteData.policyHolderData.lastName]" 
                    />
                </div>
                <div class="questions-section" id="detail-occupation" v-show="stepCompleted('detail-car-owner-name')">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.occupation')" 
                        slot-type
                        :textTooltip="$t('motorVehicle.pD1tooltip7')" 
                        ref="occupation" 
                        @action="occupationAction"
                    >
                        <ButtonSelect 
                            :label="labelOccupationOwnerCar" 
                            :listsData="Global.occupations"
                            :language="policyObject.root.quoteData.prefLang"  
                            :activeSelected="policyObject.root.quoteData.policyHolderData.occupation"                         
                            type="occupations" 
                            @selected="selectOccupation" 
                        />
                    </QuestionPolicyDetails>
                </div>


                <!-- ----------- if is not main driver -------- -->
                <div class="questions-section" id="detail-marital-status"
                    v-show="stepCompleted('detail-occupation') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <GenderAndStatus 
                        is-policy-details
                        :defaultAnswer="Global.motorVehicle.mainDrivers.gender + '-' + Global.motorVehicle.mainDrivers.maritialStatus"
                        @next="genderAndStatusAction"
                        :statusTitle="$t('motorVehicle.statusCarOwner')" 
                    />
                </div>

                <div class="questions-section" id="detail-dob"
                    v-show="stepCompleted('detail-marital-status') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <DayOfBirth 
                        :title="$t('motorVehicle.dobCarOwner')"
                        :defaultAnswer="Global.motorVehicle.mainDrivers.dob" 
                        @next="dobAction"
                        @update="updateDobAction" 
                    />
                </div>

                <div class="questions-section" id="detail-not-main-driver"
                    v-show="stepCompleted('detail-dob') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="mainDriverDOB" 
                        slot-type 
                        :textTooltip="$t('motorVehicle.pD1tooltip8')"
                        ref="mainDriver" 
                        no-next
                    >
                        <MainDriver 
                            :dob="policyObject.root.quoteData.insuredData.dob"
                            :maritialStatus="policyObject.root.quoteData.insuredData.gender + '-' + policyObject.root.quoteData.insuredData.maritialStatus"
                            @action="openButtonMainDriver" 
                        />
                    </QuestionPolicyDetails>
                </div>

                <!-- <div 
                    class="questions-section" 
                    id="detail-occupation-main-driver"
                    v-show="stepCompleted('detail-car-owner-name') && !isMainDriver"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.occupation')"
                        slot-type
                        textTooltip="tooltip info will show here!"
                        ref="occupationMainDriver"
                        @action="occupationActionMainDriver"
                    >
                        <ButtonSelect 
                            :label="$t('motorVehicle.occupation')"
                            :listsData="Global.occupations"
                            :language="Global.quote.prefLang"
                            type="occupations"
                            @selected="selectOccupationMainDriver"    
                        />
                    </QuestionPolicyDetails>
                </div> -->

                <div class="questions-section" id="detail-citizen-not-main-driver"
                    v-show="stepCompleted('detail-not-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.citizenMainDriver')" 
                        :answers="citizenOptions"
                        @action="citizenNotMainDriverAction"
                        :globalValue="policyObject.root.quoteData.insuredData.residentStatus" 
                    />
                </div>
                <div class="questions-section" id="detail-idnumber-main-driver"
                    v-show="stepCompleted('detail-citizen-not-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH && isNotMainDriverIndonesian">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.identificationNumberMainDriver')" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.identificationNumberPlaceholder')" 
                        formType="tel"
                        :textTooltip="$t('motorVehicle.pD1tooltip4')" 
                        is-number-only citizen-form citizen-validate
                        :lengthValue="16" 
                        force-disable
                        :minLength="16" 
                        @action="citizenNumberMainDriverAction"
                        :globalValue="policyObject.root.quoteData.insuredData.thaiIdNumber" 
                    />
                </div>
                <div class="questions-section" id="detail-passportnumber-main-driver"
                    v-show="stepCompleted('detail-citizen-not-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH && !isNotMainDriverIndonesian">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.passportNumberMainDriver')" 
                        form-control
                        :formPlaceholder="$t('motorVehicle.passportNumberPlaceholder')" 
                        :textTooltip="$t('motorVehicle.pD1tooltip6')" 
                        formType="text"
                        validate-char  
                        ref="country" 
                        passport-validate
                        :lengthValue="9" 
                        :minLength="7" 
                        :checkCountry = "policyObject.root.quoteData.insuredData.countryOfIssuance"
                        slot-type 
                        @action="passportMainDriverAction"
                        :globalValue="policyObject.root.quoteData.insuredData.passPortNumber"
                    >
                        <ButtonSelect 
                            :label="labelcountryPassportMain" 
                            :listsData="Global.countries" 
                            type="country"
                            @selected="selectCountryMainDriver" 
                        />
                    </QuestionPolicyDetails>
                </div>

                <div class="questions-section" id="detail-car-main-driver"
                    v-show="stepCompleted('detail-idnumber-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH || stepCompleted('detail-passportnumber-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="mainDriverName" 
                        formType="text" 
                        multi-form
                        :textTooltip="$t('motorVehicle.pD1tooltip5')" 
                        :multiFormData="mainDriverForm"
                        @action="fullnameActionMainDriver"
                        :globalValueArray="[policyObject.root.quoteData.insuredData.firstName, policyObject.root.quoteData.insuredData.lastName]" 
                    />
                </div>

                <div class="questions-section" id="detail-occupation-main-driver"
                    v-show="stepCompleted('detail-car-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.occupation2')" 
                        slot-type
                        :textTooltip="$t('motorVehicle.pD1tooltip9')" 
                        ref="occupationMainDriver"
                        @action="occupationActionMainDriver"
                    >
                        <ButtonSelect 
                            :label="labelOccupationMainDriver" 
                            :listsData="Global.occupations"
                            :language="policyObject.root.quoteData.prefLang" 
                            :activeSelected="policyObject.root.quoteData.insuredData.occupation" 
                            type="occupations" 
                            @selected="selectOccupationMainDriver" 
                        />
                    </QuestionPolicyDetails>
                </div>

                <div class="questions-section" id="detail-email-main-driver"
                    v-show="stepCompleted('detail-occupation-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.mainDriverEmail')"
                        questionImage="images/motor-vehicle/EmailOwner.svg"
                        :textTooltip="$t('motorVehicle.pD1tooltip1')" 
                        email-form
                        :isShowNewsLetter="false"
                        :isMainDriver="true"
                        @action="emailActionMainDriver" 
                        :globalValue="policyObject.root.quoteData.insuredData.email" 
                    />
                </div>

                <div class="questions-section" id="detail-phone-main-driver"
                    v-show="stepCompleted('detail-email-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.mainDriverPhone')"
                        questionImage="images/motor-vehicle/PhoneOwner.svg"
                        phone-form
                        is-number-only 
                        phone-validate 
                        force-disable
                        :lengthValue="14" 
                        :minLength="10" 
                        :isMainDriver="true"
                        @action="phoneActionMainDriver"
                        :globalValue="policyObject.root.quoteData.insuredData.phoneNumber" 
                    />
                </div>

                <!-- end if not main driver -->


                <div class="questions-section" id="detail-polis-date"
                    v-show="stepCompleted('detail-occupation') && policyObject.root.quoteData.vehicleData.isMainDriverPH || stepCompleted('detail-phone-main-driver') && !policyObject.root.quoteData.vehicleData.isMainDriverPH">
                    <PolisDate 
                        :globalData="policyObject.root.quoteData.startDate" 
                        :textTooltip="$t('motorVehicle.pD1tooltip10')"
                        @next="polisDateFill" 
                    />
                </div>


                <div class="nextToPayment" id="payment-button" v-show="stepCompleted('detail-polis-date')">
                    <div class="epolicy" v-if="Global.tp === 'nb'">
                        <div class="epolicy-checkbox" :class="{ clicked: ePolicyDocument }" @click="epolicyModal"></div>
                        {{ $t("motorVehicle.ePolicy") }}
                    </div>
                    <div class="tnq">
                        <div class="tnq-checkbox" :class="{ clicked: Global.motorVehicle.tncClick }" @click="tncClick"></div>
                        {{ $t('motorVehicle.agreeTnc') }} <span @click="privacyModal = true">{{ $t('motorVehicle.tnc') }}</span>
                        {{ $t('motorVehicle.and') }} <span @click="limitationAndLiabilityModal = true">{{ $t('motorVehicle.limitationAndLiability') }}</span>
                    </div>
                    <button class="btn btn-block next2btn" :disabled="!Global.motorVehicle.tncClick" @click="sendOtp">
                        {{ $t('motorVehicle.btnToPayment') }}
                    </button>
                </div>
            </div>
        </div>

        <Modal
            :show="Global.motorVehicle.isShowInstallmentModal" 
            width="100%" 
            @close="Global.motorVehicle.isShowInstallmentModal = false"
            class="modal-installment-option"
        >
            <div id="installment-detail-modal">
                <div class="modal-header" style="border:0;">
                    <button type="button" data-selenium-name="installment-modal-close-btn" @click="Global.motorVehicle.isShowInstallmentModal = false" class="close"><span aria-hidden="true">x</span></button>
                    <h2 class="text-center h4 mt-5">{{$t("instalmentOptions")}}</h2>
                    <p class="text-center" style="font-size: 16px;">{{$t("instalmentCreditDebit")}}</p>
                </div>
                    <div class="panel installment-option mb-5" v-bind:class="{ active: defaultPaymentFrequency === 'Yearly'  }">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="Yearly" v-on:click="switchPaymentType('Yearly');Global.motorVehicle.isShowInstallmentModal = false; eventLog('Instalment', 'Yearly');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option1")}}</p>
                        </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("fullPayment")}}</h3>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="fullPayment-totalPrice" :data-selenium-value="formatNumber(this.paymentOptionPrice['Yearly'].totalPremium)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>{{formatNumber(this.paymentOptionPrice["Yearly"].totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>

                    <div class="panel installment-option mb-5"  v-bind:class="{ active: defaultPaymentFrequency === '6 Months'  }">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="6 months" v-on:click="switchPaymentType('6 Months');Global.motorVehicle.isShowInstallmentModal = false; eventLog('Instalment', '12 months, no deposit');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option2")}}</p>
                            </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("6MonthlyInstalments")}}</h3>
                                <i18n-t tag="p" keypath="payForMonths">
                                  <strong><small>IDR </small>{{formatNumber(this.paymentOptionPrice["6 Months"].firstInstallmentAmount)}}</strong>
                                  <strong>6</strong>
                                </i18n-t>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="monthly-nodeposit-totalPrice" :data-selenium-value="formatNumber(this.paymentOptionPrice['6 Months'].firstInstallmentAmount)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small> {{formatNumber(this.paymentOptionPrice["6 Months"].totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>
                </div>
        </Modal>

        <div class="modal modal-v1 fade" id="roojai-epolicy-modal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content white-bg">
                    <div class="modal-header epolicy-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            data-selenium-name="epolicy-close">
                            X
                        </button>
                        <h5 class="modal-title mt-4 text-center h6">
                            {{ $t("motorVehicle.ePolicyTitle") }}
                        </h5>
                    </div>
                    <div class="modal-body epolicy-body">
                        <div class="text-muted mt-2">
                            <span class="glyphicons glyphicons-info-sign"></span>
                            &nbsp;<span>{{ $t("motorVehicle.ePolicyDesc") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer epolicy-footer">
                        <div class="row justify-content-end">
                            <div class="col-6 d-flex flex-nowrap">
                                <button type="button" class="btn btn-epolicy flex-grow-1" id="modal-btn-yes" @click="epolicyYesModal();"
                                    data-selenium-name="epolicy-yes">{{ $t("motorVehicle.ePolicyYes") }}</button>
                            </div>
                            <div class="col-6 d-flex flex-nowrap">
                                <button type="button" class="btn btn-epolicy-no" styel="color:#000" id="modal-btn-no"
                                    @click="epolicyNoModal()" data-selenium-name="epolicy-no">{{
                                        $t("motorVehicle.ePolicyNo") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade insure-modal" id="otp-modal-new-phone-number" data-selenium-name="otp-modal"
            data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="otp-modalLabel" aria-modal="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body pt-5 pb-3 px-5 px-md-5 text-center">
                        <button type="button" class="close" data-selenium-name="otp-modal-close" data-dismiss="modal"
                            aria-label="Close" @click="resetOTPModal()"><span aria-hidden="true">x</span></button>

                        <div v-if="!isChangeNumber4OTP && !isOTPprocessVerify && !isOTPError">
                            <div class="verify-otp">
                                <h2 class="h1section-text2" style="font-size : 20pt;">{{ $t("otp.title") }}</h2>
                                <p for="OTP" class="mt-3 text-black" style="font-size : 12pt;">{{ $t("otp.sendto") }} <span
                                        id="otp2mobile1" class="font-weight-bold" ata-selenium-name="otp-mobile"
                                        ><br> {{ policyObject.root.quoteData.policyHolderData.phoneNumber
                                        }} </span>

                                </p>
                                <fieldset>
                                    <div class="form-group">
                                        <input type="tel" name="OTP" maxlength="6" data-selenium-name="otp"
                                            v-model.trim="OTP" v-on:keyup.enter="verifyOTP()"
                                            class="data-field form-control form-control-lg text-center border-secondary required"
                                            :placeholder="$t('otp.placeholder')">
                                    </div>
                                </fieldset>
                                <!-- <p class="mb-2" v-if="!isOTPverificationfailed">{{$t("otp.pleaseEnterOTP")}}</p> -->
                            </div>

                            <div class="resend-otp-detail-2">
                                <p class="text-otp-error text-danger" v-if="isOTPverificationfailed">
                                    <small>{{ $t("otp.OTPverificationfailed") }}</small></p>
                                <strong style="color: #FF4713 ;font-size: 21pt;">
                                    <vue-countdown v-if="counting" :time="60000" @end="onCountdownEnd"
                                        v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown>
                                    <span v-if="!counting">0</span>
                                </strong>
                                <div class="row mt-4">
                                    <div class="col-ms-12 col-md-12">
                                        <button id="resendOTP-TH" type="button" :disabled="counting"
                                            class="btn btn-small btn-resend-otp mb-2" data-selenium-name="otp-resend"
                                            @click="resendOTP()">{{ $t("otp.resend") }}</button>
                                    </div>
                                    <div class="col-ms-12 col-md-6" v-if="false">
                                        <button id="newPhoneNumber-TH" type="button"
                                            class="btn btn-small btn-outline-primary btn-block btn-new-phone-number"
                                            @click="isChangeNumber4OTP = true">เปลี่ยนเบอร์โทรศัพท์</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="new-phone-number-detail" v-if="isChangeNumber4OTP && !isOTPError && !isOTPprocessing">
                            <h2 class="mb-3">เปลี่ยนเบอร์โทรศัพท์</h2>
                            <p>กรอกเบอร์โทรศัพท์ใหม่ของคุณ<br>สำหรับการรับรหัส OTP</p>
                            <form id="new-phone-form" autocomplete="off" onsubmit="return false;">
                                <fieldset>
                                    <div class="form-group">
                                        <input type="tel" name="new-phone-number" data-selenium-name="new-phone-number"
                                            v-model.trim="OTPMobileNumber" maxlength="10" autofocus="autofocus"
                                            class="data-field form-control form-control-lg border-secondary required"
                                            v-bind:class="{ 'text-center': isValid.OTPMobileNumber }"
                                            placeholder="Phone Number">
                                        <div class="text-default small mt-2"
                                            v-if="OTPMobileNumber.length == 10 && !isValid.OTPMobileNumber">
                                            {{ $t("otp.numberInvalid") }}</div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>

                        <div class="new-phone-sending" v-if="isOTPprocessing && !isOTPError">
                            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 105 105"
                                fill="#ff5011">
                                <circle cx="12.5" cy="12.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="0s" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
                                    <animate attributeName="fill-opacity" begin="100ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="52.5" cy="12.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="300ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="52.5" cy="52.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="600ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="92.5" cy="12.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="800ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="92.5" cy="52.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="400ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="12.5" cy="92.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="700ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="52.5" cy="92.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="500ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="92.5" cy="92.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="200ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                            </svg>
                            <h4 class="mt-4">{{ $t("otp.resending") }}</h4>
                        </div>

                        <div class="new-phone-sending" v-if="isOTPprocessVerify && !isOTPError">
                            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 105 105"
                                fill="#ff5011">
                                <circle cx="12.5" cy="12.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="0s" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
                                    <animate attributeName="fill-opacity" begin="100ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="52.5" cy="12.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="300ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="52.5" cy="52.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="600ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="92.5" cy="12.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="800ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="92.5" cy="52.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="400ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="12.5" cy="92.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="700ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="52.5" cy="92.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="500ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                                <circle cx="92.5" cy="92.5" r="12.5">
                                    <animate attributeName="fill-opacity" begin="200ms" dur="1s" values="1;.2;1"
                                        calcMode="linear" repeatCount="indefinite"></animate>
                                </circle>
                            </svg>
                            <h4 class="mt-4">{{ $t("otp.verification") }}</h4>
                        </div>

                        <div class="new-phone-error" v-if="isOTPError">
                            <svg viewBox="0 0 24 24" width="100" height="100" stroke="#ff5011" stroke-width="2" fill="none"
                                stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                            </svg>
                            <p class="mt-4">{{ $t("otp.OTPError") }}</p>
                        </div>

                    </div>
                    <div class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
                        v-if="!isOTPprocessing && !isOTPprocessVerify">
                        <button v-if="!isChangeNumber4OTP && !isOTPError" id="cancelOTP-TH" type="button"
                            class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1 btn-Otp" data-selenium-name="otp-close"
                            data-dismiss="modal" @click="resetOTPModal()">{{ $t("cancel") }}</button>

                        <button v-if="!isChangeNumber4OTP && !isOTPError" id="submitOTP-TH" type="button"
                            class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1 mb-0 " data-selenium-name="otp-verify"
                            @click="verifyOTP()" v-bind:disabled="OTP.length !== 6">{{ $t("confirm") }}</button>

                        <button v-if="isChangeNumber4OTP && !isOTPError" id="new-phone-back-TH" type="button"
                            class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
                            data-selenium-name="otp-back-newNumber"
                            @click="isChangeNumber4OTP = false">{{ $t("back") }}</button>

                        <button v-if="isChangeNumber4OTP && !isOTPError" id="new-phone-submit-TH" type="button"
                            class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1 mb-0"
                            data-selenium-name="otp-submit-newNumber" @click="submitNewMobileNumber()">บันทึก และส่งรหัส OTP
                            ใหม่</button>

                        <button v-if="isOTPError" id="try-new-phone-back-TH" type="button"
                            class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1" data-selenium-name="otp-retry"
                            @click="resetOTPModal()">ลองใหม่อีกครั้ง</button>
                    </div>
                </div>
            </div>
        </div>


        <Modal
            :show="privacyModal"
            :class="'garage'"
            width="100%" 
        >
            <PrivacyAndPolicy 
                @close="privacyModal= false"
            />
        </Modal>

        <Modal
            :show="limitationAndLiabilityModal"
            :class="'garage'"
            width="100%" 
        >
            <LimitationOfLiability 
                @close="limitationAndLiabilityModal= false"
            />
        </Modal>

    </div>
</template>
    
<script>
import QuestionPolicyDetails from './components/QuestionPolicyDetails.vue';
import ButtonSelect from './components/ButtonSelect.vue';
import GenderAndStatus from './components/GenderAndStatus.vue';
import DayOfBirth from './components/DayOfBirth.vue';
import PolisDate from './components/PolisDate.vue';
import Modal from "./components/Modal.vue";
import PrivacyAndPolicy from './components/PrivacyAndPolicy.vue';
import LimitationOfLiability from './components/LimitationAndLiability.vue';

import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";
import MainDriver from './components/MainDriver.vue';
import HeaderPrice from './components/HeaderPrice.vue';

import { getRegisterIGNSdk, setInterceptorObj, calculateAge, ymdFormatedDate, oneYearLater } from "./lib/index.js";
import { policyServiceMappingObj } from './data/util.js';
import { policyObject } from './data/PolicyObject';

import {
    setClientRegInfo,
    calculatePlanOptionsPrice,
    setInputPricing,
    setPolicyServiceInputObj,
    getPolicyPaymentOptionPrice,
    saveQuote,
    getAvailablePaymentFrequency,
    getCoveragePrice,
    getDefaultPaymentFrequency,
    updatePaymentFrequency,
    // setPolicyServiceMappingFromInputPriceResponse,
    getInputPricing,
    getRewardPoint,
    getPolicyServiceInputObj
} from "./lib/IGNLibrary/IGNPricingEngine.js";

import axios from "axios";

let clientRegInfo;
export default {
    name: "PolicyDetails",
    beforeRouteLeave (to, from, next) {
        if(to.name == 'mv-payment') next()
        if(window.pageYOffset > 0) {
            this.scrollToTopPage()
            next(false)
        } else next()
    },
    data() {
        return {
            Global,
            policyObject,
            inputPriceObj: null,
            reward:0,
            isDefaultReward: false,
            tnc: false,
            showCarPlate: false,
            isCalculatePlanOptionsPrice: true,
            ePolicyDocument: true,
            isConfirmInfo: true,
            counting: false,
            isChangeNumber4OTP: false,
            isOTPprocessing: false,
            isOTPprocessVerify: false,
            isOTPError: false,
            isOTPverificationfailed: false,
            OTPMobileNumber: "",
            OTP: "",
            refID: "",
            OTPcountdown: 30,
            isOTPcountdown: true,
            paymentOptionPrice : null,
            availablePaymentFrequency: [],
            defaultPaymentFrequency: null,
            coveragePrice: {},
            privacyModal: false,
            limitationAndLiabilityModal: false,

        }
    },
    components: {
        QuestionPolicyDetails,
        ButtonSelect,
        GenderAndStatus,
        DayOfBirth,
        PolisDate,
        MainDriver,
        Modal,
        HeaderPrice,
        PrivacyAndPolicy,
        LimitationOfLiability
    },
    mixins: [Mixin],
    created() {
        
        this.fetchOccupations();
        window.scrollTo(0, 0);
        clientRegInfo = getRegisterIGNSdk();
        this.bindData();
        this.saveQuoteFetch();
    },

    methods: {
        async sendOtp() {
            this.loading("show");
            const isNotBlacklistAccount = await this.searchBlacklistAccountWithOpp(this.policyObject.root.quoteData.opportunityNumber)
            if (isNotBlacklistAccount) {
                if (Global.brokerInfo.intermediaryReptID !== "" && Global.isAgentCustomerView) {
                    Global.isOTPValid = true;
                }
                if (Global.isOTPValid ||
                    (Global.brokerInfo.intermediaryReptID !== "" &&
                        Global.isAgentCustomerView)
                ) {
                    this.getData();
                    this.saveQuoteFetch();
                    this.loading("hide");
                    this.$router.push({ name: 'mv-payment' });
                } else {
                    try {
                        let otpResponse = await this.generateOTP();
                        if (otpResponse.data.success === true) {
                            this.loading("hide");
                            this.refID = otpResponse.data.data.refID;
                            $("#otp-modal-new-phone-number").modal("show");
                            this.OTPcountdown = 30
                            this.countdownOTP();
                            // this.dateTime = moment(30000)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            }else{
                this.loading("hide");
                this.errorModal(this.$t("blackList"), 'title', this.$t("blackListTitle"), this.$t("blackListContact"), this.$t("blackList2"));
            }
        },
        epolicyModal: function () {
            if (this.ePolicyDocument == true) {
                $("#roojai-epolicy-modal").modal("show");
            } else {
                this.ePolicyDocument = true
            }
        },
        epolicyYesModal: function () {
            this.ePolicyDocument = true
            this.policyObject.root.quoteData.ePolicyDocument = this.ePolicyDocument ? "Yes" : "No";
            $("#roojai-epolicy-modal").modal("hide");
        },
        epolicyNoModal: function () {
            this.ePolicyDocument = false
            this.policyObject.root.quoteData.ePolicyDocument = this.ePolicyDocument ? "Yes" : "No";
            $("#roojai-epolicy-modal").modal("hide");
        },
        startCountdown: function () {
            this.counting = true;
        },
        onCountdownEnd: function () {
            this.counting = false;
        },
        generateOTP: function () {

            this.refID = "";
            this.OPT = "";
            this.OTPcountdown = 30;
            return axios.post(Global.roojaiService.url + "/OTP/generate", {
                destination: this.policyObject.root.quoteData.policyHolderData.phoneNumber,
                locale: Global.quote.prefLang,
                inputType: "phone",
                source: "insure"
            },
                {
                    headers: { Authorization: "Bearer " + Global.roojaiService.token }
                }
            );
        },
        resendOTP: function() {
            var vm = this;
            vm.isOTPprocessing = true;
            vm.generateOTP()
            .then(function(otpResponse) {
            if (otpResponse.data.success === true) {
                vm.refID = otpResponse.data.data.refID;
                vm.isOTPprocessing = false;
                vm.counting = true
                // vm.OTPcountdown = 30
                // vm.countdownOTP();
            }
            })
            .catch(function(err) {
            vm.isOTPprocessing = false;
            console.log("resendOTP", err);
            });
        },
        countdownOTP: function () {
            var vm = this;
            vm.counting = true
            vm.isOTPcountdown = true;

            setTimeout(function () {
                vm.isOTPcountdown = false;
            }, 60000);
        },
        resetOTPModal: function () {
            this.isOTPprocessing = false;
            this.counting = false
            this.isChangeNumber4OTP = false;
            this.isOTPverificationfailed = false;
        },
        verifyOTP: function () {
            var vm = this;

            if (this.OTP.length !== 6) {
                return false;
            }

            // if (vm.isOTPprocessing) {
            //     return true;
            // }

            if (vm.isOTPprocessVerify) {
                return true;
            }

            // vm.isOTPprocessing = true;

            vm.isOTPprocessVerify = true

            axios.post(Global.roojaiService.url + "/OTP/verify_otp", {
                refID: this.refID,
                OTP: this.OTP,
                destination: this.policyObject.root.quoteData.policyHolderData.phoneNumber,
                source: "insure"
            },
                {
                    headers: { Authorization: "Bearer " + Global.roojaiService.token }
                }).then(
                    response => {
                        vm.isOTPprocessVerify = false;
                        // vm.loading("hide");

                        Global.isOTPValid = response.data.success;
                        let checkRJSes = Global.roojaiService.rjses?.includes(response?.data?.rj)
                        if (response.data.success && checkRJSes) {
                            // GO PAYMENT
                            vm.goToPayment();
                        } else {
                            vm.isOTPverificationfailed = true;
                            vm.isOTPcountdown = false;
                            vm.OTP = "";
                        }

                    },
                    error => {
                        vm.isOTPprocessVerify = false;
                        console.error(error);
                    }
                );
        },
        bindData(){
            this.paymentOptionPrice = getPolicyPaymentOptionPrice();
            this.availablePaymentFrequency = getAvailablePaymentFrequency();
            this.defaultPaymentFrequency = getDefaultPaymentFrequency();
            this.coveragePrice = getCoveragePrice();
            this.policyObject = getPolicyServiceInputObj();
            this.inputPriceObj = getInputPricing();
            this.getRewardPoint();   
        },

        onScrollNavbar() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition > 240) {
                this.showPriceHeader = true;
            } else {
                this.showPriceHeader = false;
            } 

            if (currentScrollPosition < 3000) {
                this.showFloating = true;
            } else {
                this.showFloating = false;
            }
        },

        switchPaymentType(value) {
            if(this.paymentOptionPrice) {
                updatePaymentFrequency(this.defaultPaymentFrequency);
            }
            },
        updateDefaultPaymentFrequency(value) {
            updatePaymentFrequency(value);
            // this.bindData();
        },
        async saveQuoteFetch(saveAccount) {

            this.policyObject.root.quoteData.onlineStage = saveAccount ? "Save Account" : "Proceed to Policy Details 1";
            this.policyObject.root.quoteData.onlineScreen = saveAccount ? "/mv/policydetails1" : "/mv/policydetails";
            // this.policyObject.root.quoteData.paymentData = {
            //     "isPmntInitiatedBySF": false,
            //     "agentId": null,
            //     "paidAmount":0,
            //     "flagPaymentConfirmed":null,
            //     "flagDeferredPayment":null,
            //     "paymentMethod": null,
            //     "paymentFrequency": null,
            //     "paymentDetailsData" : [],
            //     "creditCard" : null
            // },
            this.policyObject.root.quoteData.ePolicyDocument = this.ePolicyDocument ? "Yes" : "No";
            setPolicyServiceInputObj(this.policyObject);
            // setPolicyServiceMappingFromInputPriceResponse(policyServiceMappingObj);

            let interceptorObj = setInterceptorObj();
            let resQuote = await saveQuote(interceptorObj);
            this.policyObject.root.quoteData.opportunityNumber = resQuote.opportunityNumber;
            if(resQuote.sfAccountId) this.policyObject.root.quoteData.policyHolderData.sfAccountId = resQuote.sfAccountId
            if(this.policyObject.root.quoteData.vehicleData.isMainDriverPH) this.policyObject.root.quoteData.insuredData.sfAccountId = resQuote.sfAccountId
        },
        async getData() {
            // this.inputPriceObj.root.Quote.Radar_Call_Type = null;
            if (this.policyObject.root.quoteData.opportunityNumber) {
                this.inputPriceObj.root.Quote.Policy_Number = this.policyObject.root.quoteData.opportunityNumber;
            }

            setClientRegInfo(clientRegInfo);
            setInputPricing(this.inputPriceObj);
            this.loading("show");
            try {
                await calculatePlanOptionsPrice(this.isCalculatePlanOptionsPrice);
                this.loading("hide");
                //Global.motorVehicle.isPackagesStage = true;
                this.coveragePrice = getCoveragePrice();
            } catch (error) {
                this.loading("hide");
                this.errorModal(this.$t("error.connection.message"));
                console.log(error)
            }
        },


        tncClick() {
            Global.motorVehicle.tncClick = !Global.motorVehicle.tncClick ;
            // this.tnc = !this.tnc;
        },

        getRewardPoint(){
            let rewardRes = getRewardPoint();
            if (rewardRes > 0 && Global.motorVehicle.isDefaultReward == null) {
                Global.motorVehicle.isDefaultReward = true;
                Global.motorVehicle.DefaultReward = rewardRes;
                this.isDefaultReward = true
                this.reward = rewardRes;
            }
        },

        fetchOccupations() {
            this.getOccupations(Global.enumProductType.MV, "SOMPO");
        },
        fetchCountries: async function () {
            if (Array.isArray(Global.countries) && Global.countries.length > 0) {
                return true;
            }

            try {
                var respose = await this.request().get(
                    Global.restServer.url + "/countries"
                );
                Global.countries = respose.data;
            } catch (error) {
                if (error.response) {
                    vm.frontEndLog("countries", "", error.response.status.toString());
                }
                console.error("getCountries => ", error);
            }

        },

        selectCountry(value) {
            this.policyObject.root.quoteData.policyHolderData.countryOfIssuance = value;
            this.$refs.country.openDisabledButton();
        },

        selectCountryMainDriver(value) {
            this.policyObject.root.quoteData.insuredData.countryOfIssuance = value;
        },

        selectOccupation(value) {

            
            this.policyObject.root.quoteData.policyHolderData.occupation = value.nameEn;
            this.inputPriceObj.root.Quote.Insured_List[0].Occupation = value.nameEn;
            Global.motorVehicle.occupationCarOwner = value
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.occupation = value.nameEn;               
            } 
            this.$refs.occupation.openDisabledButton();
        },

        selectOccupationMainDriver(value) {

            this.policyObject.root.quoteData.insuredData.occupation = value.nameEn;
            Global.motorVehicle.occupationMainDriver = value
            if (!this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.occupation = value.nameEn;
            }
            this.$refs.occupationMainDriver.openDisabledButton();
        },

        mainDriverAction(value) {
            this.policyObject.root.quoteData.vehicleData.isMainDriverPH = value == 'YES'? true : false;
            Global.motorVehicle.phaccount.isMainDriver = value;
            this.policyObject.root.quoteData.onlineStage = "isMainDriver Captured " + value;
            this.pushStep("detail-ownership");
            this.scrollToId("detail-email");
        },

        emailAction(value) {
            this.policyObject.root.quoteData.policyHolderData.email = value;
            this.policyObject.root.quoteData.onlineStage = "Email Captured";
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.email = value;
            } else {
                this.policyObject.root.quoteData.insuredData.email = "";
            }
            this.eventTracking('Oppo', 'policyDetails1', 'Opportunity_Asset_Email', 'CAR');
            this.saveQuoteFetch();
            this.pushStep("detail-email");
            this.scrollToId("detail-phone");
        },

        phoneAction(value) {

            this.policyObject.root.quoteData.policyHolderData.phoneNumber = value;
            this.policyObject.root.quoteData.onlineStage = "Phone captured";
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.phoneNumber = value;
            } else {
                this.policyObject.root.quoteData.insuredData.phoneNumber = "";
            }
            this.eventTracking('Oppo', 'policyDetails1', 'Opportunity_Asset_Phone', 'CAR');
            this.saveQuoteFetch();
            this.pushStep("detail-phone");
            this.scrollToId("detail-callmeback");
        },

        callMeBackAction(value) {
            this.policyObject.root.quoteData.callMeBackRequest = value;
            this.policyObject.root.quoteData.onlineStage = "Call me back";
            this.saveQuoteFetch();

            this.pushStep("detail-callmeback");
            this.scrollToId("detail-car-plate");
            this.eventLog('Call_me', value);
            if (value != "No") {
                this.eventTracking('Oppo', 'policyDetails1', 'Opportunity_call_me_back', 'CAR');
            }
        },

        commercialAction(value) {
            this.policyObject.root.quoteData.vehicleData.vehicleUsage = value;
            this.policyObject.root.quoteData.onlineStage = "Vehicle Usage captured";

            this.pushStep("detail-commercial");
            this.scrollToId("detail-register-owner");
        },

        registeredOwnerAction(value) {
            this.policyObject.root.quoteData.vehicleData.carOwnerShip = value == 'personal' ? 'Private' : 'Company'
            this.inputPriceObj.root.Quote.Vehicle_Data.Ownership = value == 'personal' ? 'Private' : 'Company';
            if (this.isCompanysCar) {
                this.pushStep("detail-register-owner");
                this.scrollToId("detail-company");
            } else {
                this.pushStep("detail-register-owner");
                this.scrollToId("detail-car-plate");
            }

        },

        companyNameAction(value) {
            this.policyObject.root.quoteData.policyHolderData.companyNumber = value;
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.companyNumber = value;
            }

            this.pushStep("detail-company");
            this.scrollToId("detail-company-registration");
        },

        companyRegistrationAction(value) {
            this.policyObject.root.quoteData.policyHolderData.businessRegNumber = value;
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.businessRegNumber = value;
            }
            this.showCarPlate = true;
            this.pushStep("detail-company-registration");
            this.scrollToId("detail-car-plate");
        },

        carPlateAction(value) {
            this.policyObject.root.quoteData.vehicleData.carPlateNumber = value;
            this.policyObject.root.quoteData.onlineStage = "Car Plate Number captured";

            this.pushStep("detail-car-plate");
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.scrollToId("detail-car-owner");
            } else {

                this.scrollToId("detail-citizen");
            }

        },

        openButtonCarOwner(value) {
            if (value) {
                if (value.dob) {
                    const age = calculateAge(value.dob);
                    this.policyObject.root.quoteData.policyHolderData.driverAge = String(age);
                    this.policyObject.root.quoteData.policyHolderData.dob = ymdFormatedDate(value.dob);
                    this.policyObject.root.quoteData.insuredData.driverAge = String(age);
                    this.policyObject.root.quoteData.insuredData.dob = ymdFormatedDate(value.dob);
                }

                if (value.genderAndStatus) {
                    const genderAndStatus = value.genderAndStatus.split("-");
                    this.policyObject.root.quoteData.policyHolderData.gender = genderAndStatus[0];
                    this.policyObject.root.quoteData.policyHolderData.maritialStatus = genderAndStatus[1];
                    this.policyObject.root.quoteData.insuredData.gender = genderAndStatus[0];
                    this.policyObject.root.quoteData.insuredData.maritialStatus = genderAndStatus[1];
                }

                this.pushStep("detail-car-owner");
                this.scrollToId("detail-citizen")
            }

            this.$refs.carOwner.openDisabledButton();
        },

        openButtonMainDriver(value) {

            if (value) {
                if (value.dob) {
                    const age = calculateAge(value.dob);
                    this.policyObject.root.quoteData.insuredData.driverAge = String(age);
                    this.policyObject.root.quoteData.insuredData.dob = ymdFormatedDate(value.dob);
                }
                if (value.genderAndStatus) {
                    const genderAndStatus = value.genderAndStatus.split("-");
                    this.policyObject.root.quoteData.insuredData.gender = genderAndStatus[0];
                    this.policyObject.root.quoteData.insuredData.maritialStatus = genderAndStatus[1];
                }
                this.pushStep("detail-not-main-driver");
                this.scrollToId("detail-citizen-not-main-driver");
            }
        },

        carOwnerMaritalandGenderAction() {
            this.pushStep("detail-car-owner");
            this.scrollToId("detail-citizen");
        },

        citizenOptionsAction(value) {
            this.policyObject.root.quoteData.policyHolderData.residentStatus = value;

            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.residentStatus = value;
            }

            this.pushStep("detail-citizen");
            if (this.isIndonesianCitizen) {
                this.scrollToId("detail-idnumber");
            } else {
                this.fetchCountries()
                this.scrollToId("detail-passportnumber");
            }
        },

        citizenNumberAction(value) {
            this.policyObject.root.quoteData.policyHolderData.thaiIdNumber = value;
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.thaiIdNumber = value;
            }

            this.pushStep("detail-idnumber");
            this.scrollToId("detail-car-owner-name");
        },
        passportAction(value) {
            this.policyObject.root.quoteData.policyHolderData.passPortNumber = value;
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.passPortNumber = value;
            }

            this.pushStep("detail-passportnumber");
            this.scrollToId("detail-car-owner-name");
        },
        fullnameAction(value) {
            this.policyObject.root.quoteData.policyHolderData.firstName = value.firstName;
            this.policyObject.root.quoteData.policyHolderData.lastName = value.lastName;

            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                this.policyObject.root.quoteData.insuredData.firstName = value.firstName;
                this.policyObject.root.quoteData.insuredData.lastName = value.lastName;
            }
            this.saveQuoteFetch(Global.brokerInfo.intermediaryReptID !== "");

            this.pushStep("detail-car-owner-name");
            this.scrollToId("detail-occupation");
        },

        occupationAction() {

            //this.policyObject.root.quoteData.policyHolderData.occupation = Global.motorVehicle.phaccount.occupation
            //this.inputPriceObj.root.Quote.Insured_List[0].Occupation = Global.motorVehicle.phaccount.occupation;
            this.pushStep("detail-occupation");
            if (this.policyObject.root.quoteData.vehicleData.isMainDriverPH) {
                //this.policyObject.root.quoteData.insuredData.occupation = Global.motorVehicle.phaccount.occupation;
                this.scrollToId("detail-polis-date");
            } else {
                this.scrollToId("detail-marital-status");
            }

        },

        genderAndStatusAction(value) {

            const genderAndStatus = value.split("-");
            Global.motorVehicle.mainDrivers.gender = genderAndStatus[0];
            Global.motorVehicle.mainDrivers.maritialStatus = genderAndStatus[1];
            this.policyObject.root.quoteData.insuredData.gender = genderAndStatus[0];
            this.policyObject.root.quoteData.insuredData.maritialStatus = genderAndStatus[1];
            this.pushStep("detail-marital-status");
            this.scrollToId("detail-dob");
        },

        dobAction(value) {

            Global.motorVehicle.mainDrivers.dob = value;
            const age = calculateAge(value);
            this.policyObject.root.quoteData.policyHolderData.driverAge = String(age);
            this.policyObject.root.quoteData.policyHolderData.dob = String(value).split("-").reverse().join("-");

            this.pushStep("detail-dob");
            this.scrollToId("detail-not-main-driver");
        },

        updateDobAction(value) {
            Global.motorVehicle.mainDrivers.dob = value;
            const age = calculateAge(value);
            this.policyObject.root.quoteData.policyHolderData.driverAge = String(age);
            this.policyObject.root.quoteData.policyHolderData.dob = String(value).split("-").reverse().join("-");
        },
        citizenNotMainDriverAction(value) {
            this.policyObject.root.quoteData.insuredData.residentStatus = value;
            Global.motorVehicle.mainDrivers.residentStatus = value;
            this.pushStep("detail-citizen-not-main-driver");
            if (this.isNotMainDriverIndonesian) {
                this.scrollToId("detail-idnumber-main-driver");
            } else {
                this.fetchCountries()
                this.scrollToId("detail-passportnumber-main-driver");
            }
        },

        citizenNumberMainDriverAction(value) {
            this.policyObject.root.quoteData.insuredData.thaiIdNumber = Global.motorVehicle.mainDrivers.thaiIdNumber;
            this.pushStep("detail-idnumber-main-driver");
            this.scrollToId("detail-car-main-driver");
        },
        passportMainDriverAction(value) {

            this.policyObject.root.quoteData.insuredData.passPortNumber = value;
            this.pushStep("detail-passportnumber-main-driver");
            this.scrollToId("detail-car-main-driver");
        },

        fullnameActionMainDriver(value) {

            this.policyObject.root.quoteData.insuredData.firstName = value.firstName;
            this.policyObject.root.quoteData.insuredData.lastName = value.lastName;
            this.saveQuoteFetch();
            this.pushStep("detail-car-main-driver");
            this.scrollToId("detail-occupation-main-driver");
        },

        occupationActionMainDriver() {
            this.policyObject.root.quoteData.insuredData.occupation = Global.motorVehicle.mainDrivers.occupation;
            this.pushStep("detail-occupation-main-driver");
            this.scrollToId("detail-email-main-driver");
        },

        emailActionMainDriver(value) {
            this.policyObject.root.quoteData.insuredData.email = value;
            this.policyObject.root.quoteData.insuredData.personHasOptedOutOfEmail = Global.motorVehicle.mainDrivers.PersonHasOptedOutOfEmail;
            this.pushStep("detail-email-main-driver");
            this.scrollToId("detail-phone-main-driver");
        },

        phoneActionMainDriver(value) {
            this.policyObject.root.quoteData.insuredData.phoneNumber = value;
            this.pushStep("detail-phone-main-driver");
            this.scrollToId("detail-polis-date");
        },

        polisDateFill(value) {
            this.policyObject.root.quoteData.startDate = value.split("-").reverse().join("-");
            this.policyObject.root.quoteData.endDate = oneYearLater(value.split("-").reverse().join("-"));    
            // console.log('policyobj', policyObject)       
            this.pushStep('detail-polis-date');
            this.scrollToId('payment-button')
        },

        goToPayment() {
            this.policyObject.root.quoteData.onlineStage = "Payment";
            this.policyObject.root.quoteData.paymentData.paymentMethod = "Credit Card"
            this.policyObject.root.quoteData.paymentData.paymentFrequency = Global.motorVehicle.quote.paymentFrequency
            this.saveQuoteFetch();
            $("#otp-modal-new-phone-number").modal("hide");
            this.$router.push({ name: 'mv-payment' });
        },

        scrollToId(element) {
            setTimeout(() => {
                const headerElmnt = document.getElementById('header-price').getClientRects()[0];

                let elm = document.getElementById(element);
                let headerOffset = headerElmnt.height;
                let elementPosition = elm.getBoundingClientRect().top;
                let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });

            }, 100);
        },
        dmyFormat(date) {
            if (date) {
                const dob = date.split("-");
                let d, m, y;
                d = String(dob[2]);
                m = String(dob[1]);
                y = String(dob[0]);
                return m + "-" + d + "-" + y;
            }
        },
        ymdFunctionFormat(date) {
            return ymdFormatedDate(date);
        },
        dmyFunctionFormat(date) {
            return dmyFormatedDate(date);
        },
    },
    computed: {
        mainDriverOptions() {
            return [
                { value: "YES", text: this.$i18n.t('motorVehicle.answer.yes') },
                { value: "NO", text: this.$i18n.t('motorVehicle.answer.no') }
            ];
        },
        mainDriverForm() {
            return [
                {
                    name: "firstName",
                    placeholder: this.$i18n.t('motorVehicle.firstNamePlaceholder'),
                },
                {
                    name: "lastName",
                    placeholder: this.$i18n.t('motorVehicle.lastNamePlaceholder'),
                }
            ];
        },
        callMeBackOptions() {
            return [
                { value: "Yes", text: this.$i18n.t('motorVehicle.answer.yesNow') },
                { value: "Later", text: this.$i18n.t('motorVehicle.answer.later') },
                { value: "No", text: this.$i18n.t('motorVehicle.answer.no') },
            ];
        },
        comercialOptions() {
            return [
                { value: "Business_Support", text: this.$i18n.t('motorVehicle.answer.yes') },
                { value: "SPT", text: this.$i18n.t('motorVehicle.answer.no') }
            ];
        },
        ownershipOptions() {
            return [
                { value: "personal", text: this.$i18n.t('motorVehicle.answer.personal') },
                { value: "company", text: this.$i18n.t('motorVehicle.answer.company') }
            ]
        },
        citizenOptions() {
            return [
                { value: "Indonesian", text: this.$i18n.t('motorVehicle.answer.indonesiaCitizen') },
                { value: "Foreign resident", text: this.$i18n.t('motorVehicle.answer.foreign') }
            ]
        },
        isCompanysCar() {
            if (Global.motorVehicle.phaccount.registeredOwner == 'Company') {
                return true;
            } else {
                return false;
            }
        },
        isRegisteredAsCompany() {
            if (Global.motorVehicle.phaccount.companyRegistrationNumber !== null) {
                return true;
            } else {
                return false;
            }
        },
        isIndonesianCitizen() {
            return this.policyObject.root.quoteData.policyHolderData.residentStatus === 'Indonesian'
        },
        carOwnername() {
            if (this.policyObject.root.quoteData.policyHolderData.isMainDriverPH == true) {
                return this.$i18n.t('motorVehicle.carOwnerName');
            } else {
                return this.$i18n.t('motorVehicle.carOwnerName3');
            }
        },
        mainDriverName() {
            return this.$i18n.t('motorVehicle.carOwnerName2');
        },

        mainDriverDOB() {
            if (this.policyObject.root.quoteData.policyHolderData.isMainDriverPH == true) {
                return this.$i18n.t('motorVehicle.mainDriverDOB');
            } else {
                return this.$i18n.t('motorVehicle.mainDriverDOB2');
            }
        },
        isMainDriverIndonesian() {
            return this.policyObject.root.quoteData.policyHolderData.residentStatus === 'Indonesian'
        },
        isNotMainDriverIndonesian() {
            return this.policyObject.root.quoteData.insuredData.residentStatus === 'Indonesian'
        },
        labelcountryPassport() {
            if (this.policyObject.root.quoteData.policyHolderData.countryOfIssuance !== null) {
                return this.policyObject.root.quoteData.policyHolderData.countryOfIssuance
            } else {
                return this.$i18n.t('motorVehicle.countryOrigin');
            }
        },
        labelcountryPassportMain() {
            if (this.policyObject.root.quoteData.insuredData.countryOfIssuance !== null) {
                return this.policyObject.root.quoteData.insuredData.countryOfIssuance
            } else {
                return this.$i18n.t('motorVehicle.countryOrigin');
            }
        },
        labelOccupationOwnerCar() {
            if (Object.keys(Global.motorVehicle.occupationCarOwner).length !== 0) {
                if (this.policyObject.root.quoteData.prefLang === "id") {
                    if (Global.motorVehicle.occupationCarOwner.nameTh.length < 35) return Global.motorVehicle.occupationCarOwner.nameTh;
                    else return Global.motorVehicle.occupationCarOwner.nameTh.slice(0, 35) + '...';
                } else {
                    if (Global.motorVehicle.occupationCarOwner.nameEn.length < 35) return Global.motorVehicle.occupationCarOwner.nameEn;
                    else return Global.motorVehicle.occupationCarOwner.nameEn.slice(0, 35) + '...';
                }
            } else {
                return this.$i18n.t('motorVehicle.occupationPlaceholder');
            }
        },
        labelOccupationMainDriver() {
            if (Object.keys(Global.motorVehicle.occupationMainDriver).length !== 0) {
                if (this.policyObject.root.quoteData.prefLang=== "id") {
                    if (Global.motorVehicle.occupationMainDriver.nameTh.length < 35) return Global.motorVehicle.occupationMainDriver.nameTh;
                    else return Global.motorVehicle.occupationMainDriver.nameTh.slice(0, 35) + '...';
                } else {
                    if (Global.motorVehicle.occupationMainDriver.nameEn.length < 35) return Global.motorVehicle.occupationMainDriver.nameEn;
                    else return Global.motorVehicle.occupationMainDriver.nameEn.slice(0, 35) + '...';
                }
            } else {
                return this.$i18n.t('motorVehicle.occupationPlaceholder');
            }
        }

    },
    watch: {
        Global: {
            deep: true,
            handler: function (value) {
                const phaccount = value.motorVehicle.phaccount;
                if (!this.isCompanysCar && !phaccount.companyName & !phaccount.companyRegistrationNumber) {
                    this.showCarPlate = true;
                } else {
                    this.showCarPlate = false;
                }
            }
        },
    }
}
</script>
    
<i18n>
{
    "en": {
        "otp": {
            "title": "OTP Verification",
            "sendto": "Enter the OTP code that we sent to your WhatsApp number ",
            "sendto2" : "to continue the purchase process.",
            "placeholder" : "OTP",
            "reference": "reference ID",
            "numberInvalid": "Please enter a valid mobile number",
            "pleaseEnterOTP": "Please enter the OTP",
            "ifcantreceive":'If you don\'t receive the OTP, please click "Resend new OTP"',
            "resend": "Resend new OTP",
            "resending": "Sending new OTP",
            "verification": "Verification OTP",
            "second" : "Second",
            "OTPError": "Sorry an error occurred. Please try again",
            "OTPverificationfailed":'Your OTP code is incorrect. Please try again or click "Resend new OTP"'
        },
    },
    "id": {
    
        "otp": {
            "title": "Verifikasi OTP",
            "sendto": "Masukan kode OTP yang kami kirimkan ke nomor WhatsApp kamu ",
            "sendto2" : "untuk melanjutkan proses pembelian. ",
            "placeholder" : "OTP",
            "reference": "ID Referensi",
            "numberInvalid": "Masukkan nomor ponsel yang valid",
            "pleaseEnterOTP": "Silahkan masukkan OTP",
            "ifcantreceive":"Jika kamu belum menerima OTP, silahkan klik \"kirim ulang OTP baru\"",
            "ifcantreceive2":"Jika kamu belum menerima OTP, silahkan klik \"kirim ulang OTP baru\"",
            "resend": "Kirim ulang OTP",
            "resending": "Mengirimkan OTP baru",
            "verification": "Memverifikasi OTP",
            "second" : "Detik",
            "OTPError": "Maaf terjadi kesalahan. Silahkan coba kembali",
            "OTPverificationfailed":"Kode OTP salah. Silahkan coba lagi atau klik \"kirim ulang OTP baru\""
        },
    
    },
}
</i18n>
    
    
    
<style scoped>
.section {
    max-width: 604px;
    width: 100%;
    padding: 0 15px;
    margin: auto;
}

.questions-section {
    padding: 70px 0px;
}

.nextToPayment {
    padding: 30px 10px;
}

.next2btn {
    color: #fff;
    border-radius: 5px;
    background-image: none;
    background-color: #ff5011;
    padding: 15px 21px;
    margin: 6px auto;
    box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.btn-epolicy {
    color: #fff;
    background-color: #ff5011;
    border-radius: 10px;
    /* padding: 5px 10px; */
    margin-bottom: 20px;
}

.btn-epolicy-no {
    color: #ff5011 !important;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #003b71 !important;
    border-color: #003b71 !important;
    /* padding: 5px 10px; */
    margin-bottom: 20px;
}

.next2btn:disabled {
    border: 1px solid #ccc !important;
    color: #ccc !important;
    background-color: #fff !important;
    box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%) !important;
    cursor: not-allowed;
}

#installment-detail-modal .modal-header{
  display: block;
}

#installment-detail-modal .modal-header .close{
  color: #ff5011;
}

#installment-detail-modal .installment-option {
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  border: 1px solid #ece9e8;
  color: grey
}

#installment-detail-modal .installment-option .panel-body{
  padding: 15px;
}

#installment-detail-modal .installment-option.active {
    background-color: #fff !important;
    border: 1px solid #ff5011 !important;
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7) !important;
    color: #003b71 !important
}
#installment-detail-modal .installment-option.active .panel-footer {
    color: #fff;
    background-color: #ff5011;
}
#installment-detail-modal .installment-option.active .btn-pk-radio {
    border: 0
}
#installment-detail-modal .installment-option.active .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    display: inline
}
#installment-detail-modal .installment-option .text-head {
    font-size: 16px
}
#installment-detail-modal .installment-option .text-desc {
    font-size: 16px
}
#installment-detail-modal .installment-option .btn-pk-radio {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background-color: #fff;
    border: 1px solid rgba(255, 53, 19, .3);
    padding: 0;
    margin: 0;
    z-index: 50;
    outline-color: #fff;
    cursor: pointer
}
#installment-detail-modal .installment-option .btn-pk-radio:hover {
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7);
    border: 0
}
#installment-detail-modal .installment-option .btn-pk-radio:hover .glyphicons.glyphicons-ok-sign {
    display: inline;
    color: #fa7f52
}
#installment-detail-modal .installment-option .btn-pk-radio .glyphicons {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px
}
#installment-detail-modal .installment-option .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    color: #ff5011;
    display: none
}
#installment-detail-modal .installment-option .panel-footer {
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid #ece9e8;
    font-size: 17px;
    padding: 1rem 1rem;
}
#installment-detail-modal .installment-option .panel-footer p {
    margin: 0;
    padding: 0
}
.epolicy-header{
    padding: 5px 12px !important;
}
.epolicy-body{
    padding: 5px 10px !important;
}
.epolicy-footer{
    padding-top: 10px !important;
}

.tnq {
    display: inline-flex;
    margin-bottom: 29px;
}

.tnq span {
    font-weight: 700;
    font-size: 14px;
    color: #FF4713;
    margin-left: 8px;
    cursor: pointer;
    display: contents;
}

.tnq-checkbox {
    width: 24px;
    height: 21px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
}


.tnq-checkbox.clicked::before {
    background-color: #fff;
    content: "\E207";
    display: inline-block;
    position: relative;
    top: 2px;
    left: 3px;
    display: inline-block;
    font-family: 'Glyphicons Regular';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    color: #ff5011;
    cursor: pointer;
}

.epolicy {
    display: inline-flex;
    margin-bottom: 29px;
}

.epolicy span {
    font-weight: 700;
    font-size: 14px;
    color: #FF4713;
    margin-left: 8px;
    cursor: pointer;
}

.epolicy-checkbox {
    width: 24px;
    height: 21px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
}

.epolicy-checkbox.clicked::before {
    background-color: #fff;
    content: "\E207";
    display: inline-block;
    position: relative;
    top: 2px;
    left: 3px;
    display: inline-block;
    font-family: 'Glyphicons Regular';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    color: #ff5011;
    cursor: pointer;
}
.btn-Otp {
    background: #fff!important;
    color: #ff5011!important;
    border: 1px solid #ff5011 !important;
}
.callMeBackQuestion .response {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #FF4713;
    border-bottom: 1px solid #D1D3D4;
    padding: 50px 0px 15px 0px;
}

@media screen and (max-width: 480px) {
    /* .tnq {
            display: grid;
        } */
    /* .tnq-checkbox {
            position: absolute;
        } */
    /* .questions-section {
        padding: 110px 0px 0px 0px;
    } */
    .questions-section {
        padding: 30px 0px;
    }
    .tnq-checkbox {
        width: 38px!important;
        height: 21px!important;
    }
    .epolicy-checkbox {
        width: 33px;
        height: 21px;
    }
}

@media screen and (max-width: 360px) {
    .questions-section {
        padding: 30px 0px;
    }
    .tnq-checkbox {
        width: 43px!important;
    }
    .epolicy-checkbox {
        width: 37px!important;
        height: 21px!important;
    }
}
</style>