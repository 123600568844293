<template>
    <div id="privacy-modal" class="insure-modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body privacy-body">
                    <button 
                        type="button" 
                        class="close" 
                        style="color: rgb(244, 67, 54); opacity: 0.8;"
                        @click="close"
                    >
                        <span>x</span>
                    </button>
                    <h2 class="h1 section-text2 h4 text-center mb-3">
                        {{$t("privacyMv.mainTitlePrivacyMv")}}
                    </h2>
                    <div id="privacy">
                        <div class="privacy-content">
                            <h6 class="mb-3 subtitle">
                                {{$t("privacyMv.informationCollectionMv")}}
                            </h6>
                            <b>{{ $t("privacyMv.P1.title") }}</b>
                            <p>{{ $t("privacyMv.P1.content") }}</p>
                            <ol>
                                <li class="point-cont">
                                    <div class="title-cont">{{ $t("privacyMv.P1.points.1.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P1.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P1.points.2.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P1.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P1.points.3.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P1.points.3.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P1.points.4.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P1.points.4.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P1.points.5.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P1.points.5.content") }}</div>
                                </li>
                            </ol>

                            <b>{{ $t("privacyMv.P2.title") }}</b>
                            <p>{{ $t("privacyMv.P2.content") }}</p>
                            <ol>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("privacyMv.P1.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P2.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P2.points.3.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P2.points.4.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P2.points.5.content") }}</div>
                                </li>
                            </ol>

                            <b>{{ $t("privacyMv.P3.title") }}</b>
                            <p>{{ $t("privacyMv.P3.content") }}</p>
                            <ol>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.3.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.4.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.5.content") }}</div>
                                </li>
                            </ol>

                            <b>{{ $t("privacyMv.P4.title") }}</b>
                            <ol>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("privacyMv.P4.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P4.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.3.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P3.points.4.content") }}</div>
                                </li>
                            </ol>

                            <b>{{ $t("privacyMv.P5.title") }}</b>
                            <p>{{ $t("privacyMv.P5.content") }}</p>

                            <b>{{ $t("privacyMv.P6.title") }}</b>
                            <p>{{ $t("privacyMv.P6.content") }}</p>

                            <b>{{ $t("privacyMv.P7.title") }}</b>
                            <ol>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("privacyMv.P7.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P7.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="content-cont">{{ $t("privacyMv.P7.points.3.content") }}</div>
                                </li>
                            </ol>
                            <p>{{ $t("privacyMv.P7.content") }}</p>

                            <b>{{ $t("privacyMv.P8.title") }}</b>
                            <p>{{ $t("privacyMv.P8.content") }}</p>

                            <b>{{ $t("privacyMv.P9.title") }}</b>
                            <p>{{ $t("privacyMv.P9.content") }}</p>
                            <ol>
                                <li class="point-cont">
                                    <div class="title-cont">{{ $t("privacyMv.P9.points.1.title") }}</div>
                                    <div class="content-cont ">{{ $t("privacyMv.P9.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P9.points.2.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P9.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P9.points.3.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P9.points.3.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P9.points.4.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P9.points.4.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P9.points.5.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P9.points.5.content") }}</div>
                                </li>
                            </ol>

                            <b>{{ $t("privacyMv.P10.title") }}</b>
                            <div><b>{{ $t("privacyMv.P10.title2") }}</b></div>
                            <p>{{ $t("privacyMv.P10.content") }}</p>
                            <b>{{ $t("privacyMv.P10.points.title") }}</b>
                            <p>{{ $t("privacyMv.P10.points.content") }}</p>
                            <ol>
                                <li class="point-cont">
                                    <div class="title-cont">{{ $t("privacyMv.P10.points.points.1.title") }}</div>
                                    <div class="content-cont ">{{ $t("privacyMv.P10.points.points.1.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P10.points.points.2.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P10.points.points.2.content") }}</div>
                                </li>
                                <li>
                                    <div class="title-cont">{{ $t("privacyMv.P10.points.points.3.title") }}</div>
                                    <div class="content-cont">{{ $t("privacyMv.P10.points.points.3.content") }}</div>
                                </li>
                            </ol>

                            <b>{{ $t("privacyMv.P11.title") }}</b>
                            <p>{{ $t("privacyMv.P11.content") }}</p>

                            <b>{{ $t("privacyMv.P12.title") }}</b>
                            <p>{{ $t("privacyMv.P12.content") }}</p>

                            <b>{{ $t("privacyMv.P13.title") }}</b>
                            <p>{{ $t("privacyMv.P13.content", {email: 'service@roojai.co.id'}) }}</p>

                        </div>
                    </div>
                    <div class="button-area">
                        <div class="modal-footer justify-content-center">
                            <button @click="close" type="button" style="border-radius: 0 0 .25rem .25rem;" class="btn w-100 btn-primary btn-block btn-confirm">{{$t("close")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PrivacyAndPolicy",
    methods: {
        close() {
            this.$emit("close");
            console.log('close')
        }
    }
}
</script>
<style scoped>
#privacy-modal #privacy {
  height: 60vh;
  overflow-y: auto;
  font-size: 14px;
  scrollbar-width: thin;
  scrollbar-color: #ddd orange;
}
.privacy-paragraph{
  margin-bottom: 0.5em;
  font-weight: bold;
}
#privacy-modal #privacy::-webkit-scrollbar {
  width: 8px;
}
#privacy-modal #privacy::-webkit-scrollbar-track {
  background: #dddddd;
  border-radius: 20px;
}
#privacy-modal #privacy::-webkit-scrollbar-thumb {
  background-color: #003b71;
  border-radius: 20px;
}
.privacy-body {
    position: relative;
}
.privacy-body button.close {
    position: absolute;
    top: -25px!important;
}
.privacy-content {
    margin: 0px 10px;
}
.privacy-body .subtitle {
    text-transform: uppercase;
}

.privacy-content ol .title-cont {
    font-weight: bold;
}
.privacy-content ol li {
    margin-bottom: 15px;
}
.bold {
    font-weight: bold;
}
.button-area {
    margin-bottom: -40px;
}

@media screen and (max-width: 480px) {
    .privacy-body button.close {
        top: -8px!important;
    }
}
</style>