<template>
<div class="postalCode">
    <div class="title">
        <h1>{{ $t('motorVehicle.driverLiving') }}</h1>
        <div class="tooltip-title">
            <Tooltip :text="textTooltip" />
        </div>
    </div>
    <div class="post-code-image">
        <img src="../../../../public/images/motor-vehicle/PostalCode.svg" />
    </div>
    <div class="postcode-form">
        <input 
            type="tel" 
            maxlength="5" 
            class="form-control dob2extra"
            placeholder="10230"
            v-model="answer"
            v-on:keyup.enter="nextToStepEnter"
            @input="triggerPostcode(answer)"
        />
        <i class="icon-valid glyphicons glyphicons-ok" v-if="isNumberValid"></i>
        <i class="loader spinner" v-if="isLoading"></i>
        <div class="ad-info" v-if="isAdInfo">
            {{ $t('motorVehicle.askPostalCode') }}
        </div>
        <div class="ad-info not-valid" v-if="showNotValid">
            <span class="glyphicons glyphicons-validate glyphicons-warning-sign animated swing"></span> {{$t("pleaseInsertAZipCode")}}
        </div>
    </div>
    <div class="button-area">
        <button 
            v-if="isButtonClick"
            class="btn btn-lg next2btn"
            :disabled="!isNumberValid"
            @click="setPostalCode"
            @blur="setPostalCode"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
</template>
<script>
import Tooltip from "./Tooltip.vue";
import { filterNumberOnly } from "../lib"
import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";
import { policyObject } from "../data/PolicyObject";

export default {
    name: "PostalCode",
    mixins: [Mixin],
    props: {
        defaultAnswer: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            Global,
            answer: null,
            isAdInfo: true,
            showNotValid: false,
            isNumberValid: false,
            isLoading: false,
            isButtonClick: true,
        }
    },
    components: { 
        Tooltip 
    },
    watch: {
        answer: function(value) {
            this.showNotValid = false;
            this.isNumberValid = false;
            this.answer = filterNumberOnly(value);

            if (value.length == 0) this.isAdInfo = true;
            else this.isAdInfo = false;
        }
    },
    mounted() {
        this.answerActive();
    },
    methods: {
        setPostalCode() {
            this.$emit('next', this.answer);
            this.isButtonClick = false;
        },
        triggerPostcode(value) {
            if(value.length == 5) {
                this.getProvince();
                this.isButtonClick = true;
            }
        },
        async getProvince() {
            this.isLoading = true;
            try {
                const response = await this.request().get(Global.restServer.url + "/postals?search=province&keyword=" + this.answer);
                this.isLoading = false;
                if (response.data?.model?.found == true) {
                    Global.isValidated.postcode = true;
                    //Global.motorVehicle.postalCodeDetails = response.data.model;
                    this.updateContactDetail(response.data.model);
                    this.isNumberValid = true;
                    this.showNotValid = false;
                    // this.eventLog("Postal Code", this.answer);
                } else {
                    Global.isValidated.postcode = false;
                    this.showNotValid = true;
                }
            } catch (error) {
                this.isLoading = false;
                console.error("getProvince => ", error);
            }
        },

        updateContactDetail(postalCodeRes){
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].subDistrictCode = postalCodeRes.subDistricts[0].list[0].name[1];
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].subDistrict = postalCodeRes.subDistricts[0].list[0].name[0];
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].postalCode = this.answer;
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].postalCodeText = this.answer;
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].districtCode = postalCodeRes.districts[0].name[1];
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].district = postalCodeRes.districts[0].name[0];
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].provinceCode = postalCodeRes.provinces[0].name[1];
            policyObject.root.quoteData.policyHolderData.contactAddresses[0].province = postalCodeRes.provinces[0].name[0];
            //policyObject.root.quote.policyHolderData.contactAddresses[0].houseNumber = Global.motorVehicle.phaccount.houseNumber;
            //policyObject.root.quote.policyHolderData.contactAddresses[0].soi = Global.motorVehicle.phaccount.soi;
        },

        nextToStepEnter(){
            if(this.isNumberValid && this.isButtonClick){
                this.$emit('next', this.answer);
                this.isButtonClick = false;
            }else{
                Global.isValidated.postcode = false;
                this.showNotValid = true;
            }
        },
        answerActive() {
            if(this.defaultAnswer == null) {
                this.answer = null;
            } else {
                this.answer = this.defaultAnswer;
                this.isButtonClick = false;
            }
        }
    },
    computed: {
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltip7');
        }
    }
}
</script>
<style scoped>
.postalCode {
    position: relative;
}
.postalCode .title {
    display: flex;
    position: relative;
}
.postalCode .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.postalCode h1{
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.postalCode .post-code-image {
    margin: 24px;
    text-align: center;
}
.postalCode .postcode-form {
    margin-bottom: 24px;
    position: relative;
}
.postalCode .postcode-form .icon-valid {
    position: absolute;
    right: 15px;
    top: 23px;
    font-size: 18px;
}
.postalCode .postcode-form .loader {
    position: absolute;
    right: 15px;
    top: 14px;
    font-size: 18px;
}

.ad-info {
    font-size: 16px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
    margin-top: 24px;
}
.dob2extra {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    color: #003B71;
    font-weight: 700;
    border-radius: 8px;
}
.dob2extra::-webkit-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::-ms-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::placeholder {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}

.postalCode .button-area {
    text-align: center;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.not-valid {
    margin-top: 24px;
    color: #FF4713;
}

.spinner {
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#0071d9 94%,#0000) top/3.8px 3.8px no-repeat,
          conic-gradient(#0000 30%,#0071d9);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
   animation: spinner-c7wet2 0.6s infinite linear;
}
@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}
</style>