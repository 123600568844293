<template>
    <div class="headerPD2">
            <div class="row header-body">
                <div class="col title-header">
                    <div class="main-price">
                        <span class="title-msg">{{$t('motorVehicle.paymentConfirmed')}}</span> <br>
                        <span class="sub-msg">{{$t('motorVehicle.paymentConfirmedMsg')}}</span>
                    </div>
                </div>
                <div class="col">
                    <img
                        :src="pd2logo"
                        :data-src="pd2logo"
                        alt="roojai.co.id"
                        class="lazy logo-roojai"
                        height="230"
                        width="305"
                    />
                </div>
            </div>
    </div>
    
    </template>
    <script>
    import pd2logo from "../../../../public/images/pd2.png";

    export default {
        name: "headerPD2",
        props: {
            sticky: {
                type: Boolean,
                default: true
            },
            price: {
                type: [Number, String],
                default: 0,
            },
            paymentType: {
                type: String,
                default: "Monthly"
            }
        },
        data() {
            return {
                pd2logo : pd2logo,
                showNavigation: false,
                showInstallment: true,
            }
        },
        created() {
            if(this.sticky) {
                window.addEventListener('scroll', this.onScrollNavbar);
            }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            onScrollNavbar() {
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                this.$emit('currentScrollPosition', currentScrollPosition)
                if (currentScrollPosition > 100) {
                    this.showNavigation = true;
                    setTimeout(()=> {
                        this.showInstallment = false;
                    },500);
                } else {
                    this.showNavigation = false;
                    setTimeout(()=> {
                        this.showInstallment = true;
                    },500);
                } 
    
            },
        }
    }
    </script>
    <style scoped>
    .headerPD2 {
        background: linear-gradient(180deg, #015CAF 0%, #003D75 100%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        top: 0;
        position: relative;
        width: 100%;
        min-height: 300px;
        display: block;
        z-index: 899;
        padding: 20px 0px 0px 0px;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        transition: opacity 0.5;
    }
    .headerPD2 .header-body{
        margin-top: 50px;
        padding: 0rem 8rem 0rem 8rem;
        margin-left:auto;
        margin-right:auto;
    }

    .headerPD2 .container .row .title-header{
        padding-left: 5px !important;
        margin-left: 0 !important;
    }

    .headerPD2 .stepper-header{
        top: 0;
    }
    
    .headerPD2 .logo-roojai{
        position: absolute;
        right: 0;
        bottom:0;
    }
    .headerPD2 .head-block {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
    }
    .headerPD2 .head-block .back-button {
        width: 25%;
        text-align: left;
    }
    .headerPD2 .head-block .instalment {
        width: 25%;
        text-align: right;
    }
    .headerPD2 .head-block .optiNUmber {
        width: 50%;
        text-align: center;
        color: #fff;
    }
    .headerPD2 .head-block button{
        background: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.8);
    }
    .headerPD2 .main-price {
        color: #fff;
        font-weight: 600;
        font-size: 48px;
        line-height: 36px;
        margin-bottom: 40px;
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .headerPD2 .main-price .title-msg {
        font-weight: 700;
        font-size: 40px;
        line-height: 54px;
        color: rgba(255, 255, 255, 1);
    }
    .headerPD2 .main-price .sub-msg {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 1);
    }
    .headerPD2 .ad-word {
        text-align: center;
        color: #fff;
        font-size: 12px;
    }
    .headerPD2 .ad-word i {
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 1px 6px;
        font-size: 10px;
    }
    .headerPD2 .installment-bottom {
        text-align: center;
        margin-bottom: 13px;
        margin-top: 5px;
    }
    
    .headerPD2 .installment-bottom .op-installment {
        background: transparent;
        font-size: 12px;
        color: #A8CEF1;
        border: 1px solid #A8CEF1;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.24));
        border-radius: 11px;
    }
    .stickyStyle {
        position:sticky!important;
    }
    @-webkit-keyframes rubberBand {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    @keyframes rubberBand {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    @-webkit-keyframes fadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
    @keyframes fadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
    @media screen and (max-width: 768px) {
        .headerPD2 .logo-roojai{
            position: relative;
            right: 0;
            bottom:0;
        }
    }
    
    @media screen and (max-width: 480px) {
        .headerPD2 {
            padding: 11px 0px;
        }
        .headerPD2 .logo-roojai{
            width: 200px;
            height: 140px;
            float: none;
            display: block;
            margin: 0 auto;
        }
        .headerPD2 .header-body{
            text-align:center;
            padding: 0rem 2rem 0rem 2rem;
        }
        .headerPD2 .main-price {
            font-size: 32px;
        } 
        .headerPD2 .main-price .title-msg {
            font-size: 36px;
            font-weight: 700;
        }
        .headerPD2 .main-price .sub-msg {
            font-size: 18px;
        }
        .headerPD2 .head-block button {
            font-size: 12px;
        }
        .headerPD2 .head-block .back-button {
            width: 30%;
        }
        .headerPD2 .head-block .instalment {
            width: 30%;
        }
        .headerPD2 .head-block .optiNUmber {
            width: 40%;
        }
    }
    </style>