<template>
<div class="dayOfBirth">
    <div class="title">
        <h1>{{ title }}</h1>
        <div class="tooltip-title">
            <Tooltip :text="textTooltip"/>
        </div>
    </div>
    <div class="dob-image">
        <img src="../../../../public/images/motor-vehicle/DOB.svg" />
    </div>
    <div class="dob-form col-xs-12">
        <div class="row mt-4 mb-5">
            <div class="col col-3">
                <input 
                    type="tel" 
                    maxlength="2" 
                    class="form-control dob2extra"
                    placeholder="01"
                    v-model="birth.day"
                    @blur="handleBlurDate"
                    @input="autoFocusOnMaxLength"
                    name="day"
                    data-next="month"
                    v-on:keyup.enter="nextToStepEnter"
                    id="input-dd"
                />
                <div class="mt-2 text-center">DD</div>
            </div>
            <div class="col col-3">
                <input 
                    type="tel" 
                    maxlength="2" 
                    class="form-control dob2extra monthFrm" 
                    placeholder="09" 
                    v-model="birth.month"
                    @blur="handleBlurMonth"
                    @input="autoFocusOnMaxLength"
                    name="month" 
                    data-next="year"
                    v-on:keyup.enter="nextToStepEnter"
                />
                <div class="mt-2 text-center">MM</div>
            </div>
            <div class="col col-6 label">
                <input 
                    type="tel" 
                    maxlength="4" 
                    class="form-control dob2extra"
                    placeholder="1982"
                    v-model="birth.year"
                    name="year"
                    v-on:keyup.enter="nextToStepEnter"
                />
                <div class="mt-2 text-center">YYYY</div>
            </div>
        </div>
        <div class="ad-info not-valid" v-if="isValid">
            <span class="glyphicons glyphicons-validate glyphicons-warning-sign animated swing"></span> {{$t("pleaseSelectAValidDate")}}
        </div>
    </div>
    <div class="button-area" v-if="isUseButtonAction">
        <button 
            class="btn btn-lg next2btn"
            :disabled="isButtonClick"
            @click="nextToStep"
            v-if="isShowButton"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
</template>
<script>
import Tooltip from './Tooltip.vue';
import { filterNumberOnly } from "../lib"
import Mixin from "@/components/Mixin";
import dayjs from "@/dayjs";

export default {
    name: "DayOfBirth",
    mixins: [Mixin],
    watch: {
        birth: {
            deep: true,
            handler: function(value) {
                const year = new Date().getFullYear();
                const isAdult = year - value.year > 17;
                const actualYear = year - value.year > 80;
                const actualDate = value.day > 31;
                const actualMonth = value.month > 12;
                this.birth.day = filterNumberOnly(value.day);
                this.birth.month = filterNumberOnly(value.month);
                this.birth.year = filterNumberOnly(value.year);
                const dob = String(this.birth.day + '/' + this.birth.month + '/' + this.birth.year);
                let validDob = dayjs(dob, "DD/MM/YYYY", true).isValid()
                if (this.birth.day !== '00' &  this.birth.day.length == 2 && actualDate ) {
                    this.birth.day = '';
                    this.isButtonClick = true;
                } else if(this.birth.month !== '00' && this.birth.month.length == 2 && actualMonth){
                    this.birth.month = '';
                    this.isButtonClick = true;
                } else if(this.birth.year.length == 4 && actualYear ){
                    this.isButtonClick = true;
                }else if(this.birth.day !== '00' &  this.birth.day.length == 2 && !actualDate && this.birth.month !== '00' && this.birth.month.length == 2 && !actualMonth && this.birth.year.length == 4 && !actualYear && validDob){
                    this.isButtonClick = false;
                    this.isValid = false;
                    this.$emit('actionFromChild', this.birth)
                }else{
                    this.isButtonClick = true;
                }

                if(this.defaultAnswer) {
                    if (this.birth.day !== '00' & this.birth.month !== '00' && this.birth.day.length == 2 && this.birth.month.length == 2 && this.birth.year.length == 4 && isAdult && !actualYear && !actualDate && !actualMonth) {
                        this.isValid = false;
                        const dob = String(this.birth.day + '-' + this.birth.month + '-' + this.birth.year);
                        this.$emit('update', dob);
                    } else  {
                        this.isValid = true;
                    }
                }
            }
        }
    },
    props: {
        title: {
            type: String,
            default: null
        },
        defaultAnswer: {
            type: String,
            default: null
        },
        isUseButtonAction: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            birth: {
                day: '',
                month: '',
                year: ''
            },
            //textTooltip: "The car usage influences how you drive and helps us determine your exact premium.",
            isButtonClick: true,
            isShowButton: true,
            isValid: false,
        };
    },
    components: { 
        Tooltip 
    },
    mounted() {
        this.recallDobWhenBack();
    },
    methods: {
        focusDDInput(){
            setTimeout(() => {
                document.getElementById("input-dd").focus()
            }, 200);
        },
        nextToStepEnter(){
            if (this.isUseButtonAction && !this.isButtonClick) {
                const dob = String(this.birth.day + '-' + this.birth.month + '-' + this.birth.year);
                this.$emit('next', dob);
                this.isShowButton = false;
            }else{
                this.isValid = true
            }
        },
        nextToStep() {
            const dob = String(this.birth.day + '-' + this.birth.month + '-' + this.birth.year);
            this.$emit('next', dob);
            this.isShowButton = false;
        },
        handleBlurDate() {
            if (this.birth.day.length == 1 && Number(this.birth.day) !== 0) {
                this.birth.day = "0"+this.birth.day;
            }
        },
        handleBlurMonth() {
            if (this.birth.month.length == 1 && Number(this.birth.month) !== 0) {
                this.birth.month = "0"+this.birth.month;
            }
        },
        recallDobWhenBack() {
            
            if(this.defaultAnswer !== null) {
                const dateString = this.defaultAnswer; 
                const dateParts = dateString.split("-");
                this.birth.day = String(dateParts[0]);
                this.birth.month = String(dateParts[1]);
                this.birth.year = String(dateParts[2]);
                this.isShowButton = false;
            }
        },
        autoFocusOnMaxLength(event) {
            const input = event.target;
            const maxLength = input.getAttribute("maxlength");
            const inputValue = input.value;
            if (inputValue.length >= maxLength) {
                const nextInput = input.getAttribute("data-next");
                if (nextInput) {
                    const next = document.querySelector(`input[name=${nextInput}]`);
                    if (next) {
                        next.focus();
                    }
                }
            }
        }
    },
    computed: {
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltipAge');
        }
    }
}
</script>
<style scoped>
.dayOfBirth {
    position: relative;
}
.dayOfBirth .title {
    display: flex;
    position: relative;
}
.dayOfBirth .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.dayOfBirth h3{
    font-size: 16px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
}
.dayOfBirth h1{
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.dob-image {
    margin: 24px;
    text-align: center;
}
.dob-form {
    margin: 0 auto;
    max-width: 360px;
}
.dob-form .col {
    padding-right: 5px!important;
    padding-left: 5px!important;
}
.dob2extra {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    color: #003B71;
    font-weight: 700;
    border-radius: 8px;
}
.dob2extra::-webkit-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::-ms-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::placeholder {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}

.dayOfBirth .button-area {
    text-align: center;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}
.ad-info {
    font-size: 16px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
    margin-top: 24px;
}

.not-valid {
    margin-top: 24px;
    color: #FF4713;
}
@media screen and (max-width: 480px) {
    .dayOfBirth h1{
        width: 93%;
    }
}
</style>