<template>
<div class="polisDate">
    <div class="title">
        <h1>{{ $t('motorVehicle.policyDate') }}</h1>
        <div class="tooltip-title" v-if="textTooltip.length !== 0">
            <Tooltip :text="textTooltip"/>
        </div>
    </div>
    <div class="text-polis">{{ $t('motorVehicle.policyDateText') }}</div>
    <div class="dob-image">
        <img src="../../../../public/images/motor-vehicle/Calendar.svg" />
    </div>
    <div class="dob-form col-xs-12">
        <div class="row mt-4 mb-5">
            <div class="col col-3">
                <input 
                    type="text" 
                    maxlength="2" 
                    class="form-control dob2extra"
                    :placeholder="placeholder.day"
                    v-model="startPolis.day"
                    name="dayPolis"
                    data-next="monthPolis"
                    @blur="handleBlurDate"
                    @input="autoFocusOnMaxLength"
                />
                <div class="mt-2 text-center">DD</div>
            </div>
            <div class="col col-3">
                <input 
                    type="text" 
                    maxlength="2" 
                    class="form-control dob2extra" 
                    :placeholder="placeholder.month" 
                    v-model="startPolis.month"
                    name="monthPolis" 
                    data-next="yearPolis"
                    @blur="handleBlurMonth"
                    @input="autoFocusOnMaxLength"
                />
                <div class="mt-2 text-center">MM</div>
            </div>
            <div class="col col-6 label">
                <input 
                    type="text" 
                    maxlength="4" 
                    class="form-control dob2extra"
                    :placeholder="placeholder.year"
                    name="yearPolis"
                    v-model="startPolis.year"
                />
                <div class="mt-2 text-center">YYYY</div>
            </div>
        </div>
    </div>
    <div class="button-area">
        <button 
            class="btn btn-lg next2btn"
            :disabled="isButtonClick"
            @click="nextToStep"
            v-if="isButtonShow"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
</template>

<script>
import Tooltip from './Tooltip.vue';
import { filterNumberOnly } from "../lib"

export default {
    name: "PolisDate",
    props: {
        globalData: {
            type: String,
            default: "" || null
        },
        textTooltip: {
            type: String,
            default: ""
        }
    },
    watch: {
        startPolis: {
            deep: true,
            handler: function(value) {
                const dob = String(value.year + '/' + value.month + '/' + value.day);
                let policy = new Date(dob).getTime();
                let now = new Date().getTime();
                let date = policy - now
                let days = date / (1000 * 3600 * 24);
                let thirtyDays = days >= -1 && days <= 90;
                this.startPolis.day = filterNumberOnly(value.day);
                this.startPolis.month = filterNumberOnly(value.month);
                this.startPolis.year = filterNumberOnly(value.year);
                if (this.startPolis.day.length == 2 && this.startPolis.month.length == 2 && this.startPolis.year.length == 4 && thirtyDays) {
                    this.isButtonClick = false;
                    this.isButtonShow = true;
                } else {
                    this.isButtonClick = true;
                }
            }
        }
    },
    data() {
        return {
            startPolis: {
                day: '',
                month: '',
                year: ''
            },
            isButtonClick: true,
            isButtonShow: true,
        }
    }, 
    components: { 
        Tooltip 
    },
    mounted(){
        if(this.globalData) {
            const dateParts = this.globalData.slice(0, 10).split("-");
            this.startPolis.day = dateParts[2];
            this.startPolis.month = dateParts[1];
            this.startPolis.year = dateParts[0];
        }
    },
    methods: {
        nextToStep() {
            const date = String(this.startPolis.day + '-' + this.startPolis.month + '-' + this.startPolis.year);
            this.$emit('next', date);
            this.isButtonShow = false;
        },
        handleBlurDate() {
            if (this.startPolis.day.length == 1 && Number(this.startPolis.day) !== 0) {
                this.startPolis.day = "0"+this.startPolis.day;
            }
        },
        handleBlurMonth() {
            if (this.startPolis.month.length == 1 && Number(this.startPolis.month) !== 0) {
                this.startPolis.month = "0"+this.startPolis.month;
            }
        },
        autoFocusOnMaxLength(event) {
            const input = event.target;
            const maxLength = input.getAttribute("maxlength");
            const inputValue = input.value;
            if (inputValue.length >= maxLength) {
                const nextInput = input.getAttribute("data-next");
                if (nextInput) {
                    const next = document.querySelector(`input[name=${nextInput}]`);
                    if (next) {
                        next.focus();
                    }
                }
            }
        }
    },
    computed: {
        placeholder() {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear());

            return {
                day: day,
                month: month,
                year: year
            };
        }
    }
}
</script>

<style scoped>
.polisDate {
    position: relative;
}
.polisDate .title {
    display: flex;
    position: relative;
}
.polisDate .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.polisDate h3{
    font-size: 16px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
}
.polisDate h1{
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.text-polis {
    text-align: center;
    color: #003B71;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-width: 315px;
    margin: 0px auto;
}
.dob-image {
    margin: 24px;
    text-align: center;
}
.dob-form {
    margin: 0 auto;
    max-width: 360px;
}
.dob-form .col {
    padding-right: 5px!important;
    padding-left: 5px!important;
}
.dob2extra {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    color: #003B71;
    font-weight: 700;
    border-radius: 8px;
}
.dob2extra::-webkit-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::-ms-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::placeholder {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}

.polisDate .button-area {
    text-align: center;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}

@media screen and (max-width: 480px) {
    .polisDate h1{
        width: 93%;
    }
}
</style>