<template>
<div v-if="!edit">
    <div class="mainDriver">
        <div class="identity">
            <h1 class="title" v-if="maritialStatus === 'Male-Single'">{{ $t('motorVehicle.maritalStatus_singleman') }}</h1>
            <h1 class="title" v-if="maritialStatus === 'Male-Married'">{{ $t('motorVehicle.maritalStatus_marriedman') }}</h1>
            <h1 class="title" v-if="maritialStatus === 'Female-Single'">{{ $t('motorVehicle.maritalStatus_singlewoman') }}</h1>
            <h1 class="title" v-if="maritialStatus === 'Female-Married'">{{ $t('motorVehicle.maritalStatus_marriedwoman') }}</h1>

            <h5>{{ $t('motorVehicle.dobDriver') }}</h5>
            <!-- <h5 v-if="maritialStatus === 'Male-Single'">{{ $t('motorVehicle.maritalStatus_singleman') }}</h5>
            <h5 v-if="maritialStatus === 'Male-Married'">{{ $t('motorVehicle.maritalStatus_marriedman') }}</h5>
            <h5 v-if="maritialStatus === 'Female-Single'">{{ $t('motorVehicle.maritalStatus_singlewoman') }}</h5>
            <h5 v-if="maritialStatus === 'Female-Married'">{{ $t('motorVehicle.maritalStatus_marriedwoman') }}</h5> -->
            <h2 v-if="dob">{{ dateFormated(dob) }}</h2>
        </div>
        <div class="image-driver">
            <img class="img-male-single" src="images/motor-vehicle/Singleman-policy-detail.svg" v-if="maritialStatus === 'Male-Single'">
            <img class="img-male-married" src="images/motor-vehicle/Mariedman-policy-detail.png" v-if="maritialStatus === 'Male-Married'">
            <img class="img-female-single" src="images/motor-vehicle/SInglewoman-policy-detail.png" v-if="maritialStatus === 'Female-Single'">
            <img class="img-female-married" src="images/motor-vehicle/Mariedwoman-policy-details.png" v-if="maritialStatus === 'Female-Married'">
        </div>
        
        <!-- <div class="edit-btn" @click="edit = true" v-if="!edit">edit</div> -->
    </div>
    <div class="button-area">
        <button 
            class="btn btn-lg next2btn"
            :disabled="isButtonClick"
            @click="nextToStep"
            v-if="isShowButton"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
<div v-else>
    <GenderAndStatus 
        is-policy-details
        @next="genderAndStatusFill"
        :defaultAnswer="valueData.genderAndStatus"
    />
    <DayOfBirth 
        :title="$t('motorVehicle.dobDriver')"
        :defaultAnswer="valueData.dob"
        @actionFromChild="updateDob"
        :isUseButtonAction="false"
    />
    <div class="button-area">
        <button 
            class="btn btn-lg next2btn"
            :disabled="isButtonClick"
            @click="nextToStep"
            v-if="isShowButton"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
</template>

<script>
import { dmyFormatedDate } from "../lib/index.js"
import DayOfBirth from "./DayOfBirth.vue";
import GenderAndStatus from "./GenderAndStatus.vue";

export default {
    name: "MainDriver",
    props: {
        maritialStatus: {
            type: String,
            default: "Male-Single"
        },
        dob: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            edit: false,
            isButtonClick: false,
            isShowButton: true,
            valueData: {
                genderAndStatus: null,
                dob: null
            }
        }
    },
    watch: {
        valueData: {
            deep: true,
            handler: function(value) {
                if(value.genderAndStatus !== null && value.dob !== null) {
                    this.isShowButton = true;
                } else {
                    this.isShowButton = false;
                }
            }
        },
        edit:function(value) {
            if (value) {
                this.isShowButton = true;
                if(this.maritialStatus && this.dob.length > 0) {
                    this.valueData.dob = this.dateDefault(this.$props.dob);
                    this.valueData.genderAndStatus = this.$props.maritialStatus;
                }
            }
        }   
    },
    components: {
        GenderAndStatus,
        DayOfBirth
    },
    mounted() {
        this.$emit('action');
    },
    methods: {
        removeDash(str) {
            return str.replace(/-/g, " ");
        },
        dateFormated(date){
            return dmyFormatedDate(date);
        },
        dateDefault(date) {
            const dateParts = date.split("-");
            let d, m, y;
            d = String(dateParts[2]);
            m = String(dateParts[1]);
            y = String(dateParts[0]);
            return d+"-"+m+"-"+y;
        },
        updateDob(value) {
            const dob = String(value.day + '-' + value.month + '-' + value.year);
            this.valueData.dob = dob;
        },
        genderAndStatusFill(value) {
            this.valueData.genderAndStatus = value;
        },
        nextToStep() {
            this.$emit('action', this.valueData);
            this.edit = false;
            this.isShowButton = false;
        }
    }

}
</script>

<style scoped>
.mainDriver {
    position: relative;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 16px;
}
.mainDriver .identity {
    text-align: center;
}
.mainDriver .identity .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #003B71;
}
.mainDriver .identity h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #003B71;
}
.mainDriver .identity h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #003B71;
}
.mainDriver .image-driver {
    position: absolute;
    right: 60px;
    bottom: 0;
}
.mainDriver .edit-btn {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 24%);
    border-radius: 11px;
    color: #FF4713;
    font-weight: 500;
    font-size: 12px;
    width: 60px;
    text-align: center;
    padding: 2px 0px;
    position: absolute;
    bottom: -11px;
    cursor: pointer;
}
.mainDriver .edit-btn:hover {
    color: #003b71;
}
.button-area {
    text-align: center;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}
@media screen and (max-width: 480px) {
    .mainDriver .image-driver {
        position: absolute;
        right: 15px;
        bottom: 0;
    }
    .mainDriver .image-driver .img-male-single {
        width: 112px;
        right: -18px!important;
        bottom: 0;
        position: absolute;
    }

    .mainDriver .image-driver .img-female-married {
        width: 120px;
        right: -29px!important;
        bottom: 0;
        position: absolute;
    }

    .mainDriver .image-driver .img-female-single {
        width: 130px;
        right: -33px!important;
        position: absolute;
        bottom: 0;
    }

    .mainDriver .image-driver .img-male-married {
        width: 112px;
        right: -18px!important;
        bottom: 0;
        position: absolute;
    }
}

</style>