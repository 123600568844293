<template>
    <div class="mv-policydetails">
        <HeaderPD2 
            thankyou2
            @currentScrollPosition="getCurrentScrollPOsition"
        />
        <div class="section policyDetails">
            <button class="btn btn-gohome btn-block" data-selenium-name="floating-buynow" @click="gobackhome();">
                {{$t("motorVehicle.gobackhome")}}
            </button>
        </div>
    </div>
    </template>
    
    <script>
    import Global from "@/store/global.js";
    import Mixin from "@/components/Mixin";
    import HeaderPD2 from './components/Headerthankyou.vue';
    
    export default {
        name: "PolicyDetails",
        data() {
            return {
                Global,
            }
        },
        components: {
            HeaderPD2
        },
        mixins: [Mixin],
        created(){
            window.addEventListener('scroll', this.onScrollNavbar);
            window.scrollTo(0, 0);
        },
        mounted() {
            this.loading("hide")
            // this.fetchOccupations();
            // window.scrollTo(0, 0);
            // Global.motorVehicle.radarCallType = "2";
            // clientRegInfo = getRegisterIGNSdk();
        },
        methods: {
            gobackhome() {
                location.reload();
            },
            getCurrentScrollPOsition(value) {
                Global.motorVehicle.scrollPositionPD2 = value;
            }
        }
    }
    </script>
    
    <style scoped>
    .section {
        max-width: 604px;
        width: 100%;
        padding: 0 15px;
        margin: auto;
    }
    .questions-section {
        padding: 70px 0px;
    }
    .nextToPayment {
        padding: 30px 0px;
        text-align: center;
    }
    .btn-gohome{
        width: 100%;
        margin: auto;
        font-weight: 600;
        font-size: 20px;
        border-radius: 8px;
        margin-top: 8rem;
        margin-bottom: 4rem;
        border-color: #ff5011;
        color: #ff5011;
        background-color: #fff;
    }
    @media screen and (max-width: 480px) {
        .btn-gohome{
            margin-top: 14rem;
        }
    }
    @media screen and (max-width: 679px) {
        .btn-gohome{
            margin-top: 16rem;
        }
    }
    
    </style>