<template>
    <div class="mv-redeem">
        <div id="progress-line-area" class="text-center px-0 sticky-top">
            <div class="progress">
                <nav class="WizardSteps">
                    <a class="WizardSteps__step" :class="page1 ? 'active': 'complete'">
                        <span class="WizardSteps__status" v-if="page1">
                        <span class="WizardSteps__status-text"> 1</span>
                        </span>
                        <img
                            v-if="page2 || page3"
                            :src="redeemCheck"
                            :data-src="redeemCheck"
                            alt="roojai.co.id"
                            class="lazy check-redeem"
                        />

                        <h4 class="WizardSteps__title">{{$t('motorVehicle.redeemStep1')}}</h4>
                    </a>

                    <hr class="WizardSteps__line" />

                    <a class="WizardSteps__step" :class="page1 ? 'complete' : 'active'">
                        <span class="WizardSteps__status" v-if="page1 || page2">
                        <span class="WizardSteps__status-text">2</span>
                        </span>
                        <img
                            v-if="page3"
                            :src="redeemCheck"
                            :data-src="redeemCheck"
                            alt="roojai.co.id"
                            class="lazy check-redeem"
                        />

                        <h4 class="WizardSteps__title">{{$t('motorVehicle.redeemStep2')}}</h4>
                    </a>

                </nav>
            </div>
        </div>
        <div class="d-flex headerRedeem">
            <div class="main-price text-left">
                <span class="title-msg">{{$t('motorVehicle.redeemTitle')}}</span> <br>
            </div>
            <img
                :src="redeemIcon"
                :data-src="redeemIcon"
                alt="roojai.co.id"
                class="lazy logo-redeem"
            />
        </div>
        <div class="section">
            <div v-show="page1">
                <h4 class="redeemMsg">{{$t('motorVehicle.redeemMsg1')}}</h4>
                <p class="text-center" style="padding:8px 0px;">{{$t('motorVehicle.redeemMsg2')}}</p>
                <label for="inp" class="inp">
                    <!-- :class="{success:isInvalidatePhoneNumber == false}" v-model.trim="policyObject.root.quoteData.policyHolderData.phoneNumber" -->
                    <input type="text" v-model.trim="voucherCode" id="inp" maxlength="16" minlength="10"  placeholder="&nbsp;">
                    <span class="label">{{$t('motorVehicle.redeemPlace')}}</span>
                    <span class="focus-bg"></span>
                </label>
                <p class="error-msg" v-if="isInvalidateVoucherCode"> <img src="/images/motor-vehicle/warning.svg" alt="" style="margin-right:8px;"> <span v-if="isCodeNotFound">{{$t('motorVehicle.redeemErrVoucher')}}</span> <span v-if="isAlreadyRedeem">{{$t('motorVehicle.alreadyRedeem')}}</span> </p>
                <!-- <p class="example-msg">{{$t('motorVehicle.redeemExmVoucher')}}</p> -->
                <div class="loc-input" style="margin-top: 16px;">
                    <span class="loc-placeholder">{{$t('motorVehicle.redeemLocation')}}</span>
                    <br>
                    <div class="marquee-section">
                        <div class="marquee-div">
                            <div class="marquee">
                                <span v-for="i in 30" :key="i" style="padding-right:16px;">
                                    {{ location }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-show="page2">
                <div class="box-grey">
                    <p class="grey-msg">{{$t('motorVehicle.redeemValidVoucher', {code: voucherCode} )}}</p>
                    <div class="box-owner">
                        <div class="header-owner">
                            <p class="msg">{{$t('motorVehicle.redeemHeaderVoucher')}}</p>
                        </div>
                        <div class="body-owner">
                            <p class="key">{{$t('motorVehicle.redeemNoWa')}}</p>
                            <p class="value">{{voucherOwner.phone}}</p>
                            <div class="d-flex" style="margin-top:16px">
                                <div class="col flex-column" style="margin-right:8px;padding-left: 0px;">
                                    <p class="key">{{$t('motorVehicle.redeemEmail')}}</p>
                                    <p class="value">{{voucherOwner.email}}</p>
                                </div>
                                <div class="col flex-column" style="margin-right:8px;padding-left: 0px;">
                                    <p class="key">{{$t('motorVehicle.redeemCarPlate')}}</p>
                                    <p class="value">{{voucherOwner.carPlate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="loc-input" style="margin-top: 16px;">
                    <span class="loc-placeholder">{{$t('motorVehicle.redeemLocation')}}</span>
                    <br>
                    <div class="marquee-section">
                        <div class="marquee-div">
                            <div class="marquee">
                                <span v-for="i in 30" :key="i" style="padding-right:16px;">
                                    {{ location }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-center" style="padding:16px 0px;">{{$t('motorVehicle.redeemHintVoucher')}}</p>
            </div>
            <div class="success-redeem" v-show="page3">
                <img src="/images/motor-vehicle/shield-check.svg" alt="" style="margin-right:8px;">
                <h5 class="title">{{$t('motorVehicle.successRedeemVoucherTitle')}}</h5>
                <p class="msg">{{$t('motorVehicle.successRedeemVoucherMsg')}}</p>
            </div>
            <div class="thankyou-button">
                <button v-show="page1" @click="getDetailOwnVoucher();">{{ $t("motorVehicle.redeemBtn") }}</button>
                <button v-show="page2" @click="modalPasscode=true;">{{ $t("motorVehicle.redeemVerifyBtn") }}</button>
            </div>
        </div>
        <div class="d-flex callus-redeem" v-if="page1 || page2">
            <span class="msg"> {{ $t('motorVehicle.redeemCallUs') }} </span>
            <a class="link-callme"  href="tel:02150890821">
                <span>{{ $t("motorVehicle.callUs") }}</span>
            </a>
        </div>
        <Modal 
            :show="modalPasscode" 
            width="100%"
            @close="modalPasscode = false;passCode = null;isInvalidateVoucherPassCode = null"
            :classModal="{'slideIn':modalPasscode}"
            :title="$t('motorVehicle.redeemPassCodeTitle')" 
            class="modal-call-phone"
        >
            <div class="call-me-back">
                <div class="call-me-back-body">
                    <div class="text-muted" style="margin-top:16px">
                        <span>{{ $t("motorVehicle.redeemPassCodeMsg") }}</span>
                    </div>
                    <label for="inp" class="inp">
                        <input type="password" id="inp" maxlength="13" minlength="4" v-model.trim="passCode" placeholder="&nbsp;">
                        <span class="label">{{$t('motorVehicle.redeemPassCodePlaceHolder')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                    <!-- <p class="error-msg" v-if="isInvalidateVoucherPassCode"> <img src="/images/motor-vehicle/warning.svg" alt="" style="margin-right:8px;"> {{$t('motorVehicle.redeemPassCodeError')}}</p> -->
                    <!-- <p class="example-msg">{{$t('motorVehicle.redeemExmVoucher')}}</p> -->
                    <div class="alert alert-redeem" role="alert" v-if="isInvalidateVoucherPassCode">
                        <div class="d-flex" style="align-items: center;">
                            <img class="img-msg-redeem-upd" src="/images/motor-vehicle/WarnRedeem.svg" />
                            <span class="alert-msg-redeem noto"> {{ $t('motorVehicle.redeemPassCodeError') }} </span>
                            <a class="link-callme"  href="tel:02150890821">
                                <span>{{ $t("motorVehicle.callUs") }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="call-me-back-footer">
                    <button type="button" class="btn-cancel" @click="modalPasscode = false;passCode = null;isInvalidateVoucherPassCode = null">
                        {{ $t("motorVehicle.back") }}
                    </button>

                    <button type="button" class="btn-confirm" @click="verifyPasscode();counting = false">
                        {{ $t("motorVehicle.confirmation") }}
                    </button>
                </div>
            </div>
        </Modal> 
    </div>
    </template>
    
    <script>
    import Global from "@/store/global.js";
    import Mixin from "@/components/Mixin";
    import Modal from "./components/Modal.vue";
    import HeaderPD2 from './components/Headerthankyou.vue';
    import redeemIcon from "../../../public/images/motor-vehicle/Redeem.svg";
    import redeemCheck from "../../../public/images/motor-vehicle/checkRedeem.svg";
    
    export default {
        name: "PolicyDetails",
        data() {
            return {
                Global,
                redeemIcon : redeemIcon,
                redeemCheck : redeemCheck,
                voucherCode: "",
                isInvalidateVoucherCode: null,
                isInvalidateVoucherPassCode: null,
                modalOTP: null,
                modalPasscode: null,
                voucherOwner:{
                    carPlate : null,
                    email : null,
                    phone : null,
                    voucherId : null,
                },
                isCodeNotFound: null,
                isAlreadyRedeem: null,
                passCode: null,
                location: '',
                page1: false,
                page2: false,
                page3:false
            }
        },
        components: {
            HeaderPD2,
            Modal
        },
        mixins: [Mixin],
        created(){
            window.addEventListener('scroll', this.onScrollNavbar);
            window.scrollTo(0, 0);
            if(this.$route.query.loc !== undefined){
                this.location = this.$route.query.loc;
                console.log(this.location);
                this.$router.replace({name: 'mv-redeem'});
                sessionStorage.setItem("redeemLocation",this.location);
            }
            if (sessionStorage.getItem("redeemLocation") !== null) {
                this.location = sessionStorage.getItem("redeemLocation")
            }

            if (this.location === '') {
                this.$router.push({name: 'mv-input'});
            }
            this.location = this.location.replace(/%20/g,' ')
            this.location = this.location.replace(/%26/g,'&')
        },
        watch:{
            "voucherCode": function(value) {
                this.voucherCode = value.toUpperCase();
            }
        },
        computed:{
            locationRedeem(){
                this.location = this.location.replace(/%20/g,' ')
                if (this.location.length < 35) return this.location;
                    else return this.location.slice(0, 35) + '  ...';
            }
        },
        mounted() {
            this.loading("hide")
            this.page1 = true
        },
        methods: {
            async getDetailOwnVoucher(){
                this.loading("show");
                this.isAlreadyRedeem = null
                this.isCodeNotFound = null
                var response = await this.request().get(
                    Global.roojaiService.url +
                    "/rewardPoints/voucherdetails?serial=" +
                    this.voucherCode.replace(/&/g, "%26"),
                    {
                        headers: { Authorization: "Bearer " + Global.roojaiService.token }
                    }
                    // "https://ddd7b2b5-27f0-4c86-aea3-af6e23dd0dc6.mock.pstmn.io/api/rewardPoints/voucher?serial=My-Vocher-RIA"
                );
                if (response.data.data != null) {
                    if (response.data.data.verified == true ) {
                        this.isInvalidateVoucherCode = true
                        this.isAlreadyRedeem = true
                        this.loading("hide");
                    }
                    else{
                        this.voucherOwner.carPlate = response.data.data.carPlateNumber
                        this.voucherOwner.email = response.data.data.email
                        this.voucherOwner.phone = response.data.data.phone
                        this.voucherOwner.voucherId = response.data.data.voucherId
                        this.loading("hide");
                        this.page1 = false
                        this.page2 = true
                    }
                }else{
                    this.isCodeNotFound = true
                    this.isInvalidateVoucherCode = true
                    this.loading("hide");
                }
            },
            async verifyPasscode(){
                this.loading("show");
                var response = await this.request().post(
                    Global.roojaiService.url + "/rewardPoints/voucherapprove", 
                    {                        
                        "voucherId": this.voucherOwner.voucherId,
                        "passCode": this.passCode,
                        "location": this.location
                    },
                    {
                        headers: { Authorization: "Bearer " + Global.roojaiService.token }
                    }
                    // 'https://ddd7b2b5-27f0-4c86-aea3-af6e23dd0dc6.mock.pstmn.io/api/rewardPoints/voucher/approve',
                    // {
                    // "voucherId": "1",
                    // "passCode": "1234"
                    // }
                );
                if (response.data.success == true) {
                    console.log(response);
                    this.page1 = false
                    this.page2 = false
                    this.page3 = true
                    this.modalPasscode = false
                    this.loading("hide");
                }else{
                    this.loading("hide");
                    this.isInvalidateVoucherPassCode = true
                }
            }
        }
    }
    </script>
    
    <style scoped>
    .callus-redeem{
        background:#E6F9EB;
        color:#000;
        position: fixed;
        width: 100%;
        bottom: 0;
        padding: 8px 16px;
        justify-content: center;
    }
    .callus-redeem .msg{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    .link-callme{
        color: #EF4A24;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        text-decoration-color: #EF4A24;
        text-decoration-thickness: 2.3px;
        text-underline-offset: 5px;
        line-height: 18px;
        cursor: pointer;
        margin-left: 8px;
        font-size: 10px;
        font-weight: 700;
        align-self: center;
    }
    .alert-redeem{
        background: #FFF1F1;
        border-width: 0.5px 0.5px 3px 0.5px;
        border-style: solid;
        border-color: #FF8389;
        border-radius: 4px;
        margin-top: 16px;
    }
    .img-msg-redeem-upd{
        margin-top: 0px;
        height: 20px;
        width: 20px;
    }
    .img-msg-redeem{
        height: 20px;
        width: 20px;
    }
    .alert-msg-redeem{
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #000;
        max-width: 70%;
        margin-left: 12px;
        align-self: center;
    }
    .check-redeem{
        margin-right: 8px;
    }
    .btn-cancel{
        height: 40px;
        padding: 6px;
        width: 130px;
        border-radius: 8px;
        margin-bottom:0px;
        font-weight: 800;
        border: 1px solid #C73E1E;
        transition: background 0.5s step-start;
    }
    .btn-cancel:hover{
        background: #FFE7E1;
    }

    .btn-confirm{
        height: 40px;
        padding: 6px;
        width: 130px;
        font-weight: 800;
        border-radius: 8px;
        margin-bottom:0px;
        background-color: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
        transition: background 0.5s step-start;
    }
    .btn-confirm:hover{
        background-color: #C73E1E;
    }
    .call-me-back {
        padding: 0px 16px 16px 16px;
    }

    .call-me-back h5 {
        text-align: center;
        margin-bottom: 16px;
    }

    .call-me-back .call-me-back-body {
        margin-bottom: 16px;
    }

    .call-me-back .call-me-back-footer {
        display: flex;
        margin-bottom: 0px;
    }

    .call-me-back .call-me-back-footer button {
        margin-right: 8px;
    }

    .call-me-back .call-me-back-footer button:last-child {
        margin-right: 0px;
    }

    .call-me-back .text-muted {
        display: flex;
        padding-bottom: 16px;
        max-width: 245px;
    }

    .call-me-back .text-muted .glyphicons {
        margin-right: 5px;
    }
    .key{
        margin-bottom: 0px;
        color: #6F6F6F;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    .value{
        margin-bottom: 0px;
        color:#161616;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
    }
    .success-redeem{
        text-align: center;
    }
    .success-redeem .title{
        color: #393939;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px; 
    }
    .success-redeem .msg{
        color: #393939;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .box-grey{
        padding: 16px;
        border-radius: 8px;
        border: 2px solid #F2F2F2;
        background:#E8E8E8;
    }
    .box-owner{
        border-radius: 4px;
        background: #FFF;        
    }
    .header-owner{
        padding: 16px;
        background:#003A6E;
        color: #fff;
        text-align: center;
    }
    .body-owner{
        padding: 16px;
    }
    .grey-msg{
        color: #393939;
        font-family: 'Noto Sans';
        font-size: 12px;
        text-align: center;
        font-weight: 700;
        line-height: 20px;
    }
    .header-owner .msg{
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 21px;
        margin-bottom: 0px;
    }

    .inp {
        position: relative;
        margin: auto;
        width: 100%;
        border-radius: 3px;
        overflow: hidden;
    }
    .inp .label {
        position: absolute;
        top: 17px;
        left: 12px;
        font-size: 14px;
        color: #525252;
        font-weight: 500;
        transform-origin: 0 0;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;
        pointer-events: none;
    }
    .inp .focus-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        z-index: -1;
        transform: scaleX(0);
        transform-origin: left;
    }
    .inp input {
        -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
        width: 100%;
        border: 0;
        font-family: inherit;
        padding: 16px 12px 0 12px;
        height: 54px;
        font-size: 14px;
        font-weight: 400;
        background: #fff;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        color: #000 !important;
        transition: all 0.15s ease;
    }
    .inp input:hover {
        background: #fff;
        border: 1px solid #000;
    }
    .inp input:not(:-moz-placeholder-shown) + .label {
        color: #00539A;
        font-size: 12px;
        font-weight: 700;
        top: 20px;
        transform: translate3d(0, -12px, 0) scale(0.75);
    }
    .inp input:not(:-ms-input-placeholder) + .label {
        color: #00539A;
        font-size: 12px;
        font-weight: 700;
        top: 20px;
        transform: translate3d(0, -12px, 0) scale(0.75);
    }
    .inp input:not(:placeholder-shown) + .label {
        color: #00539A;
        font-size: 12px;
        font-weight: 700;
        top: 20px;
        transform: translate3d(0, -12px, 0) scale(0.75);
    }
    .inp input:focus {
        background: #fff;
        outline: none;
        border: 2px solid #0072C3;
    }
    .inp input.success{
        background-color: #E6F9EB;
        outline: none;
        border: 2px solid #0E6027;
        background-image: url('../../../public/images/motor-vehicle/checkForm.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: top 20px right 4px;
    }

    .inp input:focus + .label {
        color: #00539A;
        font-size: 12px;
        font-weight: 700;
        top: 20px;
        transform: translate3d(0, -12px, 0) scale(0.75);
    }
    .inp input:focus + .label + .focus-bg {
        transform: scaleX(1);
        transition: all 0.1s ease;
    }
    .error-msg{
        background: #FFF1F1;
        border-radius: 4px;
        padding: 4px 4px 6px 8px;
        color: #DA1E28;
        font-size:10px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
    }
    .example-msg{
        background: #E2EFF9;
        border-radius: 4px;
        padding: 4px 4px 6px 8px;
        color: #003A6E;
        font-size:10px;
    }
    .thankyou-button{
        margin-top: 16px;
    }
    .thankyou-button button {
        background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
        border-radius: 8px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        width: 100%;
        border: 0;
        padding: 13px;
        transition: background 0.5s step-start;
    }
    .thankyou-button button:hover {
        background: #C73E1E;
    }
    .mv-redeem{
        font-family: 'Public Sans';
    }
    .loc-input{
        background-color: #E6F9EB;
        outline: none;
        border: 2px solid #0E6027;
        padding: 4px 12px 0px 8px;
        height: 54px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 8px;
        overflow: hidden
    }
    .loc-placeholder{
        color:#00539A;
        font-size: 10px;
        font-weight: 700;
    }
    .marquee-section {
        position: relative;
        min-height: 82px;
    }

    .marquee-section, .marquee-section * {
        overflow: hidden;
    }

    .marquee {
        white-space: nowrap;
        color:#000;
        font-size: 12px;
    }

    .marquee-div {
        position: absolute;
        animation: move-right-to-left linear 90s infinite;
    }

    @-moz-keyframes move-right-to-left {
        0% {
            -moz-transform: translateX(0);
        }
        100% {
            -moz-transform: translateX(-50%);
        }
    }

    @-webkit-keyframes move-right-to-left {
        0% {
            -webkit-transform: translateX(0);
        }
        100% {
            -webkit-transform: translateX(-50%);
        }
    }

    /* use this one to move from right to left direction */
    @keyframes move-right-to-left {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-50%);
        }
    }

    .WizardSteps__line {
        flex-grow: 1;
        display: block;
        width: 100%;
        height: 1px;
        margin: 0;
        padding: 0;
        border: 0 none transparent;
        font-size: 10px;
        background-color: #fff;
    }

    .WizardSteps__status {
        box-sizing: border-box;
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #fff;
        background: #fff;
        border-radius: 50%;
        line-height: 14px;
        text-align: center;
    }

    .WizardSteps__status-text {
        color: #007CCF;
    }

    .WizardSteps__title {
        margin: 0;
        white-space: nowrap;
        font-weight: 300;
        font-size: 10px;
        color: #fff;
        line-height: 16px;
    }

    .WizardSteps__step {
        display: flex;
        flex-grow: 2;
        flex-wrap: nowrap;
    }

    .WizardSteps__step > .WizardSteps__status {
        margin-right: 8px;
    }

    .WizardSteps > .WizardSteps__line {
        margin-left: 8px;
        margin-right: 8px;
    }

    .WizardSteps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    /* Complete status styles */
    .WizardSteps__step.complete > .WizardSteps__title {
        color: #82CFFF;
    }

    .WizardSteps__step.complete > .WizardSteps__status {
        border-color: #82CFFF;
        background: #82CFFF !important;
    }

    .WizardSteps__step.complete > .WizardSteps__status > .WizardSteps__status-text {
        color: #007CCF;
    }

    .WizardSteps__step.complete + .WizardSteps__line {
        background-color: #33B1FF;
    }

    /* Active status styles */
    .WizardSteps__step.active > .WizardSteps__title {
        font-weight: 400;
    }

    .WizardSteps__step.active > .WizardSteps__status {
        border-width: 2px;
        line-height: 14px;
    }

    .WizardSteps__step.active > .WizardSteps__status > .WizardSteps__status-text {
        font-weight: 700;
    }

    .WizardSteps__step.active + .WizardSteps__line {
        height: 2px;
    }

    #progress-line-area {
        /* height: 20px; */
        margin-bottom: 0px;
        border-radius: 0;
        margin-top: 0px;
        width: 100%;
        background-color: #007CCF;
    }
    #progress-line-area.fixed{
        position: fixed;
        margin-top: 0;
        left: 0;
        top: 0;
        z-index: 1100;
    }
    #progress-line-area .progress {
        display: flex;
        justify-content: center;
        border-radius: 0;
        padding: 14px 0px;
        /* height: 20px; */
        margin: auto;
        background-color: #007CCF;
        max-width:375px;
    }
    .headerRedeem{
        background: linear-gradient(180deg, #015CAF 0%, #003D75 100%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        top: 0;
        position: relative;
        width: 100%;
        max-height: 240px;
        border-bottom: 2px solid #003D75;
        border-radius: 0px 0px 64px 148px;
        align-items: center;
        display: flex;
        justify-content: center;
        z-index: 899;
        padding: 16px 160px;
        -webkit-animation-name: fadeIn-595fed16;
        animation-name: fadeIn-595fed16;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        transition: opacity 0.5;
    }
    .headerRedeem .main-price .title-msg {
        font-weight: 800;
        font-size: 54px;
        line-height: 60px;
        color: rgba(255, 255, 255, 1);
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .headerRedeem .main-price .message-header .sub-msg {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 1);
    }
    .headerRedeem .main-price .message-header{
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }
    .logo-redeem{
        height: 200px;
        width: 200px;
        float: right;
        margin-left: 40px;
    }
    .section {
        max-width: 604px;
        width: 100%;
        padding: 16px 16px;
        margin: auto;
    }
    .redeemMsg{
        color: #393939;
        margin: 0px 0px 0px 0px;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
    }
    @media screen and (max-width: 480px) {
        #progress-line-area .progress {
            max-width:280px;
        }
        .headerRedeem{
            border-radius: 0px 0px 16px 40px !important;
            padding: 16px !important;
            justify-content: space-between;
        }
        .headerRedeem .main-price .title-msg  {
            font-size: 20px !important;
            font-weight: 800;
            line-height: 28px !important;
        }
        .logo-redeem{
            height: 120px !important;
            width: 96px !important;
            margin-left: 0px !important;
        }
        .value{
            word-wrap: break-word;
        }
    }
    @media screen and (min-width: 481px) and (max-width: 679px) {
        .headerRedeem{
            border-radius: 0px 0px 16px 40px !important;
            padding: 16px !important;
            justify-content: space-between;
        }
        .headerRedeem .main-price .title-msg  {
            font-size: 24px !important;
            font-weight: 800;
            line-height: 32px !important;
        }
        .logo-redeem{
            height: 120px !important;
            width: 120px !important;
            margin-left: 0px !important;
        }
    }
    
    </style>