<template>
    <div id="privacy-modal" class="insure-modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body privacy-body">
                    <button 
                        type="button" 
                        class="close" 
                        style="color: rgb(244, 67, 54); opacity: 0.8;"
                        @click="close"
                    >
                        <span>x</span>
                    </button>
                    <h2 class="h1 section-text2 h4 text-center mb-3">
                        {{ $t("limitation.title") }}
                    </h2>
                    <div id="privacy">
                        <div class="privacy-content">
                            <h6 class="mb-3 subtitle">
                                {{ $t("limitation.subtitle") }}
                            </h6>
                            <ol>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.1") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.2") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.3") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.4") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.5") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.6") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.7") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.8") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.9") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.10") }}</div>
                                </li>
                                <li class="point-cont">
                                    <div class="content-cont">{{ $t("limitation.points.11") }}</div>
                                </li>
                            </ol>
                            {{ $t("limitation.points.next") }} 
                            <a
                                href="https://www.roojai.co.id/en/terms-and-conditions/"
                                target="_blank"
                                v-if="$i18n.locale == 'en'"
                                class="link-to"
                                >{{ $t("limitation.points.tnc") }}</a>
                            <a
                                href="https://www.roojai.co.id/terms-and-conditions/"
                                target="_blank"
                                v-if="$i18n.locale == 'id'"
                                class="link-to"
                                >{{ $t("limitation.points.tnc") }}</a>
                        </div>
                    </div>
                    <div class="button-area">
                        <div class="modal-footer justify-content-center">
                            <button @click="close" type="button" style="border-radius: 0 0 .25rem .25rem;" class="btn w-100 btn-primary btn-block btn-confirm">{{$t("close")}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LimitationAndLiability",
    methods: {
        close() {
            this.$emit("close");
        }
    }
}
</script>
<style scoped>
#privacy-modal #privacy {
  height: 60vh;
  overflow-y: auto;
  font-size: 14px;
  scrollbar-width: thin;
  scrollbar-color: #ddd orange;
}
.privacy-paragraph{
  margin-bottom: 0.5em;
  font-weight: bold;
}
#privacy-modal #privacy::-webkit-scrollbar {
  width: 8px;
}
#privacy-modal #privacy::-webkit-scrollbar-track {
  background: #dddddd;
  border-radius: 20px;
}
#privacy-modal #privacy::-webkit-scrollbar-thumb {
  background-color: #003b71;
  border-radius: 20px;
}
.privacy-body {
    position: relative;
}
.privacy-body button.close {
    position: absolute;
    top: -25px!important;
}
.privacy-content {
    margin: 0px 10px;
}
.privacy-body .subtitle {
    text-transform: uppercase;
}

.privacy-content ol .title-cont {
    font-weight: bold;
}
.privacy-content ol li {
    margin-bottom: 15px;
}
.bold {
    font-weight: bold;
}
.button-area {
    margin-bottom: -40px;
}
.link-to {
    font-weight: 700;
    font-size: 14px;
    color: rgb(255, 71, 19);
    margin-left: 0px;
    cursor: pointer;
}
@media screen and (max-width: 480px) {
    .privacy-body button.close {
        top: -8px!important;
    }
}
</style>