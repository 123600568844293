<template>
<div class="questionPolicy">
    <div class="title">
        <h1>{{ questions }}</h1>
        <div class="tooltip-title" v-if="showTooltip || textTooltip.length > 0">
            <Tooltip :text="textTooltip"/>
        </div>
    </div>

    <div class="question-image" v-if="showImage || questionImage.length > 0">
        <img :src="questionImage" />
    </div>

    <div class="email-form" v-if="emailForm">
        <input 
            type="email" 
            class="form-control dob2extra"
            placeholder="sample@email.com"
            v-model="emailValue"
        />
        <div class="ad-info emai-newsletter-sub" v-if="isShowNewsLetter">
            <div class="newsletter-checkbox" :class="{clicked: newsletterSubscribe}" @click="subscribeNewsletter"></div>
            {{ $t('motorVehicle.emailNewsletter') }}
        </div>
    </div>

    <div class="email-form" v-if="phoneForm">
        <input 
            type="tel" 
            class="form-control dob2extra"
            placeholder="0893334444"
            v-model="formValue"
            :maxlength="lengthValue"
        />
        <div class="ad-info" v-if="!isMainDriver">
            {{ $t('motorVehicle.phoneNumberValidText') }}
        </div>
    </div>

    <div class="date-form col-xs-12" v-if="datePolis">
        <div class="row mt-4 mb-5">
            <div class="col col-3">
                <input 
                    type="text" 
                    maxlength="2" 
                    class="form-control dob2extra"
                    placeholder="01"
                    v-model="startPolis.day"
                    name="day"
                    data-next="month"
                    @blur="handleBlurDate"
                    @input="autoFocusOnMaxLength"
                />
                <div class="mt-2 text-center">DD</div>
            </div>
            <div class="col col-3">
                <input 
                    type="text" 
                    maxlength="2" 
                    class="form-control dob2extra monthFrm" 
                    placeholder="09" 
                    v-model="startPolis.month"
                    name="month"
                    data-next="year"
                    @blur="handleBlurMonth"
                    @input="autoFocusOnMaxLength"
                />
                <div class="mt-2 text-center">MM</div>
            </div>
            <div class="col col-6 label">
                <input 
                    type="text" 
                    maxlength="4" 
                    class="form-control dob2extra"
                    placeholder="2023"
                    name="year"
                    v-model="startPolis.year"
                />
                <div class="mt-2 text-center">YYYY</div>
            </div>
        </div>
    </div>

    <div class="form-general" v-if="formControl">
        <input 
            :type="formType" 
            class="form-control dob2extra"
            :placeholder="formPlaceholder"
            v-model="formValue"
            :maxlength="lengthValue"
        />
    </div>

    <div class="form-general" v-if="multiForm">
        <input 
            v-for="(formData, index) in multiFormData"
            :key="index"
            :type="formType" 
            class="form-control dob2extra"
            :placeholder="formData.placeholder"
            v-model="multiFormValue[index]"
        />
    </div>

    <div class="form-general" v-if="slotType">
        <slot />
    </div>


    <div class="row row-btn" v-if="answers.length > 0" :class="{rowHeight:  questionImage === ''}">
        <div class="col-12" v-for="(answer, index) in answers" :key="index">
            <button 
                class="btn btn-answer btn-block" 
                :class="{btnActive: selected === answer.value || globalValue === answer.value}"
                @click="setAnswer(answer.value)"
            >
                {{ answer.text }}
            </button>
        </div>
    </div>
    <div class="button-area" :class="{rowHeight:  questionImage === ''}" v-else-if="this.checkCountry !== null && this.passportValidate && this.btnCountry ">
        <button 
            class="btn btn-lg next2btn"
            v-show="!noNext"
            @click="nextTo();this.btnCountry = false"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
    <div class="button-area" :class="{rowHeight:  questionImage === ''}" v-else>
        <button 
            class="btn btn-lg next2btn"
            v-show="!noNext"
            :disabled="isButtonDisabled"
            @click="nextTo"
            v-if="isButtonShow"
        >
            {{ $t('next') }} &#8595;
        </button>
    </div>
</div>
</template>
<script>
import Tooltip from './Tooltip.vue';
import { filterNumberOnly } from "../lib"
import Mixin from "@/components/Mixin";
import { policyObject } from "../data/PolicyObject";
// import Global from "@/store/global.js";
import {
    getPolicyServiceInputObj
} from "../lib/IGNLibrary/IGNPricingEngine.js";

export default {
    name: "QuestionPolicyDetails",
    mixins: [Mixin], 
    props: {
        showTooltip: {
            type: Boolean,
            default: false
        },
        textTooltip: {
            type: String,
            default: ""
        },
        showImage: {
            type: Boolean,
            default: false
        },
        questionImage: {
            type: String,
            default: ""
        },
        questions: {
            type: String,
            default: ""
        },
        answers: {
            type: Array,
            default: () => []
        },
        emailForm: {
            type: Boolean,
            default: false
        },
        datePolis: {
            type: Boolean,
            default: false
        },
        phoneForm: {
            type: Boolean,
            default: false
        },
        formControl: {
            type: Boolean,
            default: false
        },
        formPlaceholder: {
            type: String,
            default: ""
        },
        formType: {
            type: String,
            default: ""
        },
        isNumberOnly: {
            type: Boolean,
            default: false
        },
        minLength: {
            type: Number
        },
        lengthValue: {
            type: Number
        },
        multiForm: {
            type: Boolean,
            default: false
        },
        multiFormData: {
            type: Array,
            default: () => []
        },
        noNext: {
            type: Boolean,
            default: false
        },
        slotType: {
            type: Boolean,
            default: false
        },
        phoneValidate: {
            type: Boolean,
            default: false
        },
        validateChar: {
            type: Boolean,
            default: false
        },
        passportValidate: {
            type: Boolean,
            default: false
        },
        globalValue: {
            type: String,
            default: null
        },
        checkCountry:{
            type: String,
            default: null
        },
        globalValueArray: {
            default: Array,
            default: () => []
        },
        carPlateValidate: {
            type: Boolean,
            default: false
        },
        forceDisable: {
            type: Boolean,
            default: false
        },
        newsletterSubscribeValue:{
            type: Boolean,
            default: false
        },
        isMainDriver: {
            type: Boolean,
            default: false
        },
        isShowNewsLetter: {
            type: Boolean,
            default: true
        },
        chassisNumber: {
            type: Boolean,
            default: false
        },
        validateHousenumber:{
            type: Boolean,
            default: false
        },
        validateSoi:{
            type: Boolean,
            default: false
        }
    },
    watch: {
        formValue: function(value) {
            // validation for phone number
            if (this.phoneForm && this.isNumberOnly && this.phoneValidate) {
                this.formValue = filterNumberOnly(value);
                const regxIndoPhone = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{2,3}$/g;
                if(value.match(regxIndoPhone)) {
                    this.isButtonShow = true;
                    this.isButtonDisabled = false;
                } else {
                    this.isButtonDisabled = true;
                }
            }
            
            if (this.isNumberOnly && !this.phoneValidate && !this.phoneForm ) {
                this.formValue = filterNumberOnly(value);
                if(value.length >= this.minLength) {
                    this.isButtonDisabled = false;
                    this.isButtonShow = true;
                } else {
                    this.isButtonDisabled = true;
                }
            }

            

            if(this.validateChar && this.passportValidate){
                this.formValue = value.toUpperCase();
                if(value.length >= this.minLength) {
                    this.btnCountry = true;
                    this.isButtonShow = true;
                    // this.isButtonDisabled = false;
                } else {
                    this.btnCountry = false;
                    this.isButtonDisabled = true;
                }
            }
            
            // if (this.validateChar && this.minLength) {
            //     if(value.length >= this.minLength) {
            //         this.isButtonShow = true;
            //         this.isButtonDisabled = false;
            //     } else {
            //         this.isButtonDisabled = true;
            //     }
            // }

            // car plate validation
            if (this.validateChar && this.minLength && this.carPlateValidate) {
                const rgx = /^[A-Z]{1,2}\s{0}\d{1,4}\s{0}[A-Z]{1,3}$/gm; 
                this.formValue = value.toUpperCase();
                if(!this.globalValue) {
                    if((value.length >= this.minLength) && value.match(rgx)) {
                        this.isButtonShow = true;
                        this.isButtonDisabled = false;
                    } else {
                        this.isButtonDisabled = true;
                    }
                } else {
                    if((this.formValue.length >= this.minLength) && this.formValue.match(rgx)) {
                        this.isButtonShow = true;
                        this.isButtonDisabled = false;
                    } else {
                        this.isButtonShow = true;
                        this.isButtonDisabled = true;
                    }
                }
            }
            

            if (this.minLength && this.validateSoi) {
                const regex = /^[a-zA-Z0-9].{5,50}$/gm;
                if((value.length >= this.minLength) && value.match(regex)) {
                    this.isButtonShow = true;
                    this.isButtonDisabled = false;
                } else {
                    this.isButtonDisabled = true;
                }
            }

            if (this.minLength && this.validateHousenumber) {
                // const regex = /^[a-zA-Z0-9]$/gm;
                if(value.length >= this.minLength) {
                    this.isButtonShow = true;
                    this.isButtonDisabled = false;
                } else {
                    this.isButtonDisabled = true;
                }
            }

            if (this.validateChar && this.minLength && this.chassisNumber) {
                const regex = /^[A-Z0-9]{17}$/gm;
                this.formValue = value.toUpperCase();
                if(!this.globalValue) {
                    if(value.match(regex)) {
                        this.isButtonShow = true;
                        this.isButtonDisabled = false;
                    } else {
                        this.isButtonDisabled = true;
                    }
                } else {
                    if(this.formValue.match(regex)) {
                        this.isButtonShow = true;
                        this.isButtonDisabled = false;
                    } else {
                        this.isButtonShow = true;
                        this.isButtonDisabled = true;
                    }
                }
            }
        },
        multiFormValue: {
            deep: true,
            handler: function(value) {
                const checkValue = [];
                value.forEach((arrValue) => {
                    if (arrValue && arrValue.length > 0) {
                        checkValue.push(arrValue);
                    }
                });
                const isFullnameTrue = this.checkFullname(checkValue);
                if (this.multiFormData.length == checkValue.length && isFullnameTrue) {
                    this.isButtonDisabled = false;
                    this.isButtonShow = true;
                } else {
                    this.isButtonDisabled = true;
                }
            }
        },
        emailValue: function(value) {
            const regxEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            const correctEmail = value.match(regxEmail);
            if(correctEmail !== null) {
                this.formValue = value;
                this.isButtonDisabled = false;
                this.isButtonShow = true;
            } else {
                this.isButtonDisabled = true;
            }
        },
        startPolis: {
            deep: true,
            handler: function(value) {
                const dob = String(value.year + '/' + value.month + '/' + value.day);
                let policy = new Date(dob).getTime();
                let now = new Date().getTime();
                let date = policy - now
                let days = date / (1000 * 3600 * 24);
                let thirtyDays = days >= -1 && days <= 30;
                this.startPolis.day = filterNumberOnly(value.day);
                this.startPolis.month = filterNumberOnly(value.month);
                this.startPolis.year = filterNumberOnly(value.year);
                if (this.startPolis.day.length == 2 && this.startPolis.month.length == 2 && this.startPolis.year.length == 4 ) {
                    this.isButtonDisabled = false;
                    this.isButtonShow = true;
                } else {
                    this.isButtonDisabled = true;
                }
            }
        }
    },
    data() {
        return {
            policyObject,
            selected: null,
            isButtonDisabled: true,
            formValue: null,
            multiFormValue: [],
            newsletterSubscribe: false,
            emailValue: null,
            isButtonShow: true,
            btnCountry: false,
            startPolis: {
                day: '',
                month: '',
                year: ''
            },
        }
    },
    components: { 
        Tooltip 
    },
    mounted() {
        if(this.globalValue !== null) {
            this.emailValue = this.globalValue;
            this.formValue = this.globalValue;
            this.isButtonShow = true;
            this.isButtonDisabled= false;
        }
        if(this.globalValueArray) {
            this.multiFormValue = this.globalValueArray;
        }

        setTimeout(()=> {
            //console.log(this.globalValue,this.forceDisable,this.emailValue);
            if (this.forceDisable && (this.globalValue != "" && this.globalValue != null) && (this.emailValue != "" && this.emailValue != null) ) {
                this.isButtonDisabled = false;
            }else if (this.forceDisable && (this.globalValue != "" && this.globalValue != null) && this.emailValue == null) {
                this.isButtonDisabled = false;
            }else if(!this.forceDisable  && (this.globalValue != "" && this.globalValue != null) ){
                this.isButtonDisabled = false;
            }else{
                this.isButtonDisabled = true;
            }
        },500);
        

    },
    methods: {
        setAnswer(value) {
            this.selected = value;
            this.$emit("action", this.selected);
        },
        checkFullname(arr) { 
            if(arr.every(element => element.length > 2)){
                const fullname = arr[0] + ' ' + arr[1];
                return this.validate(fullname).isAlphabet()
            }
        },
        autoFocusOnMaxLength(event) {
            const input = event.target;
            const maxLength = input.getAttribute("maxlength");
            const inputValue = input.value;
            if (inputValue.length >= maxLength) {
                const nextInput = input.getAttribute("data-next");
                if (nextInput) {
                    const next = document.querySelector(`input[name=${nextInput}]`);
                    if (next) {
                        next.focus();
                    }
                }
            }
        },
        openDisabledButton() {
            this.isButtonDisabled = false;
        },
        DisableButton() {
            this.isButtonDisabled = true;
        },
        subscribeNewsletter() {
            this.newsletterSubscribe = !this.newsletterSubscribe;
            policyObject.root.quoteData.policyHolderData.PersonHasOptedOutOfEmail = this.newsletterSubscribe;
            // if (this.isMainDriver) {
            //     Global.motorVehicle.mainDrivers.PersonHasOptedOutOfEmail = this.newsletterSubscribe
            // }else{
            //     Global.motorVehicle.phaccount.PersonHasOptedOutOfEmail = this.newsletterSubscribe
            // }
        },
        nextTo() {
            if (this.multiForm) {
                const obj = {}; //Create object 
                this.multiFormData.forEach((element, index) => {
                    obj[element.name] = this.multiFormValue[index];
                });
                this.$emit("action", obj);
            }else if(this.datePolis){
                const date = String(this.startPolis.year + '-' + this.startPolis.month + '-' + this.startPolis.day);
                this.$emit('next', date);
            } else {
                this.$emit("action", this.formValue);
            }
            this.isButtonShow = false
            this.isButtonDisabled = true
        },
        handleBlurDate() {
            if (this.startPolis.day.length == 1 && Number(this.startPolis.day) !== 0) {
                this.startPolis.day = "0"+this.startPolis.day;
            }
        },
        handleBlurMonth() {
            if (this.startPolis.month.length == 1 && Number(this.startPolis.month) !== 0) {
                this.startPolis.month = "0"+this.startPolis.month;
            }
        },
    },
    computed: {
        isGlobalValueHasValue() {
            return this.globalValue !== null || this.globalValue !== "";
        }
    }

}
</script>
<style scoped>
.questionPolicy .title {
    display: flex;
    position: relative;
}
.questionPolicy .title h1{
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.questionPolicy .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.questionPolicy .question-image {
    text-align: center;
    margin: 24px;
}
.row-btn {
    margin-top: 50px;
}
.btn-answer {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 15px 21px;
    color: #003B71;
    font-size: 20px;
}
.btnActive {
    background: #0E5DA7;
    color: #ffffff;
}
.row-btn button:first-child {
    margin-bottom: 15px;
}
.questionPolicy .button-area {
    text-align: center;
}
.next2btn {
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 21px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 18px;
}
.next2btn:disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}

.email-form {
    margin: 0 auto;
}
.ad-info {
    font-size: 12px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
    margin-top: 24px;
}

.emai-newsletter-sub {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ad-info .newsletter-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
}
.ad-info .newsletter-checkbox.clicked::before {
    background-color: #fff;
    content: "\E207";
    display: inline-block;
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Regular';
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    color: #ff5011;
    cursor: pointer;
}
.date-form {
    margin: 0 auto;
    max-width: 360px;
}
.date-form .col {
    padding-right: 5px!important;
    padding-left: 5px!important;
}
.dob2extra {
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    color: #003B71;
    font-weight: 700;
    border-radius: 8px;
    margin-bottom: 20px;
}
.dob2extra::-webkit-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::-ms-input-placeholder { 
    font-size: 20px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.dob2extra::placeholder {
    font-size: 18px;
    font-weight: 500;
    padding: 25px 10px;
    text-align: center;
    font-weight: 700;
    color: #C1B6AF;
}
.form-general {
    margin: 24px 0px;
}
@media screen and (max-width: 480px) {
    .dob2extra::-webkit-input-placeholder { 
        font-size: 12px;
    }
    .dob2extra::-ms-input-placeholder { 
        font-size: 12px;
    }
    .dob2extra::placeholder {
        font-size: 12px;
    }
    .row-btn button:first-child {
        margin-bottom: 15px;
    }
    .ad-info .newsletter-checkbox {
        margin-top: -10px!important;
    }
    .dob2extra::placeholder {
        font-size: 15px!important;
    }
    .rowHeight {
        margin-top: 25px!important;
    }
}

@media screen and (max-width: 360px) {
    .questionPolicy .title h1 {
        font-size: 16px!important;
    }
    .btn-answer {
        padding: 10px 21px!important;
        font-size: 16px!important;
    }
    .next2btn {
        padding: 10px 21px!important;
        font-size: 16px!important;
    }
    .question-image img {
        height: 95px;
    }
    .questionPolicy .question-image {
        margin: 10px;
    }
    .dob2extra::placeholder {
        font-size: 15px!important;
    }
    .rowHeight {
        margin-top: 25px!important;
    }
}


</style>