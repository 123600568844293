<template>
    <div class="mv-policydetails">
        <HeaderPD2 />
        <div class="section policyDetails">
            <div class="container">
                <div 
                    class="nextToPayment my-3"
                    id="payment-button"
                >
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import Global from "@/store/global.js";
    import Mixin from "@/components/Mixin";
    import HeaderPD2 from './components/Headerthankyou.vue';
    
    export default {
        name: "PolicyDetails",
        data() {
            return {
                Global,
            }
        },
        components: {
            HeaderPD2
        },
        mixins: [Mixin],
        created(){
            window.addEventListener('scroll', this.onScrollNavbar);
            window.scrollTo(0, 0);
        },
        mounted() {
            this.loading("hide")
            // this.fetchOccupations();
            // window.scrollTo(0, 0);
            // Global.motorVehicle.radarCallType = "2";
            // clientRegInfo = getRegisterIGNSdk();
        },
    }
    </script>
    
    <style scoped>
    .section {
        max-width: 604px;
        width: 100%;
        padding: 0 15px;
        margin: auto;
    }
    .questions-section {
        padding: 70px 0px;
    }
    .nextToPayment {
        padding: 30px 0px;
        text-align: center;
    }
    .next2btn {
        color: #fff;
        border-radius: 5px;
        background-image: none;
        background-color: #ff5011;
        padding: 15px 21px;
        margin: 6px auto;
        box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
        font-size: 16px;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
    .next2btn:disabled {
        border: 1px solid #ccc!important;
        color: #ccc!important;
        background-color: #fff!important;
        box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
        cursor: not-allowed;
    }
    .tnq {
        display: inline-flex;
        margin-bottom: 29px;
    }
    .tnq span {
        font-weight: 700;
        font-size: 14px;
        color: #FF4713;
        margin-left: 8px;
        cursor: pointer;
    }
    .tnq-checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid #666;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
    }
    .tnq-checkbox.clicked::before {
        background-color: #fff;
        content: "\E207";
        display: inline-block;
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Regular';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        vertical-align: top;
        -webkit-font-smoothing: antialiased;
        color: #ff5011;
        cursor: pointer;
    }
    
    @media screen and (max-width: 480px) {
        .tnq {
            display: grid;
        }
        .tnq-checkbox {
            position: absolute;
        }
    }
    </style>