<template>
    <div class="mv-policydetails">
        <HeaderPD2 @currentScrollPosition="getCurrentScrollPOsition"/>
        <div class="section policyDetails">
            <div class="container">
                <div 
                    class="questions-section" 
                    id="detail-under-financing"
                >
                    <QuestionPolicyDetails 
                        :questions="underfinancingQuestion"
                        questionImage="images/motor-vehicle/underfinancing.svg" 
                        :answers="UnderFinancingOptions"
                        @action="UnderFinancingAction"
                        :globalValue="policyObject.root.quoteData.vehicleData.flagCarFinancing"
                    />
                </div>

                <div 
                    class="questions-section" 
                    id="detail-company-financing"
                    v-show="stepCompleted('detail-under-financing') && isUnderFinancing"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.companyUnderFinancing')"
                        slot-type
                        ref="financing"
                        no-next
                    >
                        <ButtonSelect 
                            :label="labelCompanyFinancing"
                            :listsData="listFinancings"
                            :language="policyObject.root.quoteData.prefLang"
                            type="financing"
                            @selected="selectFinancing"    
                        />
                    </QuestionPolicyDetails>
                </div>

                <div 
                    class="questions-section" 
                    id="detail-chassis"
                    v-show="(stepCompleted('detail-under-financing') && !isUnderFinancing) || (stepCompleted('detail-company-financing') && isUnderFinancing)"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.questionchassisNumber')"
                        questionImage="images/motor-vehicle/chassisnumber.svg"
                        form-control
                        :formPlaceholder="$t('motorVehicle.chassisNumberPlaceholder')"
                        formType="text"
                        chassis-number
                        validate-char
                        :lengthValue="17"
                        :minLength="17"
                        @action="chassisNumberAction"
                        :globalValue="policyObject.root.quoteData.vehicleData.chassisNumber"
                    />
                </div>

                <div 
                    class="questions-section" 
                    id="detail-insurance"
                    v-show="stepCompleted('detail-chassis')"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.questionUnderInsurance')"
                        questionImage="images/motor-vehicle/ClaimQuestion.svg" 
                        :answers="insuranceOptions"
                        @action="insuranceAction"
                        :globalValue="policyObject.root.quoteData.previousInsurer"
                    />
                </div>
                <div 
                    class="questions-section" 
                    id="detail-company-insurance"
                    v-show="stepCompleted('detail-insurance') && isInsurance"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.questionCompanyInsurance')"
                        slot-type
                        ref="insurer"
                        no-next
                    >
                        <ButtonSelect 
                            :label="labelCompanyInsurer"
                            :listsData="Global.insurers"
                            :language="policyObject.root.quoteData.prefLang"
                            type="insurer"
                            @selected="selectInsurer"    
                        />
                    </QuestionPolicyDetails>
                </div>
                <div 
                    class="questions-section" 
                    id="detail-type-insurance"
                    v-show="stepCompleted('detail-company-insurance') && isInsurance"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.questionTypeInsurance')"
                        slot-type
                        ref="typeInsurer"
                        no-next
                    >
                        <ButtonSelect 
                            :label="labelCoverType"
                            :listsData="Global.coverTypes"
                            :language="policyObject.root.quoteData.prefLang"
                            :activeSelected="policyObject.root.quoteData.previousCoverType"
                            type="occupations"
                            @selected="selectTypeInsurer"    
                        />
                    </QuestionPolicyDetails>
                </div>

                <div 
                    class="questions-section col-xs-12"
                    id="detail-expire-insurance"
                    v-show="stepCompleted('detail-type-insurance') && isInsurance"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.questionExpireInsurance')"
                        date-polis
                        class="mt-5"
                        @next="expireInsurance"
                    />
                </div>

                <div 
                    class="questions-section" 
                    id="detail-address"
                    v-show="(stepCompleted('detail-insurance') && !isInsurance) || (stepCompleted('detail-expire-insurance') && isInsurance)"
                >
                    <h4 class="text-center mb-5">{{ $t('motorVehicle.completeAddress') }}</h4>

                        <QuestionPolicyDetails 
                            :questions="$t('motorVehicle.zipcode')"
                            form-control
                            formType="tel"
                            slot-type
                            :formPlaceholder="$t('motorVehicle.zipcode')"
                            is-number-only
                            :lengthValue="5"
                            :minLength="5"
                            @action="zipcodeAction"
                            class="mt-5"
                            :globalValue="policyObject.root.quoteData.postalCode"
                        >
                            <div class="orange-text text-center" v-if="!this.checkPostcode && this.postalCode.length == 5">
                                <span class="glyphicons glyphicons-validate glyphicons-warning-sign animated swing"></span> {{$t("pleaseInsertAZipCode")}}
                            </div>
                        </QuestionPolicyDetails>
                </div>

                <div 
                    class="questions-section" 
                    id="detail-province"
                    v-show="stepCompleted('detail-address')"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.province')"
                        no-next
                        slot-type
                        ref="province"
                    >
                        <ButtonSelect 
                            :label="labelProvince"
                            :listsData="provinces"
                            :language="policyObject.root.quoteData.prefLang"
                            type="postal"
                            @selected="selectProvince"    
                        />
                    </QuestionPolicyDetails>
                </div>
                <div 
                    class="questions-section" 
                    id="detail-district"
                    v-show="stepCompleted('detail-province')"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.district')"
                        no-next
                        slot-type
                        ref="district"
                    >
                        <ButtonSelect 
                            :label="labelDistrict"
                            :listsData="districts"
                            :language="policyObject.root.quoteData.prefLang"
                            type="postal"
                            @selected="selectDistrict"    
                        />
                    </QuestionPolicyDetails>
                </div>

                
                <div 
                    class="questions-section" 
                    id="detail-subdistrict"
                    v-show="stepCompleted('detail-district')"
                >
                    <QuestionPolicyDetails 
                        :questions="$t('motorVehicle.subdistrict')"
                        no-next
                        slot-type
                        ref="subdistrict"
                    >
                        <ButtonSelect 
                            :label="labelsubDistrict"
                            :listsData="subDistricts"
                            :language="policyObject.root.quoteData.prefLang"
                            type="postal"
                            @selected="selectSubdistrict"    
                        />
                    </QuestionPolicyDetails>
                </div>


                    <div
                        class="questions-section"
                        id="detail-road-name"
                        v-show="stepCompleted('detail-subdistrict')"
                    >
                        <QuestionPolicyDetails
                            :questions="$t('motorVehicle.roadName')"
                            form-control
                            formType="text"
                            validate-soi
                            force-disable
                            :minLength="5"
                            :lengthValue="50"
                            :formPlaceholder="$t('motorVehicle.roadNamePlaceholder')"
                            @action="roadNameAction"
                            class="mt-5"
                            :globalValue="policyObject.root.quoteData.policyHolderData.contactAddresses[0].soi"
                        />
                    </div>
                    <div
                        class="questions-section mb-5"
                        id="detail-house-number"
                        v-show="stepCompleted('detail-road-name')"
                    >
                        <QuestionPolicyDetails 
                            :questions="$t('motorVehicle.houseNumber')"
                            form-control
                            formType="text"
                            validate-housenumber
                            force-disable
                            :minLength="1"
                            :lengthValue="5"
                            :formPlaceholder="$t('motorVehicle.houseNumberPlaceholder')"
                            @action="houseNumberAction"
                            class="mt-5"
                            :globalValue="policyObject.root.quoteData.policyHolderData.contactAddresses[0].houseNumber"
                        />
                    </div>
    
                <div 
                    class="questions-section nextToPayment my-5"
                    id="submit-button"
                    v-show="stepCompleted('detail-house-number')"
                >
                    <button 
                        class="btn btn-block next2btn" 
                        @click="submitPD2"
                    >
                        {{ $t('motorVehicle.submit') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import QuestionPolicyDetails from './components/QuestionPolicyDetails.vue';
    import ButtonSelect from './components/ButtonSelect.vue';
    import PostalCode from "./components/PostalCode.vue";
    import HeaderPD2 from './components/HeaderPd2.vue';

    
    import Global from "@/store/global.js";
    import Mixin from "@/components/Mixin";


    
    import { policyObject } from "./data/PolicyObject";
    import {createInputPricingObj, policyServiceMappingObj} from "./data/util.js"
    import { formattedDate, ymdFormatedDate, setInterceptorObj,getRegisterIGNSdk,dmyFormatedDate,
        dayMonthYearFormatedDate } from "./lib/index.js";

    
    import {
        setClientRegInfo,
        calculatePlanOptionsPrice,
        saveQuote,
        setPolicyServiceInputObj,
        calculateSelectedPlanPrice,
        getPolicyPaymentOptionPrice,
        // setPolicyServiceMappingFromInputPriceResponse,
        setInputPricing
    } from "./lib/IGNLibrary/IGNPricingEngine.js";
    
    let clientRegInfo;
    
    export default {
        name: "PolicyDetails",
        beforeRouteLeave (to, from, next) {
            if(to.name == 'mv-thankyou2') next()
            if(window.pageYOffset > 0) {
                this.scrollToTopPage()
                next(false)
            } else next()
        },
        data() {
            return {
                Global,
                policyObject,
                tnc: false,
                showCarPlate: false,
                showHeader: true,
                isCalculatePlanOptionsPrice: true,
                underfinancing : "",
                insurance : "",
                postalCode: "",
                checkPostcode: null,
                paymentOptionPrice : null,
                provinces: "",
                districts: "",
                subDistricts: "",
                typeInsurers: ['Comprehensive','Total Lost'],
                listFinancings : [
                    {
                    "ID": "Aditama Finance",
                    "EN": "Aditama Finance"
                    
                    },
                    {
                    "ID": "AEON Credit Services Indonesia",
                    "EN": "AEON Credit Services Indonesia"
                    
                    },
                    {
                    "ID": "Al Ijarah Indonesia Finance",
                    "EN": "Al Ijarah Indonesia Finance"
                    
                    },
                    {
                    "ID": "Amanah Ventura Syariah",
                    "EN": "Amanah Ventura Syariah"
                    
                    },
                    {
                    "ID": "Anadana Global Multifinance",
                    "EN": "Citra Tirta Mulia"
                    
                    },
                    {
                    "ID": "Arthaasia Finance",
                    "EN": "Permodalan BMT Ventura"
                    
                    },
                    {
                    "ID": "Asia Multidana",
                    "EN": "Permodalan Nasional Madani Ventura Syariah"
                    
                    },
                    {
                    "ID": "ASIA PACIFIC VENTURA",
                    "EN": "Persada Ventura Syariah"
                    
                    },
                    {
                    "ID": "Astra Auto Finance",
                    "EN": "Astra Auto Finance"
                    
                    },
                    {
                    "ID": "Astra Mitra Ventura",
                    "EN": "Astra Mitra Ventura"
                    
                    },
                    {
                    "ID": "Astra Multi Finance",
                    "EN": "Astra Multi Finance"
                    
                    },
                    {
                    "ID": "Bahana Artha Ventura",
                    "EN": "Bahana Artha Ventura"
                    
                    },
                    {
                    "ID": "BCA Finance",
                    "EN": "BCA Finance"
                    
                    },
                    {
                    "ID": "BCA Multi Finance",
                    "EN": "BCA Multi Finance"
                    
                    },
                    {
                    "ID": "Bhumindo Sentosa Abadi Finance",
                    "EN": "Bhumindo Sentosa Abadi Finance"
                    
                    },
                    {
                    "ID": "Bima Multi Finance",
                    "EN": "Bima Multi Finance"
                    
                    },
                    {
                    "ID": "Bina Artha Ventura",
                    "EN": "Bina Artha Ventura"
                    
                    },
                    {
                    "ID": "Bintang Mandiri Finance",
                    "EN": "Bintang Mandiri Finance"
                    
                    },
                    {
                    "ID": "BNI Multifinance",
                    "EN": "BNI Multifinance"
                    
                    },
                    {
                    "ID": "Bosowa Multi Finance",
                    "EN": "Bosowa Multi Finance"
                    
                    },
                    {
                    "ID": "BRI Ventura Investama",
                    "EN": "BRI Ventura Investama"
                    
                    },
                    {
                    "ID": "Buana Finance",
                    "EN": "Buana Finance"
                    
                    },
                    {
                    "ID": "Bumiputera BOT- Finance",
                    "EN": "Bumiputera BOT- Finance"
                    
                    },
                    {
                    "ID": "Bussan Auto Finance",
                    "EN": "Bussan Auto Finance"
                    },
                    {
                    "ID": "Cakrawala Citra Mega Multifinance",
                    "EN": "Cakrawala Citra Mega Multifinance"
                    },
                    {
                    "ID": "Caturnusa Sejahtera Finance",
                    "EN": "Caturnusa Sejahtera Finance"
                    },
                    {
                    "ID": "Celebes  Artha Ventura",
                    "EN": "Celebes  Artha Ventura"
                    },
                    {
                    "ID": "Ciptadana Multifinance",
                    "EN": "Ciptadana Multifinance"
                    },
                    {
                    "ID": "Citra Tirta Mulia",
                    "EN": "Citra Tirta Mulia"
                    },
                    {
                    "ID": "Clemont Finance Indonesia",
                    "EN": "Clemont Finance Indonesia"
                    },
                    {
                    "ID": "Clipan Finance Indonesia",
                    "EN": "Clipan Finance Indonesia"
                    },
                    {
                    "ID": "Dana Mandiri Sejahtera (D.h. Dana Mandiri Parasahabat)",
                    "EN": "Dana Mandiri Sejahtera (D.h. Dana Mandiri Parasahabat)"
                    },
                    {
                    "ID": "Dana Unico Finance",
                    "EN": "Dana Unico Finance"
                    },
                    {
                    "ID": "Danareksa Finance",
                    "EN": "Danareksa Finance"
                    },
                    {
                    "ID": "Emas Persada Finance",
                    "EN": "Emas Persada Finance"
                    },
                    {
                    "ID": "Equity Finance Indonesia",
                    "EN": "Equity Finance Indonesia"
                    },
                    {
                    "ID": "FinAccel Finance Indonesia",
                    "EN": "FinAccel Finance Indonesia"
                    },
                    {
                    "ID": "Fortuna Multi Finance",
                    "EN": "Fortuna Multi Finance"
                    },
                    {
                    "ID": "Globalindo Multi Finance",
                    "EN": "Globalindo Multi Finance"
                    },
                    {
                    "ID": "Hexa Finance Indonesia",
                    "EN": "Hexa Finance Indonesia"
                    },
                    {
                    "ID": "Hino Finance Indonesia",
                    "EN": "Hino Finance Indonesia"
                    },
                    {
                    "ID": "Indonesia International Finance",
                    "EN": "Indonesia International Finance"
                    },
                    {
                    "ID": "Internusa Tribuana Citra Multi Finance",
                    "EN": "Internusa Tribuana Citra Multi Finance"
                    },
                    {
                    "ID": "Ivaro Ventura",
                    "EN": "Ivaro Ventura"
                    },
                    {
                    "ID": "JACCS Mitra Pinasthika Mustika Finance Indonesia",
                    "EN": "JACCS Mitra Pinasthika Mustika Finance Indonesia"
                    },
                    {
                    "ID": "Karya Technik Multi Finance",
                    "EN": "Karya Technik Multi Finance"
                    },
                    {
                    "ID": "Koexim Mandiri Finance",
                    "EN": "Koexim Mandiri Finance"
                    },
                    {
                    "ID": "Komatsu Astra Finance",
                    "EN": "Komatsu Astra Finance"
                    },
                    {
                    "ID": "Maju Raya Sejahtera",
                    "EN": "Maju Raya Sejahtera"
                    },
                    {
                    "ID": "Mandala Multifinance",
                    "EN": "Mandala Multifinance"
                    },
                    {
                    "ID": "OCBC NISP Ventura",
                    "EN": "OCBC NISP Ventura"
                    },
                    {
                    "ID": "Permodalan BMT Ventura",
                    "EN": "Permodalan BMT Ventura"
                    },
                    {
                    "ID": "Permodalan Nasional Madani Ventura Syariah",
                    "EN": "Permodalan Nasional Madani Ventura Syariah"
                    },
                    {
                    "ID": "Persada Ventura Syariah",
                    "EN": "Persada Ventura Syariah"
                    },
                    {
                    "ID": "PNM Venture Capital",
                    "EN": "PNM Venture Capital"
                    },
                    {
                    "ID": "Pratama Interdana Finance",
                    "EN": "Pratama Interdana Finance"
                    },
                    {
                    "ID": "Proline Finance Indonesia",
                    "EN": "Proline Finance Indonesia"
                    },
                    {
                    "ID": "PT AB Sinar Mas Multifinance",
                    "EN": "PT AB Sinar Mas Multifinance"
                    },
                    {
                    "ID": "PT Adira Dinamika Multi Finance Tbk",
                    "EN": "PT Adira Dinamika Multi Finance Tbk"
                    },
                    {
                    "ID": "PT Akulaku Finance Indonesia",
                    "EN": "PT Akulaku Finance Indonesia"
                    },
                    {
                    "ID": "PT Anugerah Buana Central Multifinance",
                    "EN": "PT Anugerah Buana Central Multifinance"
                    },
                    {
                    "ID": "PT Armada Finance",
                    "EN": "PT Armada Finance"
                    },
                    {
                    "ID": "PT Artha Prima Finance",
                    "EN": "PT Artha Prima Finance"
                    },
                    {
                    "ID": "PT Asiatic Sejahtera Finance",
                    "EN": "PT Asiatic Sejahtera Finance"
                    },
                    {
                    "ID": "PT Astra Sedaya Finance",
                    "EN": "PT Astra Sedaya Finance"
                    },
                    {
                    "ID": "PT Astrido Pacific Finance",
                    "EN": "PT Astrido Pacific Finance"
                    },
                    {
                    "ID": "PT Atome Finance Indonesia",
                    "EN": "PT Atome Finance Indonesia"
                    },
                    {
                    "ID": "PT Batara Internasional Finansindo",
                    "EN": "PT Batara Internasional Finansindo"
                    },
                    {
                    "ID": "PT Bentara Sinergies Multifinance",
                    "EN": "PT Bentara Sinergies Multifinance"
                    },
                    {
                    "ID": "PT Beta Inti Multifinance",
                    "EN": "PT Beta Inti Multifinance"
                    },
                    {
                    "ID": "PT BFI Finance Indonesia, Tbk",
                    "EN": "PT BFI Finance Indonesia, Tbk"
                    },
                    {
                    "ID": "PT BRI Multifinance Indonesia",
                    "EN": "PT BRI Multifinance Indonesia"
                    },
                    {
                    "ID": "PT BTPN Syariah Ventura",
                    "EN": "PT BTPN Syariah Ventura"
                    },
                    {
                    "ID": "PT Cakrabuana Ventura Indonesia",
                    "EN": "PT Cakrabuana Ventura Indonesia"
                    },
                    {
                    "ID": "PT Capella Multidana",
                    "EN": "PT Capella Multidana"
                    },
                    {
                    "ID": "PT Capital Global Ventura",
                    "EN": "PT Capital Global Ventura"
                    },
                    {
                    "ID": "PT Caterpillar Finance Indonesia",
                    "EN": "PT Caterpillar Finance Indonesia"
                    },
                    {
                    "ID": "PT Central Capital Ventura",
                    "EN": "PT Central Capital Ventura"
                    },
                    {
                    "ID": "PT Central Java Power",
                    "EN": "PT Central Java Power"
                    },
                    {
                    "ID": "PT Century Tokyo Leasing Indonesia",
                    "EN": "PT Century Tokyo Leasing Indonesia"
                    },
                    {
                    "ID": "PT Chailease Finance Indonesia",
                    "EN": "PT Chailease Finance Indonesia"
                    },
                    {
                    "ID": "PT Chandra Sakti Utama Leasing",
                    "EN": "PT Chandra Sakti Utama Leasing"
                    },
                    {
                    "ID": "PT CIMB Niaga Auto Finance",
                    "EN": "PT CIMB Niaga Auto Finance"
                    },
                    {
                    "ID": "PT Commerce Finance",
                    "EN": "PT Commerce Finance"
                    },
                    {
                    "ID": "PT Corpus Prima Ventura",
                    "EN": "PT Corpus Prima Ventura"
                    },
                    {
                    "ID": "PT Daindo International Finance",
                    "EN": "PT Daindo International Finance"
                    },
                    {
                    "ID": "PT Dipo Star Finance",
                    "EN": "PT Dipo Star Finance"
                    },
                    {
                    "ID": "PT Emperor Finance Indonesia",
                    "EN": "PT Emperor Finance Indonesia"
                    },
                    {
                    "ID": "PT Esta Dana Ventura",
                    "EN": "PT Esta Dana Ventura"
                    },
                    {
                    "ID": "PT Federal International Finance",
                    "EN": "PT Federal International Finance"
                    },
                    {
                    "ID": "PT Fuji Finance Indonesia Tbk",
                    "EN": "PT Fuji Finance Indonesia Tbk"
                    },
                    {
                    "ID": "PT Gratama Finance Indonesia",
                    "EN": "PT Gratama Finance Indonesia"
                    },
                    {
                    "ID": "PT Hasjrat Multifinance",
                    "EN": "PT Hasjrat Multifinance"
                    },
                    {
                    "ID": "PT Hewlett Packard Finance Indonesia",
                    "EN": "PT Hewlett Packard Finance Indonesia"
                    },
                    {
                    "ID": "PT Home Credit Indonesia",
                    "EN": "PT Home Credit Indonesia"
                    },
                    {
                    "ID": "PT Honest Financial Technologies",
                    "EN": "PT Honest Financial Technologies"
                    },
                    {
                    "ID": "PT IFS Capital Indonesia",
                    "EN": "PT IFS Capital Indonesia"
                    },
                    {
                    "ID": "PT Indomobil Finance Indonesia",
                    "EN": "PT Indomobil Finance Indonesia"
                    },
                    {
                    "ID": "PT Indonesia Infrastructure Finance",
                    "EN": "PT Indonesia Infrastructure Finance"
                    },
                    {
                    "ID": "PT ITC Auto Multi Finance",
                    "EN": "PT ITC Auto Multi Finance"
                    },
                    {
                    "ID": "PT JTrust Olympindo Multi Finance",
                    "EN": "PT JTrust Olympindo Multi Finance"
                    },
                    {
                    "ID": "PT Karunia Multifinance",
                    "EN": "PT Karunia Multifinance"
                    },
                    {
                    "ID": "PT KB Bukopin Finance",
                    "EN": "PT KB Bukopin Finance"
                    },
                    {
                    "ID": "PT KB Finansia Multi Finance",
                    "EN": "PT KB Finansia Multi Finance"
                    },
                    {
                    "ID": "PT KDB Tifa Finance Tbk",
                    "EN": "PT KDB Tifa Finance Tbk"
                    },
                    {
                    "ID": "PT Kresna Reksa Finance",
                    "EN": "PT Kresna Reksa Finance"
                    },
                    {
                    "ID": "PT LOLC Ventura Indonesia",
                    "EN": "PT LOLC Ventura Indonesia"
                    },
                    {
                    "ID": "PT Lotte Capital Indonesia",
                    "EN": "PT Lotte Capital Indonesia"
                    },
                    {
                    "ID": "PT Mandiri Capital Indonesia",
                    "EN": "PT Mandiri Capital Indonesia"
                    },
                    {
                    "ID": "PT Mandiri Tunas Finance",
                    "EN": "PT Mandiri Tunas Finance"
                    },
                    {
                    "ID": "PT Mandiri Utama Finance",
                    "EN": "PT Mandiri Utama Finance"
                    },
                    {
                    "ID": "PT Maybank Indonesia Finance",
                    "EN": "PT Maybank Indonesia Finance"
                    },
                    {
                    "ID": "PT Mega Auto Finance",
                    "EN": "PT Mega Auto Finance"
                    },
                    {
                    "ID": "PT Mega Central Finance",
                    "EN": "PT Mega Central Finance"
                    },
                    {
                    "ID": "PT Mega Finance",
                    "EN": "PT Mega Finance"
                    },
                    {
                    "ID": "PT Mitra Adipratama Sejati Finance",
                    "EN": "PT Mitra Adipratama Sejati Finance"
                    },
                    {
                    "ID": "PT Mitra Bisnis Keluarga Ventura",
                    "EN": "PT Mitra Bisnis Keluarga Ventura"
                    },
                    {
                    "ID": "PT Mitra Dana Top Finance",
                    "EN": "PT Mitra Dana Top Finance"
                    },
                    {
                    "ID": "PT Mitsubishi HC Capital and Finance Indonesia",
                    "EN": "PT Mitsubishi HC Capital and Finance Indonesia"
                    },
                    {
                    "ID": "PT Mitsui Leasing Capital Indonesia",
                    "EN": "PT Mitsui Leasing Capital Indonesia"
                    },
                    {
                    "ID": "PT Mizuho Leasing Indonesia Tbk",
                    "EN": "PT Mizuho Leasing Indonesia Tbk"
                    },
                    {
                    "ID": "PT MNC Finance",
                    "EN": "PT MNC Finance"
                    },
                    {
                    "ID": "PT MNC Guna Usaha Indonesia",
                    "EN": "PT MNC Guna Usaha Indonesia"
                    },
                    {
                    "ID": "PT Modal Ventura YCAB",
                    "EN": "PT Modal Ventura YCAB"
                    },
                    {
                    "ID": "PT Modalku Finansial Indonesia",
                    "EN": "PT Modalku Finansial Indonesia"
                    },
                    {
                    "ID": "PT Multifinance Anak Bangsa",
                    "EN": "PT Multifinance Anak Bangsa"
                    },
                    {
                    "ID": "PT Multindo Auto Finance",
                    "EN": "PT Multindo Auto Finance"
                    },
                    {
                    "ID": "PT Mutiara Multi Finance",
                    "EN": "PT Mutiara Multi Finance"
                    },
                    {
                    "ID": "PT Nissan Financial Services Indonesia",
                    "EN": "PT Nissan Financial Services Indonesia"
                    },
                    {
                    "ID": "PT Nusa Surya Ciptadana",
                    "EN": "PT Nusa Surya Ciptadana"
                    },
                    {
                    "ID": "PT Orico Balimor Finance",
                    "EN": "PT Orico Balimor Finance"
                    },
                    {
                    "ID": "PT Orix Indonesia Finance",
                    "EN": "PT Orix Indonesia Finance"
                    },
                    {
                    "ID": "PT Oto Multiartha",
                    "EN": "PT Oto Multiartha"
                    },
                    {
                    "ID": "PT Pacific Multi Finance",
                    "EN": "PT Pacific Multi Finance"
                    },
                    {
                    "ID": "PT PANN Pembiayaan Maritim",
                    "EN": "PT PANN Pembiayaan Maritim"
                    },
                    {
                    "ID": "PT Paramitra Multifinance",
                    "EN": "PT Paramitra Multifinance"
                    },
                    {
                    "ID": "PT Permata Finance Indonesia",
                    "EN": "PT Permata Finance Indonesia"
                    },
                    {
                    "ID": "PT Pool Advista Finance, Tbk",
                    "EN": "PT Pool Advista Finance, Tbk"
                    },
                    {
                    "ID": "PT Porto Ventura Kapital",
                    "EN": "PT Porto Ventura Kapital"
                    },
                    {
                    "ID": "PT PPA Finance",
                    "EN": "PT PPA Finance"
                    },
                    {
                    "ID": "PT Pro Car International Finance",
                    "EN": "PT Pro Car International Finance"
                    },
                    {
                    "ID": "PT Rabana Investindo",
                    "EN": "PT Rabana Investindo"
                    },
                    {
                    "ID": "PT Radana Bhaskara Finance Tbk (d\/h PT HD Finance)",
                    "EN": "PT Radana Bhaskara Finance Tbk (d\/h PT HD Finance)"
                    },
                    {
                    "ID": "PT Reksa Finance",
                    "EN": "PT Reksa Finance"
                    },
                    {
                    "ID": "PT Reliance Modal Ventura",
                    "EN": "PT Reliance Modal Ventura"
                    },
                    {
                    "ID": "PT Resona Indonesia Finance",
                    "EN": "PT Resona Indonesia Finance"
                    },
                    {
                    "ID": "PT Rindang Sejahtera Finance",
                    "EN": "PT Rindang Sejahtera Finance"
                    },
                    {
                    "ID": "PT Sarana Global Finance Indonesia",
                    "EN": "PT Sarana Global Finance Indonesia"
                    },
                    {
                    "ID": "PT Sarana Lampung Ventura",
                    "EN": "PT Sarana Lampung Ventura"
                    },
                    {
                    "ID": "PT Sarana Majukan Ekonomi Finance Indonesia",
                    "EN": "PT Sarana Majukan Ekonomi Finance Indonesia"
                    },
                    {
                    "ID": "PT Sarana Multi Infrastruktur (Persero)",
                    "EN": "PT Sarana Multi Infrastruktur (Persero)"
                    },
                    {
                    "ID": "PT Sarana Sulteng Ventura",
                    "EN": "PT Sarana Sulteng Ventura"
                    },
                    {
                    "ID": "PT Sarana Yogya Ventura",
                    "EN": "PT Sarana Yogya Ventura"
                    },
                    {
                    "ID": "PT Satyadhika Bakti",
                    "EN": "PT Satyadhika Bakti"
                    },
                    {
                    "ID": "PT Sembrani Finance Indonesia",
                    "EN": "PT Sembrani Finance Indonesia"
                    },
                    {
                    "ID": "PT SGMW Multifinance Indonesia",
                    "EN": "PT SGMW Multifinance Indonesia"
                    },
                    {
                    "ID": "PT Sharia Multifinance Astra",
                    "EN": "PT Sharia Multifinance Astra"
                    },
                    {
                    "ID": "PT Shinhan Indo Finance",
                    "EN": "PT Shinhan Indo Finance"
                    },
                    {
                    "ID": "PT Sinar Mas Multifinance",
                    "EN": "PT Sinar Mas Multifinance"
                    },
                    {
                    "ID": "PT Sinar Mitra Sepadan Finance",
                    "EN": "PT Sinar Mitra Sepadan Finance"
                    },
                    {
                    "ID": "PT Sinarmas Hana Finance",
                    "EN": "PT Sinarmas Hana Finance"
                    },
                    {
                    "ID": "PT Smart Multi Finance",
                    "EN": "PT Smart Multi Finance"
                    },
                    {
                    "ID": "PT SMFL Leasing Indonesia",
                    "EN": "PT SMFL Leasing Indonesia"
                    },
                    {
                    "ID": "PT Summit Oto Finance",
                    "EN": "PT Summit Oto Finance"
                    },
                    {
                    "ID": "PT Sunindo Kookmin Best Finance",
                    "EN": "PT Sunindo Kookmin Best Finance"
                    },
                    {
                    "ID": "PT Suzuki Finance Indonesia",
                    "EN": "PT Suzuki Finance Indonesia"
                    },
                    {
                    "ID": "PT Swadharma Bhakti Sedaya Finance",
                    "EN": "PT Swadharma Bhakti Sedaya Finance"
                    },
                    {
                    "ID": "PT Tez Capital and Finance",
                    "EN": "PT Tez Capital and Finance"
                    },
                    {
                    "ID": "PT Tirta Rindang Unggul Ekatama Finance",
                    "EN": "PT Tirta Rindang Unggul Ekatama Finance"
                    },
                    {
                    "ID": "PT Topas Multi Finance",
                    "EN": "PT Topas Multi Finance"
                    },
                    {
                    "ID": "PT Toyota Astra Financial Services",
                    "EN": "PT Toyota Astra Financial Services"
                    },
                    {
                    "ID": "PT Transpacific Finance",
                    "EN": "PT Transpacific Finance"
                    },
                    {
                    "ID": "PT Triprima Multifinance",
                    "EN": "PT Triprima Multifinance"
                    },
                    {
                    "ID": "PT Trust Finance Indonesia",
                    "EN": "PT Trust Finance Indonesia"
                    },
                    {
                    "ID": "PT Usaha Pembiayaan Reliance Indonesia (d\/h Nation Capital Finance)",
                    "EN": "PT Usaha Pembiayaan Reliance Indonesia (d\/h Nation Capital Finance)"
                    },
                    {
                    "ID": "PT Vasham Kosa Sejahtera",
                    "EN": "PT Vasham Kosa Sejahtera"
                    },
                    {
                    "ID": "PT Ventura Investasi Prima",
                    "EN": "PT Ventura Investasi Prima"
                    },
                    {
                    "ID": "PT Wahana Ottomitra Multiartha",
                    "EN": "PT Wahana Ottomitra Multiartha"
                    },
                    {
                    "ID": "PT Woori Finance Indonesia, Tbk",
                    "EN": "PT Woori Finance Indonesia, Tbk"
                    },
                    {
                    "ID": "PT. Sarana Aceh Ventura",
                    "EN": "PT. Sarana Aceh Ventura"
                    },
                    {
                    "ID": "PT. Selaparang Finansial",
                    "EN": "PT. Selaparang Finansial"
                    },
                    {
                    "ID": "Saison Modern Finance",
                    "EN": "Saison Modern Finance"
                    },
                    {
                    "ID": "Sarana Bali Ventura",
                    "EN": "Sarana Bali Ventura"
                    },
                    {
                    "ID": "Sarana Jabar Ventura",
                    "EN": "Sarana Jabar Ventura"
                    },
                    {
                    "ID": "Sarana Jakarta Ventura",
                    "EN": "Sarana Jakarta Ventura"
                    },
                    {
                    "ID": "Sarana Jambi Ventura",
                    "EN": "Sarana Jambi Ventura"
                    },
                    {
                    "ID": "Sarana Jateng Ventura",
                    "EN": "Sarana Jateng Ventura"
                    },
                    {
                    "ID": "Sarana Jatim Ventura",
                    "EN": "Sarana Jatim Ventura"
                    },
                    {
                    "ID": "Sarana Kalbar Ventura",
                    "EN": "Sarana Kalbar Ventura"
                    },
                    {
                    "ID": "Sarana Kalsel Ventura",
                    "EN": "Sarana Kalsel Ventura"
                    },
                    {
                    "ID": "Sarana Kalteng Ventura",
                    "EN": "Sarana Kalteng Ventura"
                    },
                    {
                    "ID": "Sarana Kaltim Ventura",
                    "EN": "Sarana Kaltim Ventura"
                    },
                    {
                    "ID": "Sarana NTB Ventura",
                    "EN": "Sarana NTB Ventura"
                    },
                    {
                    "ID": "Sarana Papua Ventura",
                    "EN": "Sarana Papua Ventura"
                    },
                    {
                    "ID": "Sarana Riau Ventura",
                    "EN": "Sarana Riau Ventura"
                    },
                    {
                    "ID": "Sarana Sulsel Ventura",
                    "EN": "Sarana Sulsel Ventura"
                    },
                    {
                    "ID": "Sarana Sultra Ventura",
                    "EN": "Sarana Sultra Ventura"
                    },
                    {
                    "ID": "Sarana Sulut Ventura",
                    "EN": "Sarana Sulut Ventura"
                    },
                    {
                    "ID": "Sarana Sumatera Barat Ventura",
                    "EN": "Sarana Sumatera Barat Ventura"
                    },
                    {
                    "ID": "Sarana Surakarta Ventura",
                    "EN": "Sarana Surakarta Ventura"
                    },
                    {
                    "ID": "Shakti Top Finance",
                    "EN": "Shakti Top Finance"
                    },
                    {
                    "ID": "Sinar Mas Ventura",
                    "EN": "Sinar Mas Ventura"
                    },
                    {
                    "ID": "Surya Artha Nusantara Finance",
                    "EN": "Surya Artha Nusantara Finance"
                    },
                    {
                    "ID": "Takari Kokoh Sejahtera",
                    "EN": "Takari Kokoh Sejahtera"
                    },
                    {
                    "ID": "Tempo  Utama  Finance",
                    "EN": "Tempo  Utama  Finance"
                    },
                    {
                    "ID": "Trihamas Finance Syariah",
                    "EN": "Trihamas Finance Syariah"
                    },
                    {
                    "ID": "Varia Intra Finance",
                    "EN": "Varia Intra Finance"
                    },
                    {
                    "ID": "Ventura Investasi Utama",
                    "EN": "Ventura Investasi Utama"
                    },
                    {
                    "ID": "Woka International",
                    "EN": "Woka International"
                    },
                    {
                    "ID": "Perusahaan Pembiayaan Lainnya",
                    "EN": "Other Financing Company"
                    
                    }
                ],
            }
        },
        components: {
            QuestionPolicyDetails,
            ButtonSelect,
            PostalCode,
            HeaderPD2,
        },
        mixins: [Mixin],
        async created(){
            window.addEventListener('scroll', this.onScrollNavbar);
            window.scrollTo(0, 0);
            var clientRegInfo = getRegisterIGNSdk();
            let inputPricingObj = createInputPricingObj();
            console.log(inputPricingObj);
            await setClientRegInfo(clientRegInfo);
            await setInputPricing(inputPricingObj);
            await calculateSelectedPlanPrice();
            this.paymentOptionPrice = getPolicyPaymentOptionPrice();
        },
        mounted() {
            this.loading("hide")
            // window.scrollTo(0, 0);
            // Global.motorVehicle.radarCallType = "2";
            // clientRegInfo = getRegisterIGNSdk();
        },
        methods: {
            async getData() {
                // this.payloadPlan.multiplePlan.root.Quote.Radar_Call_Type = "2";
                let inputPricingObject = this.payloadPlan.multiplePlan;
                setClientRegInfo(clientRegInfo);
                setInputPricing(inputPricingObject);
                this.loading("show");
                try {
                   await calculatePlanOptionsPrice(this.isCalculatePlanOptionsPrice);
                   this.saveQuoteFetch(); // watch this maybe it will not use in the future
                   this.loading("hide");
                   Global.motorVehicle.isPackagesStage = true;
                } catch(error) {
                    console.log(error)
                }
            },
            removingObject(policy,removeArray){
                for(var i = 0; i < removeArray.length; i++) {
                    if(policy.hasOwnProperty(removeArray[i])) {
                        delete policy[removeArray[i]];
                    }
                }
                return policy
            },
            async postalCodeDetails(postal) {
                try {
                    const response = await this.request().get(Global.restServer.url + "/postals?search=province&keyword=" + postal);
                    if (response.data?.model?.found == true) {
                        Global.motorVehicle.postalCodeDetails = response.data.model;
                        return response
                    } else {
                        Global.isValidated.postcode = false;
                        this.showNotValid = true;
                        return response
                    }
                } catch (error) {
                    console.error("getProvince => ", error);
                }
            },
            UnderFinancingAction(value){
                Global.motorVehicle.quote.flagCarFinancing = value;
                this.policyObject.root.quoteData.vehicleData.flagCarFinancing = value;
                this.pushStep("detail-under-financing");
                if ( Global.motorVehicle.quote.flagCarFinancing == "Yes") {
                    this.scrollToId("detail-company-financing");
                }else{
                    this.scrollToId("detail-chassis");
                }
                
            },
            chassisNumberAction(value){
                Global.motorVehicle.quote.hdChassisNumber = value;
                this.policyObject.root.quoteData.vehicleData.chassisNumber = value;
                this.pushStep("detail-chassis");
                this.scrollToId("detail-insurance");
            },
            insuranceAction(value){
                Global.motorVehicle.quote.previousInsurer = value;
                this.pushStep("detail-insurance");
                if (Global.motorVehicle.quote.previousInsurer == "Yes") {
                    this.policyObject.root.quoteData.previousInsurer = '';
                    this.fetchInsurers();
                    this.scrollToId("detail-company-insurance");
                }else{
                    this.policyObject.root.quoteData.previousInsurer = value;
                    this.scrollToId("detail-address");
                }
            },
            selectProvince(value) {
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].province = value[0];
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].provinceCode = value[1];
                this.pushStep("detail-province");
                this.scrollToId("detail-district");
                this.$refs.province.openDisabledButton();
            },
            selectDistrict(value) {
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].district = value[0];
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].districtCode = value[1];
                this.pushStep("detail-district");
                this.scrollToId("detail-subdistrict");
                this.$refs.district.openDisabledButton();
            },
            selectSubdistrict(value) {
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].subDistrict = value[0];
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].subDistrictCode = value[1];
                this.pushStep("detail-subdistrict");
                this.scrollToId("detail-road-name");
                this.$refs.subdistrict.openDisabledButton();
            },
            selectFinancing(value) {
                this.policyObject.root.quoteData.vehicleData.financingCompany = value.EN;
                Global.motorVehicle.quote.financingCompany = value
                this.pushStep("detail-company-financing");
                this.scrollToId("detail-chassis");
                this.$refs.financing.openDisabledButton();
            },
            selectInsurer(value) {
                this.policyObject.root.quoteData.previousInsurer = value.nameEn;
                Global.motorVehicle.quote.previousInsurer = value;
                Global.motorVehicle.insurerCompany = value;
                this.fetchCoverTypes();
                this.pushStep("detail-company-insurance");
                this.scrollToId("detail-type-insurance");
                this.$refs.insurer.openDisabledButton();
            },
            selectTypeInsurer(value){
                this.policyObject.root.quoteData.previousCoverType = value.nameEn;
                this.pushStep("detail-type-insurance");
                this.scrollToId("detail-expire-insurance");
                this.$refs.typeInsurer.openDisabledButton();
            },
            expireInsurance(value){
                Global.motorVehicle.quote.currentPolicyExpiryDate = value;
                this.policyObject.root.quoteData.currentPolicyExpiryDate = ymdFormatedDate(value);
                this.pushStep('detail-expire-insurance');
                this.scrollToId('detail-address')
            },
            roadNameAction(value){
                Global.motorVehicle.phaccount.soi = value
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].soi = String(value);
                this.pushStep("detail-road-name");
                this.scrollToId("detail-house-number");
            },
            houseNumberAction(value){
                Global.motorVehicle.phaccount.houseNumber = value
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].houseNumber = String(value);
                this.pushStep("detail-house-number");
                this.scrollToId("submit-button");
            },
            zipcodeAction: async function(value){
                Global.motorVehicle.phaccount.postalCode = value
                this.postalCode = value
                this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].postalCode = value
                this.policyObject.root.quoteData.postalCode = value
                await this.getProvince(value)

                if(this.checkPostcode == true){
                    this.pushStep("detail-address");
                    this.scrollToId("detail-province");
                }
                
            },
            getProvince: async function(value) {
                try {
                    this.loading("show");
                    var response = await this.request().get(
                        Global.restServer.url +
                        "/postals?search=province&keyword=" +
                        value
                    );

                    this.loading("hide");
                    if (response.data?.model?.found) {
                        Global.motorVehicle.postalCodeDetails = response.data.model;
                        this.provinces = response.data.model.provinces;
                        this.districts = response.data.model.districts;
                        this.subDistricts = response.data.model.subDistricts[0].list;

                        this.checkPostcode = true;
                    } else {
                        this.checkPostcode = false;
                    }
                } catch (error) {
                    if(error.response){
                        vm.frontEndLog("postals","search=province&keyword=" + Global.phaccount.postalCode, error.response.status.toString())
                    }
                    this.loading("hide");
                    this.checkPostcode = false;
                    console.error("getProvince => ", error);

                }
            },
            async saveQuoteFetch(){
                this.loading("show");
                this.policyObject.root.quoteData.onlineStage = "Proceed to Policy Details 2";
                Global.motorVehicle.quote.Online_Screen = "Proceed to Policy Details 2";
                Global.motorVehicle.quote.Online_Screen = "/mv/policydetails2";
                this.policyObject.root.quoteData.onlineScreen = "/mv/policydetails2";
                
                let dobPolicyHolder = null;
                let dobInsuredData= null;
                this.policyObject.root.quoteData.policyHolderData.dob = dayMonthYearFormatedDate(this.policyObject.root.quoteData.policyHolderData.dob,"day");
                dobPolicyHolder = ymdFormatedDate(String(this.policyObject.root.quoteData.policyHolderData.dob).split("-").reverse().join("-"))           
                dobInsuredData = ymdFormatedDate(String(this.policyObject.root.quoteData.insuredData.dob))           
                this.policyObject.root.quoteData.policyHolderData.dob = dobPolicyHolder; 
                if(this.policyObject.root.quoteData.vehicleData.isMainDriverPH)           
                    this.policyObject.root.quoteData.insuredData.dob = dobInsuredData;
                setPolicyServiceInputObj(this.policyObject);
                // setPolicyServiceMappingFromInputPriceResponse(policyServiceMappingObj);
                try {
                    let interceptorObj = setInterceptorObj();
                    let resQuote = await saveQuote(interceptorObj);
                    this.policyObject.root.quoteData.opportunityNumber = resQuote.opportunityNumber;
                    return resQuote
                } catch (error) {
                    console.log(error);
                    this.errorModal(this.$t("error.connection.message"));
                    return error
                }     
            },
            fetchInsurers: async function() {
                if (Array.isArray(Global.insurers) && Global.insurers.length > 0) {
                    return true;
                }
    
                try {
                    var response = await this.request().get(
                        Global.restServer.url + "/insurers"
                    );
                    Global.insurers = response.data;
                } catch (error) {
                    if (error.response) {
                        vm.frontEndLog("insurers", "", error.response.status.toString());
                    }
                    console.error("getinsurers => ", error);
                }
            },
            fetchCoverTypes: async function() {
                if (Array.isArray(Global.coverTypes) && Global.coverTypes.length > 0) {
                    return true;
                }
    
                try {
                    var response = await this.request().get(
                        Global.restServer.url + "/cover-types"
                    );
                    Global.coverTypes = response.data;
                } catch (error) {
                    if (error.response) {
                        vm.frontEndLog("coverTypes", "", error.response.status.toString());
                    }
                    console.error("getcoverTypes => ", error);
                }
            },
            submitPD2() {
                this.policyObject.root.quoteData.isActivatePolicy = true;
                this.policyObject.root.quoteData.vehicleData.flagInspectionRequired = "Yes"
                this.policyObject.root.quoteData.vehicleData.inspectionLimit = "8"
                this.policyObject.root.quoteData.vehicleData.inspectionMethod = "VDO Inspection"
                let response = this.saveQuoteFetch();
                response.then((value)=>{
                    if (value.success == true) {
                        this.loading("hide");
                        this.$router.push({name: 'mv-thankyou2'}); //when success payment
                    } else{
                        this.loading("hide")
                        this.errorModal(this.$t("creditCardError"));
                    }
                });
            },
    
            scrollToId(element) {
              setTimeout(()=> {
                  const elmnt = document.getElementById(element);
                  if (elmnt) {
                    document.getElementById(element).scrollIntoView({
                      behavior: "smooth"
                    });
                  }
              });
            },
            getCurrentScrollPOsition(value) {
                Global.motorVehicle.scrollPositionPD2 = value;
            }
        },
        computed: {
            labelCompanyFinancing() {
                if (this.policyObject.root.quoteData.vehicleData.financingCompany) {
                    if (this.policyObject.root.quoteData.prefLang === "id") {
                        return Global.motorVehicle.quote.financingCompany.ID;
                    } else {
                        return Global.motorVehicle.quote.financingCompany.EN;
                    }
                } else {
                    return this.$i18n.t('motorVehicle.companyUnderFinancingPlaceholder')
                }
            },
            labelCompanyInsurer() {
                if (this.policyObject.root.quoteData.previousInsurer &&  this.policyObject.root.quoteData.previousInsurer != 'Yes') {
                    if (this.policyObject.root.quoteData.prefLang=== "id") {
                        return Global.motorVehicle.insurerCompany.nameTh;
                    } else {
                        return Global.motorVehicle.insurerCompany.nameEn;
                    }
                } else {
                    return this.$i18n.t('motorVehicle.companyInsurancePlaceholder')
                }
            },
            labelCoverType() {
                if(this.policyObject.root.quoteData.previousCoverType) {
                    return this.policyObject.root.quoteData.previousCoverType;
                } else {
                    return this.$i18n.t('motorVehicle.typeInsurancePlaceholder')
                }
            },
            labelProvince() {
                if(this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].province) {
                    return this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].province;
                } else {
                    return this.$i18n.t('motorVehicle.province')
                }
            },
            labelDistrict() {
                if(this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].district) {
                    return this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].district;
                } else {
                    return this.$i18n.t('motorVehicle.district')
                }
            },
            labelsubDistrict() {
                if(this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].subDistrict) {
                    return this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].subDistrict;
                } else {
                    return this.$i18n.t('motorVehicle.subdistrict')
                }
            },
            UnderFinancingOptions() {
                return [
                    { value: "Yes", text: this.$i18n.t('motorVehicle.answer.yes') },
                    { value: "No", text: this.$i18n.t('motorVehicle.answer.no') }
                ];
            },
            underfinancingQuestion(){
                return this.$i18n.t('motorVehicle.questionUnderFinancing',{ brand: Global.motorVehicle.quote.Make, model: Global.motorVehicle.quote.carModel })
            },
            insuranceOptions() {
                return [
                    { value: "Yes", text: this.$i18n.t('motorVehicle.answer.yes') },
                    { value: "No", text: this.$i18n.t('motorVehicle.answer.no') }
                ];
            },
            isUnderFinancing() {
                if (Global.motorVehicle.quote.flagCarFinancing  == 'Yes') {
                    return true;
                } else {
                    return false;
                }
            },
            isInsurance() {
                if (Global.motorVehicle.quote.previousInsurer != 'No') {
                    return true;
                } else {
                    return false;
                }
            },
        },
        watch:{
            Global: {
                deep: true,
                handler:function(value) {
                    const phaccount = value.motorVehicle.phaccount;
                    if (!this.isCompanysCar && !phaccount.companyName & !phaccount.companyRegistrationNumber) {
                        this.showCarPlate = true;
                    } else {
                        this.showCarPlate = false;
                    }
                }
            }
        },
    }
    </script>
    
    <style scoped>

    .dob2extra{
        font-size: 20px;
        font-weight: 500;
        padding: 25px 10px;
        text-align: center;
        color: #003B71;
        font-weight: 700;
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .section {
        max-width: 604px;
        width: 100%;
        padding: 0 15px;
        margin: auto;
    }
    .questions-section {
        padding: 100px 0px;
    }
    .nextToPayment {
        padding: 30px 0px;
        text-align: center;
    }
    .next2btn {
        color: #fff;
        border-radius: 5px;
        background-image: none;
        background-color: #ff5011;
        padding: 15px 21px;
        margin: 6px auto;
        box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
        font-size: 16px;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
    .next2btn:disabled {
        border: 1px solid #ccc!important;
        color: #ccc!important;
        background-color: #fff!important;
        box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
        cursor: not-allowed;
    }
    .tnq {
        display: inline-flex;
        margin-bottom: 29px;
    }
    .tnq span {
        font-weight: 700;
        font-size: 14px;
        color: #FF4713;
        margin-left: 8px;
        cursor: pointer;
    }
    .tnq-checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid #666;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
    }
    .tnq-checkbox.clicked::before {
        background-color: #fff;
        content: "\E207";
        display: inline-block;
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Regular';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        vertical-align: top;
        -webkit-font-smoothing: antialiased;
        color: #ff5011;
        cursor: pointer;
    }
    
    @media screen and (max-width: 480px) {
        .tnq {
            display: grid;
        }
        .tnq-checkbox {
            position: absolute;
        }
    }
    </style>

<i18n>
    {
      "en": {
        "errorTBAtitle" : "Oops, Something went wrong",
        "errorTBAtext": "It looks like our server is playing up right now. Try again in a bit.",
        "creditCardError":"Oops, something went wrong. It looks like our server is playing up right now. Try again in a bit.",
      },
      "id": {
        "errorTBAtitle" : "Maaf, Terjadi Kesalahan",
        "errorTBAtext": "Sepertinya server kami sedang terkendala sekarang. Coba lagi beberapa saat.",
        "creditCardError":"Ups, terjadi kesalahan. Sepertinya server kami sedang terkendala sekarang. Coba lagi beberapa saat.",
      },
    }
    </i18n>