<template>
<div class="genderAndStatus">
    <div class="default" v-if="!isPolicyDetails">
        <h3>{{ $t('motorVehicle.aboutDriver') }}</h3>
        <div class="title">
            <h1>{{ $t('motorVehicle.maritalStatusInfo') }}</h1>
            <div class="tooltip-title">
                <Tooltip :text="textTooltip" />
            </div>
        </div>
    </div>
    <div class="atPolicyDetails" v-else>
        <div class="title">
            <h1 v-if="statusTitle == null">Status</h1>
            <h1 v-else>{{ statusTitle }}</h1>
            <div class="tooltip-title">
                <Tooltip :text="textTooltip" />
            </div>
        </div>
    </div>
    
    <div class="gender-options">
        <div class="row">
            <div class="col col-6 col-md-3 card-gender">
                <div 
                    class="sexandstatus"
                    style="background-image: url(images/motor-vehicle/SingleMan.svg);"
                    :class="{active : answerActive ==='Male-Single'}"
                    @click="setAnswer('Male-Single')"
                >
                    <div class="title-option">{{ $t('motorVehicle.maritalStatus_singleman') }}</div>
                </div>		
            </div>
            <div class="col col-6 col-md-3 card-gender">
                <div 
                    class="sexandstatus"
                    style="background-image: url(images/motor-vehicle/MarriedMan.svg);"
                    :class="{active : answerActive ==='Male-Married'}"
                    @click="setAnswer('Male-Married')"
                >
                    <div class="title-option">{{ $t('motorVehicle.maritalStatus_marriedman') }}</div>
                </div>		
            </div>
            <div class="col col-6 col-md-3 card-gender">
                <div 
                    class="sexandstatus"
                    style="background-image: url(images/motor-vehicle/SingleWoman.svg);"
                    :class="{active : answerActive ==='Female-Single'}"
                    @click="setAnswer('Female-Single')"
                >
                    <div class="title-option">{{ $t('motorVehicle.maritalStatus_singlewoman') }}</div>
                </div>		
            </div>
            <div class="col col-6 col-md-3 card-gender">
                <div 
                    class="sexandstatus"
                    style="background-image: url(images/motor-vehicle/MarriedWoman.svg);"
                    :class="{active : answerActive ==='Female-Married'}"
                    @click="setAnswer('Female-Married')"
                >
                    <div class="title-option">{{ $t('motorVehicle.maritalStatus_marriedwoman') }}</div>
                </div>		
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Tooltip from './Tooltip.vue';
import Mixin from "@/components/Mixin";

export default {
    name: "GenderAndStatus",
    mixins: [Mixin],
    props: {
        isPolicyDetails: {
            type: Boolean,
            default: false
        },
        defaultAnswer: {
            type: String,
            default: null
        },
        statusTitle: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            answer: null,
        };
    },
    components: { 
        Tooltip 
    },
    methods: {
        setAnswer(value) {
            this.answer = value;
            this.$emit("next", this.answer);
        }
    },
    computed: {
        answerActive() {
            if(this.defaultAnswer == null) {
                return this.answer
            } else {
                this.answer = this.defaultAnswer;
                return this.defaultAnswer;
            }
        },
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltipMaritial');
        }
    }
}
</script>
<style scoped>
.genderAndStatus {
    position: relative;
}
.genderAndStatus .title {
    display: flex;
    position: relative;
}
.genderAndStatus .title .tooltip-title {
    position: absolute;
    right: 0;
    top: -8px;
}
.genderAndStatus h3{
    font-size: 16px;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 400;
}
.title-policy-gender {
    font-size: 22px!important;
    text-align: center;
    font-style:normal;
    margin-bottom: 24px;
    font-weight: 600!important;
}
.genderAndStatus h1{
    font-size: 22px;
    text-align: center;
    width: 96%;
}
.genderAndStatus .gender-options {
    margin: 24px 0px;
}
.genderAndStatus .row .card-gender {
    padding-right: 5px!important;
    padding-left: 5px!important;
    margin-bottom: 10px;
}
.sexandstatus {
    background-repeat: no-repeat;
    background-position: bottom center;
    padding-bottom: 120px!important;
    display: block;
    overflow: hidden;
    text-align: center!important;
    text-overflow: clip!important;
    white-space: pre-wrap!important;
    font-size: 16px;
    line-height: 16px;
    padding: 12px 0 120px 0;
    text-indent: 0;
    border-radius: 5px;
    border: 1px solid #EF4A24;
    background-color: #fff;
    height: 216px;
    cursor: pointer;
    color: #EF4A24;
}
.active {
    background-color: #003B71!important;
    border: 1px solid #003B71;

}
.active .title-option {
    color: #FFF!important;
}
.genderAndStatus .sexandstatus .title-option {
    text-align: center;
    color: #EF4A24;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 8px;
}
</style>