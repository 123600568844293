<template>
<div class="buttonSelect" @blur="show = false">
    <button 
        id="btn-loading" 
        type="button" 
        class="btn btn-block btn-lg px-3 btn-select-option btn-loading"
        v-if="showLoading"
    >
        <i class="loader spinner"></i>
    </button>
    <button 
        id="btn-select-year" 
        type="button" 
        class="btn btn-block btn-lg px-3 btn-select-option "
        @click="open"
        :disabled="disabled"
        v-else
    > 
        <h4 v-if="activeSelected == null">{{ label }}</h4>
        <h4 v-else-if="valueLabel">{{ label }}</h4>
        <h4 class="value" v-else>{{ selected }}</h4>
        <svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="icon" :class="{rotate : show}">
            <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
        </svg>
    </button>
    <div 
        class="dropdownOptions" 
        v-if="show && listsData"
    >
        <div class="selectOptions carBrand" :class="optionsClass" v-if="type === 'car-brand'">
            <div class="top-car-brand">
                <div class="row px-3">
                    <div class="col brand-icon-box border-bottom border-right" @click="select('Toyota')">
                        <div class="brand" :style="'background: url('+toyota+') no-repeat;'"></div>
                    </div>
                    <div class="col brand-icon-box border-bottom" @click="select('Honda')">
                        <div class="brand" :style="'background: url('+honda+') no-repeat;'"></div>
                    </div>
                    <div class="col brand-icon-box border-bottom border-left" @click="select('Mitsubishi')">
                        <div class="brand" :style="'background: url('+mitsubishi+') no-repeat;'"></div>
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col brand-icon-box border-bottom border-right" @click="select('Daihatsu')">
                        <div class="brand" :style="'background: url('+daihatsu+') no-repeat;'"></div>
                    </div>
                    <div class="col brand-icon-box border-bottom" @click="select('Suzuki')">
                        <div class="brand" :style="'background: url('+suzuki+') no-repeat;'"></div>
                    </div>
                    <div class="col brand-icon-box border-bottom border-left" @click="select('Nissan')">
                        <div class="brand" :style="'background: url('+nissan+') no-repeat;'"></div>
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col brand-icon-box border-right" @click="select('Wuling')">
                        <div class="brand" :style="'background: url('+wuling+') no-repeat;'"></div>
                    </div>
                    <div class="col brand-icon-box" @click="select('Hyundai')">
                        <div class="brand" :style="'background: url('+hyundai+') no-repeat;'"></div>
                    </div>
                    <div class="col brand-icon-box border-left" @click="select('Mazda')">
                        <div class="brand" :style="'background: url('+mazda+') no-repeat;'"></div>
                    </div>
                </div>
            </div>
            <ul class="options-list">
                <li class="dropdown-header-car">
                    Other Brands
                </li>
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData" 
                    :key="index"
                    @click="select(option)"
                >
                    {{ option }}
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'car-model'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData.topModels" 
                    :key="index"
                    @click="select(option)"
                    v-if="listsData?.topModels"
                >
                    {{ option }}
                </li>
                <li class="dropdown-header" v-if="listsData?.topModels">
                    <span class="glyphicons glyphicons-arrow-down drop"></span> 
                    --- A-Z ---
                </li>
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData.data" 
                    :key="index"
                    @click="select(option)"
                >
                    {{ option }}
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'occupations'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData"
                    :key="index"
                    @click="select(option)"
                    :class="{active : option.nameTh === this.selected}"
                >
                    <span v-if="language === 'id'">{{ option.nameTh }}</span>
                    <span v-else>{{ option.nameEn }}</span>
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'insurer'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData"
                    :key="index"
                    @click="select(option)"
                    :class="{active : option.nameEn === this.selected}"
                >
                    <span v-if="language === 'id'">{{ option.nameTh }}</span>
                    <span v-else>{{ option.nameEn }}</span>
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'financing'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData"
                    :key="index"
                    @click="select(option)"
                    :class="{active : option.EN === this.selected}"
                >
                    <span v-if="language === 'id'">{{ option.ID }}</span>
                    <span v-else>{{ option.EN }}</span>
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'country'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData"
                    :key="index"
                    @click="select(option.name)"
                >
                    {{ option.name }}
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'new-mileage'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData"
                    :key="index"
                    @click="select(option)"
                >
                    <span v-if="language === 'id'">{{ option.nameId }}</span>
                    <span v-else>{{ option.nameEn }}</span>
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else-if="type === 'postal'">
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData"
                    :key="index"
                    @click="select(option.name)"
                >
                    {{ option.name[0]}}
                </li>
            </ul>
        </div>
        <div class="selectOptions" :class="optionsClass" v-else>
            <ul class="options-list">
                <li 
                    class="option-item"
                    v-for="(option, index) in listsData" 
                    :key="index"
                    @click="select(option)"
                >
                    {{ option }}
                </li>
            </ul>
        </div>
    </div>
</div>
</template>
<script>
import Toyota from "../../../../public/images/motor-vehicle/Toyota.svg";
import Honda from "../../../../public/images/motor-vehicle/Honda.svg";
import Mitsubishi from "../../../../public/images/motor-vehicle/Mitsubishi.svg";
import Daihatsu from "../../../../public/images/motor-vehicle/Daihatsu.svg";
import Suzuki from "../../../../public/images/motor-vehicle/Suzuki.svg";
import Nissan from "../../../../public/images/motor-vehicle/Nissan.svg";
import Wuling from "../../../../public/images/motor-vehicle/Wuling.svg";
import Hyundai from "../../../../public/images/motor-vehicle/Hyundai.svg";
import Mazda from "../../../../public/images/motor-vehicle/Mazda.svg";

export default {
    name: "ButtonSelect",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        listsData: {
            type: [Array, Object]
        },
        type: {
            type: String,
            default: null
        },
        language: {
            type: String
        },
        activeSelected: {
            type: [Number, String],
            default: null
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        valueLabel: {
            type: Boolean,
            default: false
        },
        optionsClass: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            show: false,
            selected: null,
            toyota: Toyota,
            honda: Honda,
            mitsubishi: Mitsubishi,
            daihatsu: Daihatsu,
            suzuki: Suzuki,
            nissan: Nissan,
            wuling: Wuling,
            hyundai: Hyundai,
            mazda: Mazda
        }
    },
    created() {
        window.addEventListener('click', this.close)
        this.putActivateValue();
    },
    beforeDestroy() {
        window.removeEventListener('click', this.close)
    },
    methods: {
        putActivateValue(){

            if(this.activeSelected != null){
                this.selected = this.activeSelected;
                if (this.selected.length > 35) {
                    this.selected = this.selected.slice(0, 35) + '...';
                }
            }
        },

        select(value) {
            if (this.type === 'occupations') {
                if (this.language === 'id') {
                    if (value.nameTh.length > 35) {
                        this.selected = value.nameTh.slice(0, 35) + '...';
                    } else {
                        this.selected = value.nameTh
                    }
                } else {
                    if (value.nameTh.length > 35) {
                        this.selected = value.nameEn.slice(0, 35) + '...';
                    } else {
                        this.selected = value.nameEn
                    }
                }
            }
            else if(this.type === 'postal'){
                this.selected = value[0];
            }else if(this.type === 'insurer'){
                this.selected = value;
            } else if(this.type === 'financing'){
                this.selected = value;
            }  else {
                this.selected = value;
            }

            this.show = false;
            this.$emit("selected", value);
        },
        open() {
            this.show = true;
        },
        close(e) {
            if (! this.$el.contains(e.target)) {
                this.show = false;
            }
        },
        updateSelected(value) {
            this.selected = value;
        }

    }
}
</script>
<style scoped>
.buttonSelect {
    position: relative;
    margin: 15px auto;
}
.buttonSelect .btn-select-option {
    border: 1px solid #D9D9D9;
    display: flex;
    width: 100%;
}

.buttonSelect .btn-select-option h4 {
    color: #FF4713;
    font-size: 22px;
    width: 100%;
    line-height: 1.5;
}
.buttonSelect .btn-select-option h4 .value {
    text-align: left;
}
.buttonSelect .btn-select-option .icon {
    margin: 12px 0px;
    position: absolute;
    right: 16px;
}
.dropdownOptions {
    position: absolute;
    width: 100%;
    z-index: 999;
}
.dropdownOptions .selectOptions {
    padding: 15px;
    background: #fff;
    width: 100%;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border-radius: 4px;
    max-height: 325px;
    position: absolute;
    top:5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    border: 1px #fbfbfb solid;

    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.dropdownOptions .carBrand {
    max-height: 480px;
}
.dropdownOptions .selectOptions ul{
    list-style: none;
    text-align: left;
    margin-left: -40px;
}
.dropdownOptions .selectOptions li {
    cursor: pointer;
    padding: 7px 0px;
    display: inline-block;
    width: 100%;
    color: #003b71;
    background-color: #fff;
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-image: none;
    white-space: normal;
    font-size: 14px;
    line-height: 1.42857;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropdownOptions .selectOptions li span {
    cursor: pointer;
    padding: 7px 0px;
    display: inline-block;
    width: 100%;
    color: #003b71;
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-image: none;
    white-space: normal;
    font-size: 14px;
    line-height: 1.42857;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropdownOptions .selectOptions li span.drop {
    width: 15px;
}

.dropdownOptions .selectOptions .dropdown-header {
    display: block;
    padding: 12px 0px;
    font-size: 12px!important;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}
.dropdownOptions .selectOptions .dropdown-header-car {
    display: block;
    padding: 12px 0px;
    font-size: 14px!important;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}
.dropdownOptions .selectOptions .dropdown-header span {
    font-size: 12px!important;
    color: #777;
    margin: 0px;
}

* {
scrollbar-width: auto;
scrollbar-color: #c1c1c1 #ffffff;
}

*::-webkit-scrollbar {
width: 12px;
}

*::-webkit-scrollbar-track {
background: #ffffff;
}

*::-webkit-scrollbar-thumb {
background-color: #c1c1c1;
border-radius: 15px;
border: 3px solid #ffffff;
}

@-webkit-keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-15%);
        transform: translateY(-15%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(15%);
        transform: translateY(-15%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
.rotate {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.brand-icon-box {
    text-align: center;
    padding: 5px 10px !important;
    cursor: pointer;
}
.brand-icon-box .brand {
    box-shadow: none !important;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center center!important;
    margin: 15px auto 15px;
}
.top-car-brand {
    margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
    .buttonSelect .btn-select-option h4 {
        color: #FF4713;
        font-size: 14px;
        width: 100%;
        line-height: 1;
        margin-top: 5px;
    }
    .buttonSelect .btn-select-option .icon {
        margin: 6px 0px;
        position: absolute;
        right: 16px;
    }
    .buttonSelect .btn-select-option {
        border: 1px solid #D9D9D9;
        display: flex;
        width: 100%;
        padding: 16px 0px;
    }
    .dropdownOptions .carBrand {
        max-height: 415px;
    }
    .yearClass {
        max-height: 285px!important;
    }
}

@media screen and (max-width: 360px) { 
    .dropdownOptions .carBrand {
        max-height: 415px;
    }
}
.btn-loading {
    padding: 15px;
    position: relative;
}
.spinner {
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#0071d9 94%,#0000) top/3.8px 3.8px no-repeat,
          conic-gradient(#0000 30%,#0071d9);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
   animation: spinner-c7wet2 0.6s infinite linear;
}
.loader {
    margin: 0 auto;
}
@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}
</style>