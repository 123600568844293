<template>
    <div class="headerThankyou" v-if="!thankyou2">
            <div class="row header-body">
                <div class="col title-header">
                    <div class="main-price text-center">
                        <span class="title-msg">{{$t('motorVehicle.thankyouPaymentTitle')}}</span> <br>
                        <div class="message-header mt-3">
                            <p class="sub-msg">{{$t('motorVehicle.thankyouPaymentmsg')}}</p> 
                            <!-- <p class="sub-msg">{{$t('motorVehicle.thankyouPaymentmsg2')}}</p>
                            <p class="mt-3">
                                <button 
                                    class="btn btn-block next2btn" 
                                    @click="goToPD2"
                                >
                                    {{ $t('motorVehicle.continue') }}
                                </button>
                            </p> -->
                            
                        </div>
                        
                    </div>
                </div>
            </div>
    </div>
    <div class="headerThankyou" v-if="thankyou2" style="min-height:300px;">
            <div class="row header-body">
                <div class="col title-header">
                    <div class="main-price text-center">
                        <span class="title-msg">{{$t('motorVehicle.thankyou2PaymentTitle')}}</span> <br>
                        <div class="message-header mt-3">
                            <p class="sub-msg">{{$t('motorVehicle.thankyou2Paymentmsg')}}</p> 
                        </div>
                        <div class="alert alert-success center-block" role="alert">
                            <div class="d-flex">
                                <a class="btn btn-gohome btn-block" href="tel:02150890821">
                                    <i class="icon-phone"></i>
                                    <span class="text-thankyou2">{{$t("motorVehicle.callingInspection")}}</span> 
                                </a>
                            </div>
                            <p class="thankyou2-small">{{$t('motorVehicle.openInspection')}}</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    
    </template>
    <script>

    export default {
        name: "headerThankyou",
        props: {
            sticky: {
                type: Boolean,
                default: true
            },
            thankyou2:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showNavigation: false,
                showInstallment: true,
            }
        },
        created() {
            if(this.sticky) {
                window.addEventListener('scroll', this.onScrollNavbar);
            }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            onScrollNavbar() {
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                if (currentScrollPosition > 100) {
                    this.showNavigation = true;
                    setTimeout(()=> {
                        this.showInstallment = false;
                    },500);
                } else {
                    this.showNavigation = false;
                    setTimeout(()=> {
                        this.showInstallment = true;
                    },500);
                } 
    
            },
            goDial() {
                location.reload();
            },
            goToPD2(){
                this.$router.push({name: 'mv-policydetails2'});
            }
        }
    }
    </script>
    <style scoped>
    .headerThankyou {
        background: linear-gradient(180deg, #015CAF 0%, #003D75 100%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        top: 0;
        position: relative;
        width: 100%;
        min-height: 400px;
        display: block;
        z-index: 899;
        padding: 20px 0px 50px 0px;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        transition: opacity 0.5;
    }
    .next2btn {
        color: #fff;
        border-radius: 5px;
        background-image: none;
        background-color: #ff5011;
        padding: 15px 21px;
        margin: 6px auto;
        box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
        font-size: 20px;
        width: 350px;
        text-align: center;
        font-weight: 600;
    }
    .next2btn:disabled {
        border: 1px solid #ccc!important;
        color: #ccc!important;
        background-color: #fff!important;
        box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
        cursor: not-allowed;
    }
    .headerThankyou .header-body{
        margin-top: 50px;
        padding: 0rem 8rem 0rem 8rem;
        margin-left:auto;
        margin-right:auto;
    }

    .headerThankyou .container .row .title-header{
        padding-left: 5px !important;
        margin-left: 0 !important;
    }

    .headerThankyou .stepper-header{
        top: 0;
    }
    
    .headerThankyou .imgpd2{
        position: absolute;
        bottom: 0;
        right: 12%;
    }
    .headerThankyou .head-block {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
    }
    .headerThankyou .head-block .back-button {
        width: 25%;
        text-align: left;
    }
    .headerThankyou .head-block .instalment {
        width: 25%;
        text-align: right;
    }
    .headerThankyou .head-block .optiNUmber {
        width: 50%;
        text-align: center;
        color: #fff;
    }
    .headerThankyou .head-block button{
        background: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.8);
    }
    .headerThankyou .main-price {
        color: #fff;
        font-weight: 600;
        font-size: 48px;
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .headerThankyou .main-price .title-msg {
        font-weight: 700;
        font-size: 46px;
        line-height: 54px;
        color: rgba(255, 255, 255, 1);
    }
    .headerThankyou .main-price .message-header .sub-msg {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 1);
    }
    .headerThankyou .main-price .message-header{
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .headerThankyou .ad-word {
        text-align: center;
        color: #fff;
        font-size: 12px;
    }
    .headerThankyou .ad-word i {
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 1px 6px;
        font-size: 10px;
    }
    .headerThankyou .installment-bottom {
        text-align: center;
        margin-bottom: 13px;
        margin-top: 5px;
    }
    
    .headerThankyou .installment-bottom .op-installment {
        background: transparent;
        font-size: 12px;
        color: #A8CEF1;
        border: 1px solid #A8CEF1;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.24));
        border-radius: 11px;
    }
    .stickyStyle {
        position:sticky!important;
    }
    @-webkit-keyframes rubberBand {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    @keyframes rubberBand {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    @-webkit-keyframes fadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
    @keyframes fadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
    @media screen and (max-width: 480px) {
        .headerThankyou {
            padding: 20px 0px 50px 0px;
        }
        .headerThankyou .header-body{
            text-align:center;
            padding: 0rem 2rem 0rem 2rem;
        }
        .next2btn{
            width: 300px;
        }
        .headerThankyou .main-price {
            font-size: 32px;
        } 
        .headerThankyou .main-price .title-msg {
            font-size: 36px;
        }
        .headerThankyou .message-header .sub-msg {
            font-size: 18px !important;
        }
        .headerThankyou .head-block button {
            font-size: 12px;
        }
        .headerThankyou .head-block .back-button {
            width: 30%;
        }
        .headerThankyou .head-block .instalment {
            width: 30%;
        }
        .headerThankyou .head-block .optiNUmber {
            width: 40%;
        }
        .alert{
            min-width: 330px !important;
            top: 75% !important;
        }
        .btn-gohome{
            display: flex;
        }
        .icon-phone{
            margin-top: 2px;
            margin-left: 5px
        }
        .text-thankyou2{
            text-align: left;
            padding-left:10px;
        }
    }
    @media screen and (min-width: 481px){
        .icon-phone{
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 679px) and (min-width: 481px) {
        .alert {
            top: 65% !important;
            min-width: 480px !important;
        }
    }
    .icon-phone::before {
        content: url('../../../../public/images/motor-vehicle/icon-phone-pd2.svg');
        display: inline-block;
        position: relative;
        top: 5px;
        right: 5px;
        display: inline-block;
    }
    .btn-gohome{
        width: 90%;
        margin: auto;
        font-weight: 600;
        font-size: 20px;
        border-radius: 8px;
        color: #fff;
        background-color: #ff5011;
    }
    .alert {
        margin-top: 165px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        padding: 15px 15px 0 15px;
        min-width: 700px;
        border: 3px solid transparent;
        border-radius: 8px;
    }
    .alert p{
        margin-top:16px;
        font-size: 12px;
        font-weight: 600;
    }
    .alert-success {
        background-color: #DBF9E0;
        border-color: #41AC47;
        color: #000;
    }
    </style>